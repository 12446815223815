import React, { useState, useEffect } from 'react';
import { collection, getDocs, doc, deleteDoc, writeBatch } from 'firebase/firestore';
import { db } from '../firebase';
import { Link } from 'react-router-dom';
import Layout from './Layout';
import { CSVLink } from 'react-csv';
import * as XLSX from 'xlsx';

const Patients = () => {
  const [patients, setPatients] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [sortBy, setSortBy] = useState('nameAZ');
  const [selectedPatients, setSelectedPatients] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [patientsPerPage, setPatientsPerPage] = useState(20);

  useEffect(() => {
    fetchPatients();
  }, []);

  const fetchPatients = async () => {
    try {
      const querySnapshot = await getDocs(collection(db, 'patients'));
      const patientList = querySnapshot.docs.map(doc => ({
        id: doc.id,
        ...convertTimestamps(doc.data()),
      }));
      setPatients(patientList);
      setLoading(false);
    } catch (err) {
      console.error("Error fetching patients: ", err);
      setError("Failed to fetch patients. Please try again later.");
      setLoading(false);
    }
  };

  const convertTimestamps = (obj) => {
    if (obj === null || typeof obj !== 'object') {
      return obj;
    }

    if (obj.seconds !== undefined && obj.nanoseconds !== undefined) {
      return new Date(obj.seconds * 1000 + obj.nanoseconds / 1000000).toISOString();
    }

    for (const key in obj) {
      obj[key] = convertTimestamps(obj[key]);
    }

    return obj;
  };

  const handleDeletePatient = async (id) => {
    if (window.confirm('Are you sure you want to delete this patient?')) {
      try {
        await deleteDoc(doc(db, 'patients', id));
        setPatients(patients.filter(patient => patient.id !== id));
      } catch (err) {
        console.error("Error deleting patient: ", err);
        setError("Failed to delete patient. Please try again.");
      }
    }
  };

  const handleDeleteSelected = async () => {
    if (window.confirm(`Are you sure you want to delete ${selectedPatients.length} selected patients?`)) {
      try {
        const batch = writeBatch(db);
        selectedPatients.forEach((patientId) => {
          const patientRef = doc(db, 'patients', patientId);
          batch.delete(patientRef);
        });
        await batch.commit();
        setPatients(patients.filter(patient => !selectedPatients.includes(patient.id)));
        setSelectedPatients([]);
        setSelectAll(false);
      } catch (err) {
        console.error("Error deleting selected patients: ", err);
        setError("Failed to delete selected patients. Please try again.");
      }
    }
  };

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
    setCurrentPage(1);
  };

  const handleSortChange = (e) => {
    setSortBy(e.target.value);
  };

  const handleSelectAll = () => {
    setSelectAll(!selectAll);
    setSelectedPatients(selectAll ? [] : filteredAndSortedPatients.map(patient => patient.id));
  };

  const handleSelectPatient = (id) => {
    setSelectedPatients(prevSelected => 
      prevSelected.includes(id) 
        ? prevSelected.filter(patientId => patientId !== id)
        : [...prevSelected, id]
    );
  };

  const exportToExcel = () => {
    const dataToExport = filteredAndSortedPatients.map(patient => ({
      Name: patient.demographics?.name || 'N/A',
      'Date of Birth': patient.demographics?.dateOfBirth || 'N/A',
      Gender: patient.demographics?.gender || 'N/A',
      Contact: patient.demographics?.contact || 'N/A',
      Email: patient.demographics?.email || 'N/A'
    }));

    const ws = XLSX.utils.json_to_sheet(dataToExport);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Patients");
    XLSX.writeFile(wb, "patients.xlsx");
  };

  const filteredAndSortedPatients = patients
    .filter(patient => 
      patient.demographics?.name?.toLowerCase().includes(searchTerm.toLowerCase()) ||
      patient.demographics?.dateOfBirth?.toLowerCase().includes(searchTerm.toLowerCase()) ||
      patient.demographics?.gender?.toLowerCase().includes(searchTerm.toLowerCase()) ||
      patient.demographics?.contact?.toLowerCase().includes(searchTerm.toLowerCase()) ||
      patient.demographics?.email?.toLowerCase().includes(searchTerm.toLowerCase())
    )
    .sort((a, b) => {
      switch (sortBy) {
        case 'nameAZ':
          return (a.demographics?.name || '').localeCompare(b.demographics?.name || '');
        case 'nameZA':
          return (b.demographics?.name || '').localeCompare(a.demographics?.name || '');
        case 'dobAsc':
          return new Date(a.demographics?.dateOfBirth || 0) - new Date(b.demographics?.dateOfBirth || 0);
        case 'dobDesc':
          return new Date(b.demographics?.dateOfBirth || 0) - new Date(a.demographics?.dateOfBirth || 0);
        case 'mostRecent':
          return new Date(b.createdAt || 0) - new Date(a.createdAt || 0);
        case 'oldest':
          return new Date(a.createdAt || 0) - new Date(b.createdAt || 0);
        default:
          return 0;
      }
    });

  // Pagination
  const indexOfLastPatient = currentPage * patientsPerPage;
  const indexOfFirstPatient = indexOfLastPatient - patientsPerPage;
  const currentPatients = filteredAndSortedPatients.slice(indexOfFirstPatient, indexOfLastPatient);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  if (loading) return <Layout title="Patients">Loading patients data...</Layout>;
  if (error) return <Layout title="Patients"><div className="text-red-500 text-left">{error}</div></Layout>;

  return (
    <Layout title="Patients">
      <div className="mb-4 flex justify-between items-center">
        <h1 className="text-2xl font-bold text-left">Patients</h1>
        <Link to="/add-patient" className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
          Add New Patient
        </Link>
      </div>

      <p className="text-left mb-4">{filteredAndSortedPatients.length} patients</p>

      <div className="mb-4 flex justify-between items-center">
        <input
          type="text"
          placeholder="Search patients..."
          value={searchTerm}
          onChange={handleSearchChange}
          className="p-2 border rounded w-1/3 text-left"
        />
        <div>
          <select
            value={sortBy}
            onChange={handleSortChange}
            className="p-2 border rounded mr-2"
          >
            <option value="nameAZ">Name (A-Z)</option>
            <option value="nameZA">Name (Z-A)</option>
            <option value="dobAsc">Date of Birth (Ascending)</option>
            <option value="dobDesc">Date of Birth (Descending)</option>
            <option value="mostRecent">Most Recent</option>
            <option value="oldest">Oldest</option>
          </select>
          <button
            onClick={exportToExcel}
            className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded mr-2"
          >
            Export to Excel
          </button>
          <CSVLink
            data={filteredAndSortedPatients.map(patient => ({
              Name: patient.demographics?.name || 'N/A',
              'Date of Birth': patient.demographics?.dateOfBirth || 'N/A',
              Gender: patient.demographics?.gender || 'N/A',
              Contact: patient.demographics?.contact || 'N/A',
              Email: patient.demographics?.email || 'N/A'
            }))}
            filename={"patients.csv"}
            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
          >
            Export to CSV
          </CSVLink>
        </div>
      </div>

      {selectedPatients.length > 0 && (
        <div className="mb-4">
          <button
            onClick={handleDeleteSelected}
            className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded"
          >
            Delete Selected ({selectedPatients.length})
          </button>
        </div>
      )}

      {currentPatients.length === 0 ? (
        <p className="text-left">No patients found.</p>
      ) : (
        <div className="overflow-x-auto">
          <table className="min-w-full table-auto">
            <thead className="bg-gray-200">
              <tr>
                <th className="px-4 py-2 text-left">
                  <input
                    type="checkbox"
                    checked={selectAll}
                    onChange={handleSelectAll}
                  />
                </th>
                <th className="px-4 py-2 text-left">Name</th>
                <th className="px-4 py-2 text-left">Date of Birth</th>
                <th className="px-4 py-2 text-left">Gender</th>
                <th className="px-4 py-2 text-left">Contact</th>
                <th className="px-4 py-2 text-left">Email</th>
                <th className="px-4 py-2 text-left">Actions</th>
              </tr>
            </thead>
            <tbody>
              {currentPatients.map(patient => (
                <tr key={patient.id} className="bg-white border-b">
                  <td className="px-4 py-2 text-left">
                    <input
                      type="checkbox"
                      checked={selectedPatients.includes(patient.id)}
                      onChange={() => handleSelectPatient(patient.id)}
                    />
                  </td>
                  <td className="px-4 py-2 text-left">{patient.demographics?.name || 'N/A'}</td>
                  <td className="px-4 py-2 text-left">{patient.demographics?.dateOfBirth || 'N/A'}</td>
                  <td className="px-4 py-2 text-left">{patient.demographics?.gender || 'N/A'}</td>
                  <td className="px-4 py-2 text-left">{patient.demographics?.contact || 'N/A'}</td>
                  <td className="px-4 py-2 text-left">{patient.demographics?.email || 'N/A'}</td>
                  <td className="px-4 py-2 text-left">
                    <Link to={`/patients/${patient.id}`} className="text-blue-600 hover:text-blue-900 mr-2">
                      View
                    </Link>
                    <Link to={`/edit-patient/${patient.id}`} className="text-indigo-600 hover:text-indigo-900 mr-2">
                      Edit
                    </Link>
                    <button
                      onClick={() => handleDeletePatient(patient.id)}
                      className="text-red-600 hover:text-red-900"
                    >
                      Delete
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}

      {/* Pagination */}
      <div className="mt-4 flex justify-between items-center">
        <div>
          <span className="mr-2">Patients per page:</span>
          <select
            value={patientsPerPage}
            onChange={(e) => {
              setPatientsPerPage(Number(e.target.value));
              setCurrentPage(1);
            }}
            className="p-2 border rounded"
          >
            <option value={20}>20</option>
            <option value={50}>50</option>
            <option value={100}>100</option>
          </select>
        </div>
        <div>
          {Array.from({ length: Math.ceil(filteredAndSortedPatients.length / patientsPerPage) }, (_, i) => (
            <button
              key={i}
              onClick={() => paginate(i + 1)}
              className={`mx-1 px-3 py-1 rounded ${
                currentPage === i + 1 ? 'bg-blue-500 text-white' : 'bg-gray-200'
              }`}
            >
              {i + 1}
            </button>
          ))}
        </div>
      </div>
    </Layout>
  );
};

export default Patients;