import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { doc, getDoc, updateDoc, GeoPoint } from 'firebase/firestore';
import { db } from '../firebase';
import Layout from './Layout';
import { loadGoogleMapsApi } from '../services/googleMapsLoader';

const EditPharmacy = () => {
  const [pharmacy, setPharmacy] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [mapCenter, setMapCenter] = useState({ lat: 0, lng: 0 });
  const [googleMapsLoaded, setGoogleMapsLoaded] = useState(false);
  const { id } = useParams();
  const navigate = useNavigate();
  const locationInputRef = useRef(null);

  useEffect(() => {
    const fetchPharmacy = async () => {
      try {
        const docRef = doc(db, 'pharmacies', id);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
          const pharmacyData = { id: docSnap.id, ...docSnap.data() };
          setPharmacy(pharmacyData);
          if (pharmacyData.coordinates) {
            setMapCenter({
              lat: pharmacyData.coordinates.latitude,
              lng: pharmacyData.coordinates.longitude
            });
          }
        } else {
          setError('Pharmacy not found');
        }
      } catch (err) {
        setError('Error fetching pharmacy details: ' + err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchPharmacy();

    loadGoogleMapsApi()
      .then(() => {
        setGoogleMapsLoaded(true);
      })
      .catch(err => {
        console.error("Error loading Google Maps API:", err);
        setError('Failed to load Google Maps: ' + err.message);
      });
  }, [id]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name.startsWith('operatingHours.')) {
      const day = name.split('.')[1];
      setPharmacy(prev => ({
        ...prev,
        operatingHours: {
          ...prev.operatingHours,
          [day]: value
        }
      }));
    } else if (name === 'services' || name === 'insuranceAccepted') {
      setPharmacy(prev => ({
        ...prev,
        [name]: value.split(',').map(item => item.trim())
      }));
    } else {
      setPharmacy(prev => ({ ...prev, [name]: value }));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const pharmacyRef = doc(db, 'pharmacies', id);
      const updatedPharmacy = {
        ...pharmacy,
        coordinates: new GeoPoint(mapCenter.lat, mapCenter.lng),
      };
      await updateDoc(pharmacyRef, updatedPharmacy);
      navigate(`/pharmacies/${id}`);
    } catch (err) {
      setError('Failed to update pharmacy: ' + err.message);
    }
  };

  const handlePlaceSelect = useCallback(() => {
    if (googleMapsLoaded && window.google && window.google.maps && window.google.maps.places && locationInputRef.current) {
      const autocomplete = new window.google.maps.places.Autocomplete(
        locationInputRef.current,
        { types: ['geocode'] }
      );
      autocomplete.addListener('place_changed', () => {
        const place = autocomplete.getPlace();
        if (place.geometry && place.geometry.location) {
          setPharmacy(prev => ({
            ...prev,
            location: place.formatted_address,
          }));
          setMapCenter({
            lat: place.geometry.location.lat(),
            lng: place.geometry.location.lng(),
          });
        }
      });
    }
  }, [googleMapsLoaded]);

  useEffect(() => {
    if (googleMapsLoaded && locationInputRef.current) {
      handlePlaceSelect();
    }
  }, [googleMapsLoaded, handlePlaceSelect]);

  if (loading) return <Layout title="Edit Pharmacy">Loading...</Layout>;
  if (error) return <Layout title="Edit Pharmacy">Error: {error}</Layout>;
  if (!pharmacy) return <Layout title="Edit Pharmacy">Pharmacy not found</Layout>;

  return (
    <Layout title="Edit Pharmacy">
      <form onSubmit={handleSubmit} className="space-y-4 text-left">
        <div>
          <label htmlFor="name" className="block text-sm font-medium text-gray-700 text-left">Name</label>
          <input
            type="text"
            name="name"
            id="name"
            value={pharmacy.name}
            onChange={handleChange}
            className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm text-left"
          />
        </div>

        <div>
          <label htmlFor="location" className="block text-sm font-medium text-gray-700 text-left">Location</label>
          <input
            type="text"
            name="location"
            id="location-input"
            ref={locationInputRef}
            value={pharmacy.location}
            onChange={handleChange}
            className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm text-left"
          />
        </div>

        <div>
          <label htmlFor="contact" className="block text-sm font-medium text-gray-700 text-left">Contact</label>
          <input
            type="text"
            name="contact"
            id="contact"
            value={pharmacy.contact}
            onChange={handleChange}
            className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm text-left"
          />
        </div>

        <div>
          <label htmlFor="email" className="block text-sm font-medium text-gray-700 text-left">Email</label>
          <input
            type="email"
            name="email"
            id="email"
            value={pharmacy.email}
            onChange={handleChange}
            className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm text-left"
          />
        </div>

        <div>
          <label htmlFor="website" className="block text-sm font-medium text-gray-700 text-left">Website</label>
          <input
            type="url"
            name="website"
            id="website"
            value={pharmacy.website}
            onChange={handleChange}
            className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm text-left"
          />
        </div>

        <div>
          <label htmlFor="services" className="block text-sm font-medium text-gray-700 text-left">Services</label>
          <input
            type="text"
            name="services"
            id="services"
            value={pharmacy.services ? pharmacy.services.join(', ') : ''}
            onChange={handleChange}
            className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm text-left"
          />
        </div>

        <div>
          <label htmlFor="licensedPharmacists" className="block text-sm font-medium text-gray-700 text-left">Licensed Pharmacists</label>
          <input
            type="number"
            name="licensedPharmacists"
            id="licensedPharmacists"
            value={pharmacy.licensedPharmacists}
            onChange={handleChange}
            className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm text-left"
          />
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700 text-left">Operating Hours</label>
          {['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'].map((day) => (
            <div key={day} className="flex items-center mt-2">
              <span className="w-24 text-sm">{day}:</span>
              <input
                type="text"
                name={`operatingHours.${day}`}
                value={pharmacy.operatingHours?.[day] || ''}
                onChange={handleChange}
                className="ml-2 flex-grow border border-gray-300 rounded-md shadow-sm py-1 px-2 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm text-left"
                placeholder="e.g., 9 am - 5 pm or Not Open"
              />
            </div>
          ))}
        </div>

        <div>
          <label htmlFor="insuranceAccepted" className="block text-sm font-medium text-gray-700 text-left">Insurance Accepted</label>
          <input
            type="text"
            name="insuranceAccepted"
            id="insuranceAccepted"
            value={pharmacy.insuranceAccepted ? pharmacy.insuranceAccepted.join(', ') : ''}
            onChange={handleChange}
            className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm text-left"
            placeholder="e.g., Sagicor, NCB, NewYorkLife"
          />
        </div>

        <div className="flex justify-start">
          <button
            type="submit"
            className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            Save Changes
          </button>
        </div>
      </form>
    </Layout>
  );
};

export default EditPharmacy;