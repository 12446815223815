import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import Layout from './Layout';
import { User, Calendar, Hospital, Building, Briefcase, TestTube, PlusSquare, Users } from 'lucide-react';
import { PieChart, Pie, Cell, BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { collection, getDocs, query, where, orderBy } from 'firebase/firestore';
import { db } from '../firebase';

const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042', '#8884D8', '#82CA9D', '#A4DE6C', '#D0ED57', '#FFC658', '#FFD700'];

const DashboardCard = ({ title, value, icon: Icon, color, onClick }) => (
  <div onClick={onClick} className={`bg-white overflow-hidden shadow rounded-lg cursor-pointer hover:shadow-md transition-shadow duration-300`}>
    <div className="p-5">
      <div className="flex items-center">
        <div className="flex-shrink-0">
          <Icon className={`h-6 w-6 text-${color}-600`} aria-hidden="true" />
        </div>
        <div className="ml-5 w-0 flex-1">
          <dl>
            <dt className="text-sm font-medium text-gray-500 truncate">{title}</dt>
            <dd>
              <div className="text-lg font-medium text-gray-900">{value}</div>
            </dd>
          </dl>
        </div>
      </div>
    </div>
  </div>
);

const Dashboard = () => {
  const navigate = useNavigate();
  const [metrics, setMetrics] = useState({
    totalPatients: 0,
    appointmentsToday: 0,
    totalProviders: 0,
    totalClinics: 0,
    totalHospitals: 0,
    totalAppointments: 0,
    totalDentalOffices: 0,
    totalLabs: 0,
    totalPharmacies: 0,
    totalSubscribers: 0
  });
  const [appointmentData, setAppointmentData] = useState([]);
  const [dateFilter, setDateFilter] = useState('week');
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const fetchAppointmentsToday = useCallback(async () => {
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    const tomorrow = new Date(today);
    tomorrow.setDate(tomorrow.getDate() + 1);

    const q = query(
      collection(db, 'appointments'),
      where('date', '>=', today),
      where('date', '<', tomorrow)
    );

    const querySnapshot = await getDocs(q);
    return querySnapshot.size;
  }, []);

  const fetchMetrics = useCallback(async () => {
    try {
      const fetchCount = async (collectionName) => {
        const querySnapshot = await getDocs(collection(db, collectionName));
        return querySnapshot.size;
      };

      const [
        totalPatients,
        appointmentsToday,
        totalProviders,
        totalClinics,
        totalHospitals,
        totalAppointments,
        totalDentalOffices,
        totalLabs,
        totalPharmacies,
        totalSubscribers
      ] = await Promise.all([
        fetchCount('patients'),
        fetchAppointmentsToday(),
        fetchCount('providers'),
        fetchCount('clinics'),
        fetchCount('hospitals'),
        fetchCount('appointments'),
        fetchCount('dentalOffices'),
        fetchCount('labs'),
        fetchCount('pharmacies'),
        fetchCount('subscribers')
      ]);

      setMetrics({
        totalPatients,
        appointmentsToday,
        totalProviders,
        totalClinics,
        totalHospitals,
        totalAppointments,
        totalDentalOffices,
        totalLabs,
        totalPharmacies,
        totalSubscribers
      });
    } catch (err) {
      console.error("Error fetching metrics:", err);
      setError("Failed to fetch metrics. Please try again.");
    }
  }, [fetchAppointmentsToday]);

  const fetchAppointmentData = useCallback(async () => {
    try {
      const endDate = new Date();
      endDate.setHours(23, 59, 59, 999);
      let startDate = new Date(endDate);
      
      if (dateFilter === 'week') {
        startDate.setDate(startDate.getDate() - 7);
      } else if (dateFilter === 'month') {
        startDate.setMonth(startDate.getMonth() - 1);
      } else if (dateFilter === 'year') {
        startDate.setFullYear(startDate.getFullYear() - 1);
      }

      const q = query(
        collection(db, 'appointments'),
        where('date', '>=', startDate),
        where('date', '<=', endDate),
        orderBy('date', 'asc')
      );

      const querySnapshot = await getDocs(q);
      const appointments = querySnapshot.docs.map(doc => {
        const data = doc.data();
        return {
          date: data.date.toDate(),
          count: 1
        };
      });

      // Group appointments by date
      const groupedAppointments = appointments.reduce((acc, curr) => {
        const dateStr = curr.date.toISOString().split('T')[0];
        if (!acc[dateStr]) {
          acc[dateStr] = { date: dateStr, count: 0 };
        }
        acc[dateStr].count += curr.count;
        return acc;
      }, {});

      // Fill in missing dates with zero count
      let currentDate = new Date(startDate);
      while (currentDate <= endDate) {
        const dateStr = currentDate.toISOString().split('T')[0];
        if (!groupedAppointments[dateStr]) {
          groupedAppointments[dateStr] = { date: dateStr, count: 0 };
        }
        currentDate.setDate(currentDate.getDate() + 1);
      }

      setAppointmentData(Object.values(groupedAppointments).sort((a, b) => a.date.localeCompare(b.date)));
    } catch (err) {
      console.error("Error fetching appointment data:", err);
      setError("Failed to fetch appointment data. Please try again.");
    }
  }, [dateFilter]);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      setError(null);
      try {
        await Promise.all([fetchMetrics(), fetchAppointmentData()]);
      } catch (err) {
        console.error("Error fetching dashboard data:", err);
        setError("Failed to fetch dashboard data. Please try again.");
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, [fetchMetrics, fetchAppointmentData]);

  const handleCardClick = useCallback((route) => {
    navigate(route);
  }, [navigate]);

  const pieChartData = Object.entries(metrics)
    .filter(([key]) => key !== 'appointmentsToday')
    .map(([key, value]) => ({
      name: key,
      value: value
    }));

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString('en-US', { month: 'short', day: 'numeric' });
  };

  if (loading) return <Layout title="Dashboard">Loading dashboard data...</Layout>;
  if (error) return <Layout title="Dashboard"><div className="text-red-500">{error}</div></Layout>;

  return (
    <Layout title="Dashboard">
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-5 gap-4 mb-8">
        <DashboardCard title="Total Patients" value={metrics.totalPatients} icon={User} color="blue" onClick={() => handleCardClick('/patients')} />
        <DashboardCard title={`Appointments Today (${formatDate(new Date())})`} value={metrics.appointmentsToday} icon={Calendar} color="green" onClick={() => handleCardClick('/appointments')} />
        <DashboardCard title="Total Providers" value={metrics.totalProviders} icon={Users} color="indigo" onClick={() => handleCardClick('/providers')} />
        <DashboardCard title="Total Clinics" value={metrics.totalClinics} icon={Hospital} color="red" onClick={() => handleCardClick('/clinics')} />
        <DashboardCard title="Total Hospitals" value={metrics.totalHospitals} icon={Building} color="purple" onClick={() => handleCardClick('/hospitals')} />
        <DashboardCard title="Total Appointments" value={metrics.totalAppointments} icon={Calendar} color="yellow" onClick={() => handleCardClick('/appointments')} />
        <DashboardCard title="Dental Offices" value={metrics.totalDentalOffices} icon={Briefcase} color="pink" onClick={() => handleCardClick('/dental-offices')} />
        <DashboardCard title="Labs" value={metrics.totalLabs} icon={TestTube} color="teal" onClick={() => handleCardClick('/labs')} />
        <DashboardCard title="Pharmacies" value={metrics.totalPharmacies} icon={PlusSquare} color="orange" onClick={() => handleCardClick('/pharmacies')} />
        <DashboardCard title="Subscribers" value={metrics.totalSubscribers} icon={Users} color="cyan" onClick={() => handleCardClick('/subscribers')} />
      </div>

      <div className="grid grid-cols-1 lg:grid-cols-2 gap-8 mb-8">
        <div className="bg-white p-4 rounded-lg shadow">
          <h2 className="text-xl font-semibold mb-4">Entity Distribution</h2>
          <ResponsiveContainer width="100%" height={300}>
            <PieChart>
              <Pie
                data={pieChartData}
                cx="50%"
                cy="50%"
                labelLine={false}
                outerRadius={80}
                fill="#8884d8"
                dataKey="value"
                label={({ name, percent }) => `${name} ${(percent * 100).toFixed(0)}%`}
              >
                {pieChartData.map((entry, index) => (
                  <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                ))}
              </Pie>
              <Tooltip />
            </PieChart>
          </ResponsiveContainer>
        </div>

        <div className="bg-white p-4 rounded-lg shadow">
          <h2 className="text-xl font-semibold mb-4">Appointments Over Time</h2>
          <div className="mb-4">
            <select
              value={dateFilter}
              onChange={(e) => setDateFilter(e.target.value)}
              className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
            >
              <option value="week">Last Week</option>
              <option value="month">Last Month</option>
              <option value="year">Last Year</option>
            </select>
          </div>
          <ResponsiveContainer width="100%" height={300}>
            <BarChart data={appointmentData}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="date" tickFormatter={formatDate} />
              <YAxis />
              <Tooltip labelFormatter={formatDate} />
              <Legend />
              <Bar dataKey="count" fill="#8884d8" name="Appointments" />
            </BarChart>
          </ResponsiveContainer>
        </div>
      </div>
    </Layout>
  );
};

export default Dashboard;