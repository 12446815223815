import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { collection, query, where, getDocs, doc, getDoc } from 'firebase/firestore';
import { db } from '../firebase';
import Layout from './Layout';

const PharmacyDetails = () => {
  const [pharmacy, setPharmacy] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { slug } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchPharmacy = async () => {
      try {
        let pharmacyData = null;

        // First, try to fetch by slug
        const q = query(collection(db, 'pharmacies'), where('slug', '==', slug));
        let querySnapshot = await getDocs(q);

        if (!querySnapshot.empty) {
          pharmacyData = { id: querySnapshot.docs[0].id, ...querySnapshot.docs[0].data() };
        } else {
          // If not found by slug, try to fetch by ID
          const docRef = doc(db, 'pharmacies', slug);
          const docSnap = await getDoc(docRef);

          if (docSnap.exists()) {
            pharmacyData = { id: docSnap.id, ...docSnap.data() };
          }
        }

        if (pharmacyData) {
          setPharmacy(pharmacyData);
        } else {
          setError('Pharmacy not found');
        }
      } catch (err) {
        setError('Error fetching pharmacy details: ' + err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchPharmacy();
  }, [slug]);

  if (loading) return <Layout title="Pharmacy Details">Loading...</Layout>;
  if (error) return <Layout title="Pharmacy Details">Error: {error}</Layout>;
  if (!pharmacy) return <Layout title="Pharmacy Details">Pharmacy not found</Layout>;

  const daysOrder = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];

  return (
    <Layout title={`Pharmacy: ${pharmacy.name}`}>
      <div className="bg-white shadow overflow-hidden sm:rounded-lg">
        <div className="px-4 py-5 sm:px-6">
          <h3 className="text-lg leading-6 font-medium text-gray-900">Pharmacy Details</h3>
          <p className="mt-1 max-w-2xl text-sm text-gray-500">Detailed information about the pharmacy.</p>
        </div>
        <div className="border-t border-gray-200">
          <dl>
            <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500 text-left">Name</dt>
              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2 text-left">{pharmacy.name}</dd>
            </div>
            <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500 text-left">Location</dt>
              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2 text-left">{pharmacy.location}</dd>
            </div>
            <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500 text-left">Contact</dt>
              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2 text-left">{pharmacy.contact}</dd>
            </div>
            <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500 text-left">Email</dt>
              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2 text-left">{pharmacy.email}</dd>
            </div>
            <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500 text-left">Website</dt>
              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2 text-left">
                <a href={pharmacy.website} target="_blank" rel="noopener noreferrer" className="text-blue-600 hover:text-blue-800">
                  {pharmacy.website}
                </a>
              </dd>
            </div>
            <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500 text-left">Services</dt>
              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2 text-left">
                {pharmacy.services && pharmacy.services.join(', ')}
              </dd>
            </div>
            <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500 text-left">Operating Hours</dt>
              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2 text-left">
                <ul className="list-disc list-inside">
                  {pharmacy.operatingHours && daysOrder.map(day => (
                    <li key={day}>{`${day}: ${pharmacy.operatingHours[day] || 'Not specified'}`}</li>
                  ))}
                </ul>
              </dd>
            </div>
            <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500 text-left">Insurance Accepted</dt>
              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2 text-left">
                {pharmacy.insuranceAccepted && pharmacy.insuranceAccepted.join(', ')}
              </dd>
            </div>
            <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500 text-left">Licensed Pharmacists</dt>
              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2 text-left">{pharmacy.licensedPharmacists}</dd>
            </div>
          </dl>
        </div>
      </div>
      <div className="mt-4">
        <button
          onClick={() => navigate(`/edit-pharmacy/${pharmacy.id}`)}
          className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mr-2"
        >
          Edit Pharmacy
        </button>
        <button
          onClick={() => navigate('/pharmacies')}
          className="bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded"
        >
          Back to Pharmacies
        </button>
      </div>
    </Layout>
  );
};

export default PharmacyDetails;