import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { doc, getDoc, updateDoc, GeoPoint } from 'firebase/firestore';
import { db } from '../firebase';
import Layout from './Layout';
import { loadGoogleMapsApi } from '../services/googleMapsLoader';

const EditHospital = () => {
  const [hospital, setHospital] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [mapCenter, setMapCenter] = useState({ lat: 0, lng: 0 });
  const [googleMapsLoaded, setGoogleMapsLoaded] = useState(false);
  const { id } = useParams();
  const navigate = useNavigate();
  const locationInputRef = useRef(null);

  useEffect(() => {
    const fetchHospital = async () => {
      try {
        const docRef = doc(db, 'hospitals', id);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
          const hospitalData = { id: docSnap.id, ...docSnap.data() };
          setHospital(hospitalData);
          if (hospitalData.coordinates) {
            setMapCenter({
              lat: hospitalData.coordinates.latitude,
              lng: hospitalData.coordinates.longitude
            });
          }
        } else {
          setError('Hospital not found');
        }
      } catch (err) {
        setError('Error fetching hospital details: ' + err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchHospital();

    loadGoogleMapsApi()
      .then(() => {
        setGoogleMapsLoaded(true);
      })
      .catch(err => {
        console.error("Error loading Google Maps API:", err);
        setError('Failed to load Google Maps: ' + err.message);
      });
  }, [id]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setHospital(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handleOperatingHoursChange = (day, value) => {
    setHospital(prev => ({
      ...prev,
      operatingHours: {
        ...prev.operatingHours,
        [day]: value
      }
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const hospitalRef = doc(db, 'hospitals', id);
      const updatedHospital = {
        ...hospital,
        coordinates: new GeoPoint(mapCenter.lat, mapCenter.lng),
      };
      await updateDoc(hospitalRef, updatedHospital);
      navigate(`/hospitals/${id}`);
    } catch (err) {
      setError('Failed to update hospital: ' + err.message);
    }
  };

  const handlePlaceSelect = useCallback(() => {
    if (googleMapsLoaded && window.google && window.google.maps && window.google.maps.places && locationInputRef.current) {
      const autocomplete = new window.google.maps.places.Autocomplete(
        locationInputRef.current,
        { types: ['geocode'] }
      );
      autocomplete.addListener('place_changed', () => {
        const place = autocomplete.getPlace();
        if (place.geometry && place.geometry.location) {
          setHospital(prev => ({
            ...prev,
            location: place.formatted_address,
          }));
          setMapCenter({
            lat: place.geometry.location.lat(),
            lng: place.geometry.location.lng(),
          });
        }
      });
    }
  }, [googleMapsLoaded]);

  useEffect(() => {
    if (googleMapsLoaded && locationInputRef.current) {
      handlePlaceSelect();
    }
  }, [googleMapsLoaded, handlePlaceSelect]);

  if (loading) return <Layout title="Edit Hospital">Loading...</Layout>;
  if (error) return <Layout title="Edit Hospital">Error: {error}</Layout>;
  if (!hospital) return <Layout title="Edit Hospital">Hospital not found</Layout>;

  return (
    <Layout title="Edit Hospital">
      <form onSubmit={handleSubmit} className="space-y-4 text-left">
        <div>
          <label htmlFor="name" className="block text-sm font-medium text-gray-700 text-left">Name</label>
          <input
            type="text"
            name="name"
            id="name"
            value={hospital.name}
            onChange={handleChange}
            className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm text-left"
          />
        </div>

        <div>
          <label htmlFor="location" className="block text-sm font-medium text-gray-700 text-left">Location</label>
          <input
            type="text"
            name="location"
            id="location-input"
            ref={locationInputRef}
            value={hospital.location}
            onChange={handleChange}
            className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm text-left"
          />
        </div>

        <div>
          <label htmlFor="contact" className="block text-sm font-medium text-gray-700 text-left">Contact</label>
          <input
            type="text"
            name="contact"
            id="contact"
            value={hospital.contact}
            onChange={handleChange}
            className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm text-left"
          />
        </div>

        <div>
          <label htmlFor="email" className="block text-sm font-medium text-gray-700 text-left">Email</label>
          <input
            type="email"
            name="email"
            id="email"
            value={hospital.email}
            onChange={handleChange}
            className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm text-left"
          />
        </div>

        <div>
          <label htmlFor="website" className="block text-sm font-medium text-gray-700 text-left">Website</label>
          <input
            type="url"
            name="website"
            id="website"
            value={hospital.website}
            onChange={handleChange}
            className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm text-left"
          />
        </div>

        <div>
          <label htmlFor="departments" className="block text-sm font-medium text-gray-700 text-left">Departments</label>
          <input
            type="text"
            name="departments"
            id="departments"
            value={hospital.departments ? hospital.departments.join(', ') : ''}
            onChange={(e) => setHospital(prev => ({ ...prev, departments: e.target.value.split(',').map(s => s.trim()) }))}
            className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm text-left"
          />
        </div>

        <div>
          <label htmlFor="bedCapacity" className="block text-sm font-medium text-gray-700 text-left">Bed Capacity</label>
          <input
            type="number"
            name="bedCapacity"
            id="bedCapacity"
            value={hospital.bedCapacity}
            onChange={handleChange}
            className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm text-left"
          />
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700 text-left">Operating Hours</label>
          {['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'].map((day) => (
            <div key={day} className="flex items-center mt-2">
              <span className="w-24 text-sm">{day}:</span>
              <input
                type="text"
                value={hospital.operatingHours?.[day] || ''}
                onChange={(e) => handleOperatingHoursChange(day, e.target.value)}
                className="ml-2 flex-grow border border-gray-300 rounded-md shadow-sm py-1 px-2 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm text-left"
                placeholder="e.g., 9 am - 5 pm or 24 hours"
              />
            </div>
          ))}
        </div>

        <div>
          <label htmlFor="insuranceAccepted" className="block text-sm font-medium text-gray-700 text-left">Insurance Accepted</label>
          <input
            type="text"
            name="insuranceAccepted"
            id="insuranceAccepted"
            value={hospital.insuranceAccepted ? hospital.insuranceAccepted.join(', ') : ''}
            onChange={(e) => setHospital(prev => ({ ...prev, insuranceAccepted: e.target.value.split(',').map(s => s.trim()) }))}
            className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm text-left"
            placeholder="e.g., Medicare, Medicaid, Blue Cross"
          />
        </div>

        <div className="flex justify-start">
          <button
            type="submit"
            className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            Save Changes
          </button>
        </div>
      </form>
    </Layout>
  );
};

export default EditHospital;