import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { collection, query, where, getDocs, doc, getDoc } from 'firebase/firestore';
import { db } from '../firebase';
import Layout from './Layout';

const DentalOfficeDetails = () => {
  const [dentalOffice, setDentalOffice] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { slug } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchDentalOffice = async () => {
      try {
        let dentalOfficeData = null;

        // First, try to fetch by slug
        const q = query(collection(db, 'dentalOffices'), where('slug', '==', slug));
        let querySnapshot = await getDocs(q);

        if (!querySnapshot.empty) {
          dentalOfficeData = { id: querySnapshot.docs[0].id, ...querySnapshot.docs[0].data() };
        } else {
          // If not found by slug, try to fetch by ID
          const docRef = doc(db, 'dentalOffices', slug);
          const docSnap = await getDoc(docRef);

          if (docSnap.exists()) {
            dentalOfficeData = { id: docSnap.id, ...docSnap.data() };
          }
        }

        if (dentalOfficeData) {
          setDentalOffice(dentalOfficeData);
        } else {
          setError('Dental office not found');
        }
      } catch (err) {
        setError('Error fetching dental office details: ' + err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchDentalOffice();
  }, [slug]);

  if (loading) return <Layout title="Dental Office Details">Loading...</Layout>;
  if (error) return <Layout title="Dental Office Details">Error: {error}</Layout>;
  if (!dentalOffice) return <Layout title="Dental Office Details">Dental office not found</Layout>;

  const daysOrder = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];

  return (
    <Layout title={`Dental Office: ${dentalOffice.name}`}>
      <div className="bg-white shadow overflow-hidden sm:rounded-lg text-left">
        <div className="px-4 py-5 sm:px-6">
          <h3 className="text-lg leading-6 font-medium text-gray-900">Dental Office Details</h3>
          <p className="mt-1 max-w-2xl text-sm text-gray-500">Detailed information about the dental office.</p>
        </div>
        <div className="border-t border-gray-200">
          <dl className="sm:divide-y sm:divide-gray-200">
            <div className="px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">Name</dt>
              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{dentalOffice.name}</dd>
            </div>
            <div className="px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">Location</dt>
              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{dentalOffice.location}</dd>
            </div>
            <div className="px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">Contact</dt>
              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{dentalOffice.contact}</dd>
            </div>
            <div className="px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">Email</dt>
              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{dentalOffice.email}</dd>
            </div>
            <div className="px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">Website</dt>
              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                <a href={dentalOffice.website} target="_blank" rel="noopener noreferrer" className="text-blue-600 hover:text-blue-800">
                  {dentalOffice.website}
                </a>
              </dd>
            </div>
            <div className="px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">Specialties</dt>
              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                {dentalOffice.specialties && dentalOffice.specialties.join(', ')}
              </dd>
            </div>
            <div className="px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">Number of Dentists</dt>
              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{dentalOffice.numberOfDentists}</dd>
            </div>
            <div className="px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">Services Offered</dt>
              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                {dentalOffice.servicesOffered && dentalOffice.servicesOffered.join(', ')}
              </dd>
            </div>
            <div className="px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">Emergency Services</dt>
              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                {dentalOffice.emergencyServices ? 'Available' : 'Not Available'}
              </dd>
            </div>
            <div className="px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">Languages Spoken</dt>
              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                {dentalOffice.languagesSpoken && dentalOffice.languagesSpoken.join(', ')}
              </dd>
            </div>
            <div className="px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">Parking</dt>
              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                {dentalOffice.parkingAvailable ? 'Available' : 'Not Available'}
              </dd>
            </div>
            <div className="px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">Accessibility Features</dt>
              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                {dentalOffice.accessibilityFeatures && dentalOffice.accessibilityFeatures.join(', ')}
              </dd>
            </div>
            <div className="px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">Operating Hours</dt>
              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                <ul className="list-disc list-inside">
                  {dentalOffice.operatingHours && daysOrder.map(day => (
                    <li key={day}>{`${day}: ${dentalOffice.operatingHours[day] || 'Not specified'}`}</li>
                  ))}
                </ul>
              </dd>
            </div>
            <div className="px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">Insurance Accepted</dt>
              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                {dentalOffice.insuranceAccepted && dentalOffice.insuranceAccepted.join(', ')}
              </dd>
            </div>
          </dl>
        </div>
      </div>
      <div className="mt-4 flex justify-between">
        <button
          onClick={() => navigate('/dental-offices')}
          className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
        >
          Back to Dental Offices
        </button>
        <button
          onClick={() => navigate(`/edit-dental-office/${dentalOffice.id}`)}
          className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded"
        >
          Edit Dental Office
        </button>
      </div>
    </Layout>
  );
};

export default DentalOfficeDetails;