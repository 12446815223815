import React, { useState, useEffect } from 'react';
import { collection, getDocs, doc, deleteDoc } from 'firebase/firestore';
import { db } from '../firebase';
import { Link } from 'react-router-dom';
import Layout from './Layout';
import { CSVLink } from 'react-csv';
import * as XLSX from 'xlsx';

const Subscribers = () => {
  const [subscribers, setSubscribers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [sortBy, setSortBy] = useState('nameAZ');
  const [selectedSubscribers, setSelectedSubscribers] = useState([]);
  const [selectAll, setSelectAll] = useState(false);

  useEffect(() => {
    fetchSubscribers();
  }, []);

  const fetchSubscribers = async () => {
    try {
      const querySnapshot = await getDocs(collection(db, 'subscribers'));
      const subscriberList = querySnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data(),
        createdAt: doc.data().createdAt ? doc.data().createdAt.toDate() : new Date(0)
      }));
      setSubscribers(subscriberList);
      setLoading(false);
    } catch (err) {
      console.error("Error fetching subscribers: ", err);
      setError("Failed to fetch subscribers. Please try again later.");
      setLoading(false);
    }
  };

  const handleDeleteSubscriber = async (id) => {
    if (window.confirm('Are you sure you want to delete this subscriber?')) {
      try {
        await deleteDoc(doc(db, 'subscribers', id));
        setSubscribers(subscribers.filter(subscriber => subscriber.id !== id));
      } catch (err) {
        console.error("Error deleting subscriber: ", err);
        setError("Failed to delete subscriber. Please try again.");
      }
    }
  };

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleSortChange = (e) => {
    setSortBy(e.target.value);
  };

  const handleSelectAll = () => {
    setSelectAll(!selectAll);
    setSelectedSubscribers(selectAll ? [] : filteredAndSortedSubscribers.map(subscriber => subscriber.id));
  };

  const handleSelectSubscriber = (id) => {
    setSelectedSubscribers(prevSelected => 
      prevSelected.includes(id) 
        ? prevSelected.filter(subscriberId => subscriberId !== id)
        : [...prevSelected, id]
    );
  };

  const exportToExcel = () => {
    const dataToExport = filteredAndSortedSubscribers.map(subscriber => ({
      Name: subscriber.name || 'N/A',
      Email: subscriber.email || 'N/A',
      'Subscription Type': subscriber.subscriptionType || 'N/A',
      'Start Date': subscriber.startDate || 'N/A',
      'End Date': subscriber.endDate || 'N/A'
    }));

    const ws = XLSX.utils.json_to_sheet(dataToExport);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Subscribers");
    XLSX.writeFile(wb, "subscribers.xlsx");
  };

  const filteredAndSortedSubscribers = subscribers
    .filter(subscriber => 
      subscriber.name?.toLowerCase().includes(searchTerm.toLowerCase()) ||
      subscriber.email?.toLowerCase().includes(searchTerm.toLowerCase()) ||
      subscriber.subscriptionType?.toLowerCase().includes(searchTerm.toLowerCase())
    )
    .sort((a, b) => {
      switch (sortBy) {
        case 'nameAZ':
          return (a.name || '').localeCompare(b.name || '');
        case 'nameZA':
          return (b.name || '').localeCompare(a.name || '');
        case 'emailAZ':
          return (a.email || '').localeCompare(b.email || '');
        case 'emailZA':
          return (b.email || '').localeCompare(a.email || '');
        case 'mostRecent':
          return b.createdAt - a.createdAt;
        case 'oldest':
          return a.createdAt - b.createdAt;
        default:
          return 0;
      }
    });

  if (loading) return <Layout title="Subscribers">Loading subscribers data...</Layout>;
  if (error) return <Layout title="Subscribers"><div className="text-red-500 text-left">{error}</div></Layout>;

  return (
    <Layout title="Subscribers">
      <div className="mb-4 flex justify-between items-center">
        <h1 className="text-2xl font-bold text-left">Subscribers</h1>
        <Link to="/add-subscriber" className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
          Add New Subscriber
        </Link>
      </div>

      <p className="text-left mb-4">{filteredAndSortedSubscribers.length} subscribers</p>

      <div className="mb-4 flex justify-between items-center">
        <input
          type="text"
          placeholder="Search subscribers..."
          value={searchTerm}
          onChange={handleSearchChange}
          className="p-2 border rounded w-1/3 text-left"
        />
        <div>
          <select
            value={sortBy}
            onChange={handleSortChange}
            className="p-2 border rounded mr-2"
          >
            <option value="nameAZ">Name (A-Z)</option>
            <option value="nameZA">Name (Z-A)</option>
            <option value="emailAZ">Email (A-Z)</option>
            <option value="emailZA">Email (Z-A)</option>
            <option value="mostRecent">Most Recent</option>
            <option value="oldest">Oldest</option>
          </select>
          <button
            onClick={exportToExcel}
            className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded mr-2"
          >
            Export to Excel
          </button>
          <CSVLink
            data={filteredAndSortedSubscribers.map(subscriber => ({
              Name: subscriber.name || 'N/A',
              Email: subscriber.email || 'N/A',
              'Subscription Type': subscriber.subscriptionType || 'N/A',
              'Start Date': subscriber.startDate || 'N/A',
              'End Date': subscriber.endDate || 'N/A'
            }))}
            filename={"subscribers.csv"}
            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
          >
            Export to CSV
          </CSVLink>
        </div>
      </div>

      {filteredAndSortedSubscribers.length === 0 ? (
        <p className="text-left">No subscribers found.</p>
      ) : (
        <div className="overflow-x-auto">
          <table className="min-w-full table-auto">
            <thead className="bg-gray-200">
              <tr>
                <th className="px-4 py-2 text-left">
                  <input
                    type="checkbox"
                    checked={selectAll}
                    onChange={handleSelectAll}
                  />
                </th>
                <th className="px-4 py-2 text-left">Name</th>
                <th className="px-4 py-2 text-left">Email</th>
                <th className="px-4 py-2 text-left">Subscription Type</th>
                <th className="px-4 py-2 text-left">Start Date</th>
                <th className="px-4 py-2 text-left">End Date</th>
                <th className="px-4 py-2 text-left">Actions</th>
              </tr>
            </thead>
            <tbody>
              {filteredAndSortedSubscribers.map(subscriber => (
                <tr key={subscriber.id} className="bg-white border-b">
                  <td className="px-4 py-2 text-left">
                    <input
                      type="checkbox"
                      checked={selectedSubscribers.includes(subscriber.id)}
                      onChange={() => handleSelectSubscriber(subscriber.id)}
                    />
                  </td>
                  <td className="px-4 py-2 text-left">{subscriber.name || 'N/A'}</td>
                  <td className="px-4 py-2 text-left">{subscriber.email || 'N/A'}</td>
                  <td className="px-4 py-2 text-left">{subscriber.subscriptionType || 'N/A'}</td>
                  <td className="px-4 py-2 text-left">{subscriber.startDate || 'N/A'}</td>
                  <td className="px-4 py-2 text-left">{subscriber.endDate || 'N/A'}</td>
                  <td className="px-4 py-2 text-left">
                    <Link to={`/subscribers/${subscriber.id}`} className="text-blue-600 hover:text-blue-900 mr-2">
                      View
                    </Link>
                    <Link to={`/edit-subscriber/${subscriber.id}`} className="text-indigo-600 hover:text-indigo-900 mr-2">
                      Edit
                    </Link>
                    <button
                      onClick={() => handleDeleteSubscriber(subscriber.id)}
                      className="text-red-600 hover:text-red-900"
                    >
                      Delete
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </Layout>
  );
};

export default Subscribers;