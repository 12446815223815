import React, { useState, useEffect } from 'react';
import { collection, getDocs, doc, deleteDoc } from 'firebase/firestore';
import { db } from '../firebase';
import Layout from './Layout';
import { useNavigate, Link } from 'react-router-dom';

const DentalOffices = () => {
  const [dentalOffices, setDentalOffices] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    fetchDentalOffices();
  }, []);

  const fetchDentalOffices = async () => {
    try {
      const dentalOfficesCollection = collection(db, 'dentalOffices');
      const dentalOfficeSnapshot = await getDocs(dentalOfficesCollection);
      const dentalOfficeList = dentalOfficeSnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));
      setDentalOffices(dentalOfficeList);
      setLoading(false);
    } catch (err) {
      setError('Failed to fetch dental offices: ' + err.message);
      setLoading(false);
    }
  };

  const handleDelete = async (id) => {
    if (window.confirm('Are you sure you want to delete this dental office?')) {
      try {
        await deleteDoc(doc(db, 'dentalOffices', id));
        fetchDentalOffices();
      } catch (err) {
        setError('Failed to delete dental office: ' + err.message);
      }
    }
  };

  if (loading) {
    return <Layout title="Dental Offices">Loading...</Layout>;
  }

  if (error) {
    return <Layout title="Dental Offices">Error: {error}</Layout>;
  }

  return (
    <Layout title="Dental Offices">
      <div className="mb-4 flex justify-between items-center">
        <h1 className="text-2xl font-bold">Dental Offices</h1>
        <button
          onClick={() => navigate('/add-dental-office')}
          className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
        >
          Add Dental Office
        </button>
      </div>

      {dentalOffices.length === 0 ? (
        <p>No dental offices found.</p>
      ) : (
        <div className="overflow-x-auto">
          <table className="min-w-full divide-y divide-gray-200">
            <thead className="bg-gray-50">
              <tr>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Name</th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Location</th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Contact</th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Specialties</th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Actions</th>
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200">
              {dentalOffices.map((office) => (
                <tr key={office.id}>
                  <td className="px-6 py-4 whitespace-nowrap text-left">
                    <span className="text-sm text-gray-900">{office.name}</span>
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-left">
                    <span className="text-sm text-gray-900">{office.location}</span>
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-left">
                    <span className="text-sm text-gray-900">{office.contact}</span>
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-left">
                    <span className="text-sm text-gray-900">{office.specialties.join(', ')}</span>
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-left text-sm font-medium">
                    <Link to={`/dental-offices/${office.slug || office.id}`} className="text-blue-600 hover:text-blue-900 mr-2">
                      View
                    </Link>
                    <Link to={`/edit-dental-office/${office.slug || office.id}`} className="text-indigo-600 hover:text-indigo-900 mr-2">
                      Edit
                    </Link>
                    <button onClick={() => handleDelete(office.id)} className="text-red-600 hover:text-red-900">
                      Delete
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </Layout>
  );
};

export default DentalOffices;