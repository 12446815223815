import React, { useState, useEffect, useCallback } from 'react';
import { collection, getDocs, deleteDoc, doc } from 'firebase/firestore';
import { db } from '../firebase';
import { Link } from 'react-router-dom';
import Layout from './Layout';
import { Trash2, Edit, Eye } from 'lucide-react';

const Documents = () => {
  const [documents, setDocuments] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const fetchDocuments = useCallback(async () => {
    try {
      const documentsCollection = collection(db, 'documents');
      const documentSnapshot = await getDocs(documentsCollection);
      const documentList = documentSnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));
      setDocuments(documentList);
      setLoading(false);
    } catch (err) {
      setError('Failed to fetch documents: ' + err.message);
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchDocuments();
  }, [fetchDocuments]);

  const handleDelete = useCallback(async (id) => {
    if (window.confirm('Are you sure you want to delete this document?')) {
      try {
        await deleteDoc(doc(db, 'documents', id));
        setDocuments(prevDocuments => prevDocuments.filter(document => document.id !== id));
      } catch (err) {
        setError('Failed to delete document: ' + err.message);
      }
    }
  }, []);

  if (loading) {
    return <Layout title="Documents">Loading...</Layout>;
  }

  if (error) {
    return <Layout title="Documents">Error: {error}</Layout>;
  }

  return (
    <Layout title="Documents">
      <div className="mb-4 flex justify-between items-center">
        <h1 className="text-2xl font-bold">Documents</h1>
        <Link
          to="/add-document"
          className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
        >
          Add New Document
        </Link>
      </div>

      <div className="overflow-x-auto">
        <table className="min-w-full divide-y divide-gray-200">
          <thead className="bg-gray-50">
            <tr>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Name</th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Type</th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Associated With</th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Uploaded By</th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Uploaded At</th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Actions</th>
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200">
            {documents.map((document) => (
              <tr key={document.id}>
                <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">{document.name}</td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{document.type}</td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{document.associatedWith}</td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{document.uploadedBy}</td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                  {new Date(document.uploadedAt).toLocaleString()}
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm font-medium">
                  <Link to={`/documents/${document.id}`} className="text-indigo-600 hover:text-indigo-900 mr-2">
                    <Eye className="inline-block w-5 h-5" />
                  </Link>
                  <Link to={`/edit-document/${document.id}`} className="text-yellow-600 hover:text-yellow-900 mr-2">
                    <Edit className="inline-block w-5 h-5" />
                  </Link>
                  <button onClick={() => handleDelete(document.id)} className="text-red-600 hover:text-red-900">
                    <Trash2 className="inline-block w-5 h-5" />
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </Layout>
  );
};

export default Documents;