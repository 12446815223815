import React, { useState, useEffect } from 'react';
import { doc, getDoc, updateDoc, serverTimestamp } from 'firebase/firestore';
import { db } from '../firebase';
import { useNavigate, useParams } from 'react-router-dom';
import Layout from './Layout';

const EditProvider = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [provider, setProvider] = useState({
    name: '',
    specialty: '',
    contact: '',
    email: '',
    licenseNumber: '',
    yearsOfExperience: '',
    education: '',
    languages: [],
    country: '',
    availability: {
      Monday: { isAvailable: false, start: '', end: '' },
      Tuesday: { isAvailable: false, start: '', end: '' },
      Wednesday: { isAvailable: false, start: '', end: '' },
      Thursday: { isAvailable: false, start: '', end: '' },
      Friday: { isAvailable: false, start: '', end: '' },
      Saturday: { isAvailable: false, start: '', end: '' },
      Sunday: { isAvailable: false, start: '', end: '' }
    },
  });
  const [loading, setLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    const fetchProvider = async () => {
      try {
        const docRef = doc(db, 'providers', id);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          setProvider({ id: docSnap.id, ...docSnap.data() });
        } else {
          setErrorMessage('Provider not found');
        }
      } catch (err) {
        setErrorMessage('Error fetching provider: ' + err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchProvider();
  }, [id]);

  const handleUpdateProvider = async (e) => {
    e.preventDefault();
    try {
      const providerRef = doc(db, 'providers', id);
      const updatedProvider = {
        ...provider,
        yearsOfExperience: parseInt(provider.yearsOfExperience) || 0,
        updatedAt: serverTimestamp()
      };
      await updateDoc(providerRef, updatedProvider);
      navigate('/providers');
    } catch (err) {
      console.error("Error updating provider:", err);
      setErrorMessage('Failed to update provider: ' + err.message);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === 'languages') {
      setProvider(prev => ({
        ...prev,
        [name]: value.split(',').map(item => item.trim())
      }));
    } else {
      setProvider(prev => ({ ...prev, [name]: value }));
    }
  };

  const handleAvailabilityToggle = (day) => {
    setProvider(prev => ({
      ...prev,
      availability: {
        ...prev.availability,
        [day]: {
          ...prev.availability[day],
          isAvailable: !prev.availability[day].isAvailable
        }
      }
    }));
  };

  const handleAvailabilityTimeChange = (day, field, value) => {
    setProvider(prev => ({
      ...prev,
      availability: {
        ...prev.availability,
        [day]: {
          ...prev.availability[day],
          [field]: value
        }
      }
    }));
  };

  if (loading) {
    return <Layout title="Edit Provider">Loading...</Layout>;
  }

  if (errorMessage) {
    return <Layout title="Edit Provider">Error: {errorMessage}</Layout>;
  }

  return (
    <Layout title="Edit Provider">
      <div className="max-w-2xl mx-auto">
        <h2 className="text-2xl font-bold mb-4">Edit Provider</h2>
        <form onSubmit={handleUpdateProvider} className="space-y-4">
          <input
            name="name"
            value={provider.name}
            onChange={handleChange}
            placeholder="Provider Name"
            required
            className="w-full p-2 border rounded"
          />
          <input
            name="specialty"
            value={provider.specialty}
            onChange={handleChange}
            placeholder="Specialty"
            required
            className="w-full p-2 border rounded"
          />
          <input
            name="contact"
            value={provider.contact}
            onChange={handleChange}
            placeholder="Contact Number"
            required
            className="w-full p-2 border rounded"
          />
          <input
            name="email"
            type="email"
            value={provider.email}
            onChange={handleChange}
            placeholder="Email"
            required
            className="w-full p-2 border rounded"
          />
          <input
            name="licenseNumber"
            value={provider.licenseNumber}
            onChange={handleChange}
            placeholder="License Number"
            required
            className="w-full p-2 border rounded"
          />
          <input
            name="yearsOfExperience"
            type="number"
            value={provider.yearsOfExperience}
            onChange={handleChange}
            placeholder="Years of Experience"
            required
            className="w-full p-2 border rounded"
          />
          <input
            name="education"
            value={provider.education}
            onChange={handleChange}
            placeholder="Education"
            className="w-full p-2 border rounded"
          />
          <input
            name="languages"
            value={provider.languages.join(', ')}
            onChange={handleChange}
            placeholder="Languages (comma-separated)"
            className="w-full p-2 border rounded"
          />
          <input
            name="country"
            value={provider.country}
            onChange={handleChange}
            placeholder="Country"
            required
            className="w-full p-2 border rounded"
          />
          <div className="space-y-2">
            <h3 className="font-bold">Availability</h3>
            {Object.entries(provider.availability).map(([day, { isAvailable, start, end }]) => (
              <div key={day} className="flex items-center space-x-2">
                <label className="inline-flex items-center">
                  <input
                    type="checkbox"
                    checked={isAvailable}
                    onChange={() => handleAvailabilityToggle(day)}
                    className="form-checkbox h-5 w-5 text-blue-600"
                  />
                  <span className="ml-2 text-gray-700">{day}</span>
                </label>
                {isAvailable && (
                  <>
                    <input
                      type="time"
                      value={start}
                      onChange={(e) => handleAvailabilityTimeChange(day, 'start', e.target.value)}
                      className="p-1 border rounded"
                    />
                    <span>to</span>
                    <input
                      type="time"
                      value={end}
                      onChange={(e) => handleAvailabilityTimeChange(day, 'end', e.target.value)}
                      className="p-1 border rounded"
                    />
                  </>
                )}
              </div>
            ))}
          </div>
          <div className="flex justify-end">
            <button
              type="submit"
              className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600"
            >
              Update Provider
            </button>
          </div>
        </form>
      </div>
    </Layout>
  );
};

export default EditProvider;