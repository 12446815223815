import React, { useState, useEffect, useCallback } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { doc, getDoc } from 'firebase/firestore';
import { db } from '../firebase';
import Layout from './Layout';

const DocumentDetails = () => {
  const [document, setDocument] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { id } = useParams();
  const navigate = useNavigate();

  const fetchDocument = useCallback(async () => {
    setLoading(true);
    setError(null);
    try {
      const docRef = doc(db, 'documents', id);
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        setDocument({ id: docSnap.id, ...docSnap.data() });
      } else {
        setError('Document not found');
      }
    } catch (err) {
      console.error("Error fetching document:", err);
      setError('Error fetching document details: ' + err.message);
    } finally {
      setLoading(false);
    }
  }, [id]);

  useEffect(() => {
    fetchDocument();
  }, [fetchDocument]);

  const handleNavigateBack = useCallback(() => {
    navigate('/documents');
  }, [navigate]);

  const handleNavigateEdit = useCallback(() => {
    navigate(`/edit-document/${id}`);
  }, [navigate, id]);

  if (loading) {
    return <Layout title="Document Details">Loading...</Layout>;
  }

  if (error) {
    return (
      <Layout title="Document Details">
        <div className="text-red-500">{error}</div>
        <button
          onClick={handleNavigateBack}
          className="mt-4 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
        >
          Back to Documents
        </button>
      </Layout>
    );
  }

  if (!document) {
    return (
      <Layout title="Document Details">
        <div>Document not found</div>
        <button
          onClick={handleNavigateBack}
          className="mt-4 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
        >
          Back to Documents
        </button>
      </Layout>
    );
  }

  const isImage = document.fileType?.toLowerCase().includes('image');

  return (
    <Layout title={`Document: ${document.name}`}>
      <div className="bg-white shadow overflow-hidden sm:rounded-lg text-left">
        <div className="px-4 py-5 sm:px-6">
          <h3 className="text-lg leading-6 font-medium text-gray-900">Document Details</h3>
          <p className="mt-1 max-w-2xl text-sm text-gray-500">Detailed information about the document.</p>
        </div>
        <div className="border-t border-gray-200 flex">
          <div className="w-1/2 border-r">
            <dl className="sm:divide-y sm:divide-gray-200">
              <div className="px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="text-sm font-medium text-gray-500">Document Name</dt>
                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{document.name}</dd>
              </div>
              <div className="px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="text-sm font-medium text-gray-500">Type</dt>
                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{document.type}</dd>
              </div>
              <div className="px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="text-sm font-medium text-gray-500">Description</dt>
                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{document.description}</dd>
              </div>
              <div className="px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="text-sm font-medium text-gray-500">Associated With</dt>
                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{document.associatedWith}</dd>
              </div>
              <div className="px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="text-sm font-medium text-gray-500">Associated ID</dt>
                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{document.associatedId}</dd>
              </div>
              <div className="px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="text-sm font-medium text-gray-500">Uploaded By</dt>
                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{document.uploadedBy}</dd>
              </div>
              <div className="px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="text-sm font-medium text-gray-500">Uploaded At</dt>
                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                  {new Date(document.uploadedAt).toLocaleString()}
                </dd>
              </div>
              <div className="px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="text-sm font-medium text-gray-500">File</dt>
                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                  <a href={document.fileUrl} target="_blank" rel="noopener noreferrer" className="text-blue-600 hover:text-blue-800">
                    Download File
                  </a>
                </dd>
              </div>
            </dl>
          </div>
          <div className="w-1/2 p-4">
            <h4 className="text-lg font-medium text-gray-900 mb-4">File Preview</h4>
            {isImage ? (
              <img src={document.fileUrl} alt={document.name} className="max-w-full h-auto" />
            ) : (
              <iframe src={document.fileUrl} title={document.name} className="w-full h-[600px]" />
            )}
          </div>
        </div>
      </div>
      <div className="mt-4 flex justify-between">
        <button
          onClick={handleNavigateBack}
          className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
        >
          Back to Documents
        </button>
        <button
          onClick={handleNavigateEdit}
          className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded"
        >
          Edit Document
        </button>
      </div>
    </Layout>
  );
};

export default DocumentDetails;