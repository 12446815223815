import React from 'react';

const AdminPanel = () => {
  return (
    <div className="bg-white shadow rounded-lg p-6">
      <h2 className="text-2xl font-bold mb-4">Welcome to the Admin Panel</h2>
      <p className="text-gray-600">
        This is your main dashboard. From here, you can manage all aspects of your medical system.
      </p>
      <div className="mt-6">
        <h3 className="text-lg font-semibold mb-2">Quick Actions</h3>
        <ul className="list-disc list-inside">
          <li>View recent appointments</li>
          <li>Manage patient records</li>
          <li>Update staff information</li>
          <li>Generate reports</li>
        </ul>
      </div>
    </div>
  );
};

export default AdminPanel;