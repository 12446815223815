import { initializeApp } from "firebase/app";
import { getAuth } from 'firebase/auth';
import { getFirestore } from "firebase/firestore";
import { getStorage } from 'firebase/storage';
import { getAnalytics } from "firebase/analytics";
// import { getFirestore, collection, getDocs, doc, deleteDoc, updateDoc, addDoc } from 'firebase/firestore';

const firebaseConfig = {
  apiKey: "AIzaSyBYAPhJKHJXF0YCCZ74rkRXmE16nKhtq-8",
  authDomain: "patient-monitoring-app-974b6.firebaseapp.com",
  databaseURL: "https://patient-monitoring-app-974b6-default-rtdb.firebaseio.com",
  projectId: "patient-monitoring-app-974b6",
  storageBucket: "patient-monitoring-app-974b6.appspot.com",
  messagingSenderId: "496682699010",
  appId: "1:496682699010:web:47c7d6bd02a484ef521a1b",
  measurementId: "G-X2KY1X7CFW"
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);
const analytics = getAnalytics(app);
const storage = getStorage(app);

export { auth, db, analytics, storage };

export {
  collection,
  getDocs,
  doc,
  deleteDoc,
  updateDoc,
  addDoc
} from "firebase/firestore";

export { createUserWithEmailAndPassword } from "firebase/auth";