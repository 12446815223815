import React, { useState } from 'react';
import { setDoc, doc, collection, serverTimestamp } from 'firebase/firestore';
import { db } from '../firebase';
import { useNavigate } from 'react-router-dom';
import Layout from './Layout';

const AddPatientEMR = () => {
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState(0);
  const [newPatient, setNewPatient] = useState({
    demographics: {
      name: '',
      dateOfBirth: '',
      gender: '',
      contact: '',
    },
    medicalHistory: [],
    medications: [],
    labResults: [],
    imagingStudies: [],
    clinicalNotes: [],
    supportPlanGoals: [],
    behaviorIncidents: [],
    medicalAdherence: {
      medicationCompliance: '',
      appointmentAttendance: '',
      treatmentPlanAdherence: '',
      notes: '',
    },
    transfers: [],
    assignedStaff: [],
    communityActivityLogs: [],
    inventory: {
      lastInventoryDate: '',
      personalBelongings: [],
      medicalEquipment: [],
    },
    significantEvents: [],
    medicalContacts: [],
    facesheet: {
      lawEnforcement: '',
      paramedics: '',
      supportCoordinators: '',
      hospital: '',
      familyMembers: [],
      apd: '',
    },
    incomeAndExpenses: {
      incomeSources: [],
      totalMonthlyIncome: 0,
      fixedExpenses: [],
      variableExpenses: [],
      totalMonthlyExpenses: 0,
      monthlySavings: 0,
    },
    logs: {
      rehabLogs: [],
      respiteLogs: [],
      lifeSkillsLogs: [],
      personalSupportLogs: [],
      seizureLogs: [],
    },
  });
  const [errorMessage, setErrorMessage] = useState('');

  const handleChange = (section, field, value) => {
    setNewPatient(prev => ({
      ...prev,
      [section]: { ...prev[section], [field]: value }
    }));
  };

  const handleArrayChange = (section, value) => {
    setNewPatient(prev => ({
      ...prev,
      [section]: [...prev[section], value]
    }));
  };

  const handleSubmit = async () => {
    const confirmed = window.confirm("Are you ready to submit the patient information?");
    if (confirmed) {
      try {
        const patientId = doc(collection(db, 'patients')).id;
        const patientData = {
          ...newPatient,
          createdAt: serverTimestamp(),
          updatedAt: serverTimestamp()
        };

        await setDoc(doc(db, 'patients', patientId), patientData);
        navigate('/patients');
      } catch (err) {
        console.error("Error adding patient:", err);
        setErrorMessage('Failed to add new patient: ' + err.message);
      }
    }
  };

  const tabSections = [
    { title: 'Demographics', content: <DemographicsSection newPatient={newPatient} handleChange={handleChange} /> },
    { title: 'Medical History', content: <MedicalHistorySection newPatient={newPatient} handleArrayChange={handleArrayChange} /> },
    { title: 'Medications', content: <MedicationsSection newPatient={newPatient} handleArrayChange={handleArrayChange} /> },
    { title: 'Lab Results', content: <LabResultsSection newPatient={newPatient} handleArrayChange={handleArrayChange} /> },
    { title: 'Imaging', content: <ImagingSection newPatient={newPatient} handleArrayChange={handleArrayChange} /> },
    { title: 'Notes', content: <NotesSection newPatient={newPatient} handleArrayChange={handleArrayChange} /> },
    { title: 'Support Plan Goals', content: <SupportPlanGoalsSection newPatient={newPatient} handleArrayChange={handleArrayChange} /> },
    { title: 'Behavior', content: <BehaviorSection newPatient={newPatient} handleArrayChange={handleArrayChange} /> },
    { title: 'Medical Adherence', content: <MedicalAdherenceSection newPatient={newPatient} handleChange={handleChange} /> },
    { title: 'Transfers', content: <TransfersSection newPatient={newPatient} handleArrayChange={handleArrayChange} /> },
    { title: 'Assigned Staff', content: <AssignedStaffSection newPatient={newPatient} handleArrayChange={handleArrayChange} /> },
    { title: 'Community Activity Logs', content: <CommunityActivityLogsSection newPatient={newPatient} handleArrayChange={handleArrayChange} /> },
    { title: 'Inventory', content: <InventorySection newPatient={newPatient} handleChange={handleChange} handleArrayChange={handleArrayChange} /> },
    { title: 'Significant Events', content: <SignificantEventsSection newPatient={newPatient} handleArrayChange={handleArrayChange} /> },
    { title: 'Medical Contacts', content: <MedicalContactsSection newPatient={newPatient} handleArrayChange={handleArrayChange} /> },
    { title: 'Facesheet', content: <FacesheetSection newPatient={newPatient} handleChange={handleChange} handleArrayChange={handleArrayChange} /> },
    { title: 'Income and Expenses', content: <IncomeAndExpensesSection newPatient={newPatient} handleChange={handleChange} handleArrayChange={handleArrayChange} /> },
    { title: 'Logs', content: <LogsSection newPatient={newPatient} handleArrayChange={handleArrayChange} /> },
  ];

  const nextTab = () => {
    if (activeTab < tabSections.length - 1) {
      setActiveTab(activeTab + 1);
    }
  };

  const prevTab = () => {
    if (activeTab > 0) {
      setActiveTab(activeTab - 1);
    }
  };

  return (
    <Layout title="Add Patient EMR">
      <div className="container mx-auto p-4 text-left">
        <h1 className="text-2xl font-bold mb-4">Add New Patient EMR</h1>
        {errorMessage && <p className="text-red-500 mb-4">{errorMessage}</p>}
        <div className="mb-4">
          {tabSections.map((section, index) => (
            <button
              key={index}
              onClick={() => setActiveTab(index)}
              className={`mr-2 px-4 py-2 rounded text-left ${
                activeTab === index
                  ? 'bg-blue-500 text-white'
                  : 'bg-gray-200 text-gray-700'
              }`}
            >
              {section.title}
            </button>
          ))}
        </div>
        <div className="bg-white p-4 rounded-b-lg shadow">
          {tabSections[activeTab].content}
        </div>
        <div className="mt-4 flex justify-between">
          <button
            onClick={prevTab}
            disabled={activeTab === 0}
            className="bg-gray-500 text-white px-4 py-2 rounded disabled:opacity-50"
          >
            Previous
          </button>
          {activeTab === tabSections.length - 1 ? (
            <button
              onClick={handleSubmit}
              className="bg-green-500 text-white px-4 py-2 rounded"
            >
              Save
            </button>
          ) : (
            <button
              onClick={nextTab}
              className="bg-blue-500 text-white px-4 py-2 rounded"
            >
              Next
            </button>
          )}
        </div>
      </div>
    </Layout>
  );
};

// Section components
const DemographicsSection = ({ newPatient, handleChange }) => (
  <div className="space-y-4">
    <div>
      <label htmlFor="name" className="block text-sm font-medium text-gray-700">Name</label>
      <input
        type="text"
        id="name"
        value={newPatient.demographics.name}
        onChange={(e) => handleChange('demographics', 'name', e.target.value)}
        className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
      />
    </div>
    <div>
      <label htmlFor="dateOfBirth" className="block text-sm font-medium text-gray-700">Date of Birth</label>
      <input
        type="date"
        id="dateOfBirth"
        value={newPatient.demographics.dateOfBirth}
        onChange={(e) => handleChange('demographics', 'dateOfBirth', e.target.value)}
        className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
      />
    </div>
    <div>
      <label htmlFor="gender" className="block text-sm font-medium text-gray-700">Gender</label>
      <select
        id="gender"
        value={newPatient.demographics.gender}
        onChange={(e) => handleChange('demographics', 'gender', e.target.value)}
        className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
      >
        <option value="">Select Gender</option>
        <option value="Male">Male</option>
        <option value="Female">Female</option>
        <option value="Other">Other</option>
      </select>
    </div>
    <div>
      <label htmlFor="contact" className="block text-sm font-medium text-gray-700">Contact</label>
      <input
        type="tel"
        id="contact"
        value={newPatient.demographics.contact}
        onChange={(e) => handleChange('demographics', 'contact', e.target.value)}
        className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
      />
    </div>
  </div>
);

const MedicalHistorySection = ({ newPatient, handleArrayChange }) => (
  <div>
    <h2 className="text-xl font-semibold mb-2">Medical History</h2>
    <ul className="list-disc pl-5 mb-4">
      {newPatient.medicalHistory.map((item, index) => (
        <li key={index}>{item}</li>
      ))}
    </ul>
    <div className="flex">
      <input
        type="text"
        placeholder="Add new medical history item"
        className="flex-grow mr-2 border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
        onKeyPress={(e) => {
          if (e.key === 'Enter' && e.target.value.trim() !== '') {
            handleArrayChange('medicalHistory', e.target.value.trim());
            e.target.value = '';
          }
        }}
      />
      <button
        type="button"
        onClick={(e) => {
          const input = e.target.previousSibling;
          if (input.value.trim() !== '') {
            handleArrayChange('medicalHistory', input.value.trim());
            input.value = '';
          }
        }}
        className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
      >
        Add
      </button>
    </div>
  </div>
);

const MedicationsSection = ({ newPatient, handleArrayChange }) => (
  <div>
    <h2 className="text-xl font-semibold mb-2">Current Medications</h2>
    <ul className="list-disc pl-5 mb-4">
      {newPatient.medications.map((med, index) => (
        <li key={index}>{med}</li>
      ))}
    </ul>
    <div className="flex">
      <input
        type="text"
        placeholder="Add new medication"
        className="flex-grow mr-2 border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
        onKeyPress={(e) => {
          if (e.key === 'Enter' && e.target.value.trim() !== '') {
            handleArrayChange('medications', e.target.value.trim());
            e.target.value = '';
          }
        }}
      />
      <button
        type="button"
        onClick={(e) => {
          const input = e.target.previousSibling;
          if (input.value.trim() !== '') {
            handleArrayChange('medications', input.value.trim());
            input.value = '';
          }
        }}
        className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
      >
        Add
      </button>
    </div>
  </div>
);

const LabResultsSection = ({ newPatient, handleArrayChange }) => (
  <div>
    <h2 className="text-xl font-semibold mb-2">Lab Results</h2>
    <table className="min-w-full divide-y divide-gray-200 mb-4">
      <thead className="bg-gray-50">
        <tr>
          <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Test</th>
          <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Result</th>
          <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Date</th>
        </tr>
      </thead>
      <tbody className="bg-white divide-y divide-gray-200">
        {newPatient.labResults.map((result, index) => (
          <tr key={index}>
            <td className="px-6 py-4 whitespace-nowrap">{result.test}</td>
            <td className="px-6 py-4 whitespace-nowrap">{result.result}</td>
            <td className="px-6 py-4 whitespace-nowrap">{result.date}</td>
          </tr>
        ))}
      </tbody>
    </table>
    <div className="flex space-x-2">
      <input type="text" placeholder="Test" className="flex-1 border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm" />
      <input type="text" placeholder="Result" className="flex-1 border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm" />
      <input type="date" className="flex-1 border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm" />
      <button
        type="button"
        onClick={(e) => {
          const [test, result, date] = e.target.parentNode.children;
          if (test.value && result.value && date.value) {
            handleArrayChange('labResults', { test: test.value, result: result.value, date: date.value });
            test.value = result.value = date.value = '';
          }
        }}
        className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
      >
        Add
      </button>
    </div>
  </div>
);

const ImagingSection = ({ newPatient, handleArrayChange }) => (
  <div>
    <h2 className="text-xl font-semibold mb-2">Imaging Studies</h2>
    <ul className="list-disc pl-5 mb-4">
      {newPatient.imagingStudies.map((study, index) => (
        <li key={index}>{study}</li>
      ))}
    </ul>
    <div className="flex">
      <input
        type="text"
        placeholder="Add new imaging study"
        className="flex-grow mr-2 border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
        onKeyPress={(e) => {
          if (e.key === 'Enter' && e.target.value.trim() !== '') {
            handleArrayChange('imagingStudies', e.target.value.trim());
            e.target.value = '';
          }
        }}
      />
      <button
        type="button"
        onClick={(e) => {
          const input = e.target.previousSibling;
          if (input.value.trim() !== '') {
            handleArrayChange('imagingStudies', input.value.trim());
            input.value = '';
          }
        }}
        className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
      >
        Add
      </button>
    </div>
  </div>
);

const NotesSection = ({ newPatient, handleArrayChange }) => (
  <div>
    <h2 className="text-xl font-semibold mb-2">Clinical Notes</h2>
    {newPatient.clinicalNotes.map((note, index) => (
      <div key={index} className="bg-gray-100 p-4 rounded mb-4">
        <p className="font-semibold">{note.date} - {note.doctor}</p>
        <p>{note.content}</p>
      </div>
    ))}
    <div className="space-y-4">
      <div>
        <label htmlFor="noteDate" className="block text-sm font-medium text-gray-700">Date</label>
        <input
          type="date"
          id="noteDate"
          className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
        />
      </div>
      <div>
        <label htmlFor="noteDoctor" className="block text-sm font-medium text-gray-700">Doctor</label>
        <input
          type="text"
          id="noteDoctor"
          className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
          placeholder="Enter doctor's name"
        />
      </div>
      <div>
        <label htmlFor="noteContent" className="block text-sm font-medium text-gray-700">Note Content</label>
        <textarea
          id="noteContent"
          rows="4"
          className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
          placeholder="Enter clinical note"
        ></textarea>
      </div>
      <button
        type="button"
        onClick={() => {
          const date = document.getElementById('noteDate').value;
          const doctor = document.getElementById('noteDoctor').value;
          const content = document.getElementById('noteContent').value;
          if (date && doctor && content) {
            handleArrayChange('clinicalNotes', { date, doctor, content });
            document.getElementById('noteDate').value = '';
            document.getElementById('noteDoctor').value = '';
            document.getElementById('noteContent').value = '';
          }
        }}
        className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
      >
        Add Note
      </button>
    </div>
  </div>
);

const SupportPlanGoalsSection = ({ newPatient, handleArrayChange }) => (
  <div>
    <h2 className="text-xl font-semibold mb-2">Support Plan Goals</h2>
    {newPatient.supportPlanGoals.map((goal, index) => (
      <div key={index} className="mb-4 p-4 bg-gray-100 rounded">
        <p><strong>Goal:</strong> {goal.description}</p>
        <p><strong>Target Date:</strong> {goal.targetDate}</p>
        <p><strong>Status:</strong> {goal.status}</p>
        <p><strong>Notes:</strong> {goal.notes}</p>
      </div>
    ))}
    <div className="space-y-4">
      <input type="text" placeholder="Goal Description" id="goalDescription" className="w-full border border-gray-300 rounded-md shadow-sm py-2 px-3" />
      <input type="date" id="goalTargetDate" className="w-full border border-gray-300 rounded-md shadow-sm py-2 px-3" />
      <input type="text" placeholder="Status" id="goalStatus" className="w-full border border-gray-300 rounded-md shadow-sm py-2 px-3" />
      <textarea id="goalNotes" placeholder="Notes" rows="3" className="w-full border border-gray-300 rounded-md shadow-sm py-2 px-3"></textarea>
      <button
        type="button"
        onClick={() => {
          const description = document.getElementById('goalDescription').value;
          const targetDate = document.getElementById('goalTargetDate').value;
          const status = document.getElementById('goalStatus').value;
          const notes = document.getElementById('goalNotes').value;
          if (description && targetDate && status) {
            handleArrayChange('supportPlanGoals', { description, targetDate, status, notes });
            document.getElementById('goalDescription').value = '';
            document.getElementById('goalTargetDate').value = '';
            document.getElementById('goalStatus').value = '';
            document.getElementById('goalNotes').value = '';
          }
        }}
        className="w-full inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
      >
        Add Goal
      </button>
    </div>
  </div>
);

const BehaviorSection = ({ newPatient, handleArrayChange }) => (
  <div>
    <h2 className="text-xl font-semibold mb-2">Behavior Incidents</h2>
    {newPatient.behaviorIncidents.map((incident, index) => (
      <div key={index} className="mb-4 p-4 bg-gray-100 rounded">
        <p><strong>Date:</strong> {incident.date}</p>
        <p><strong>Description:</strong> {incident.description}</p>
        <p><strong>Severity:</strong> {incident.severity}</p>
        <p><strong>Intervention:</strong> {incident.intervention}</p>
      </div>
    ))}
    <div className="space-y-4">
      <input type="date" id="incidentDate" className="w-full border border-gray-300 rounded-md shadow-sm py-2 px-3" />
      <input type="text" placeholder="Description" id="incidentDescription" className="w-full border border-gray-300 rounded-md shadow-sm py-2 px-3" />
      <input type="text" placeholder="Severity" id="incidentSeverity" className="w-full border border-gray-300 rounded-md shadow-sm py-2 px-3" />
      <input type="text" placeholder="Intervention" id="incidentIntervention" className="w-full border border-gray-300 rounded-md shadow-sm py-2 px-3" />
      <button
        type="button"
        onClick={() => {
          const date = document.getElementById('incidentDate').value;
          const description = document.getElementById('incidentDescription').value;
          const severity = document.getElementById('incidentSeverity').value;
          const intervention = document.getElementById('incidentIntervention').value;
          if (date && description && severity && intervention) {
            handleArrayChange('behaviorIncidents', { date, description, severity, intervention });
            document.getElementById('incidentDate').value = '';
            document.getElementById('incidentDescription').value = '';
            document.getElementById('incidentSeverity').value = '';
            document.getElementById('incidentIntervention').value = '';
          }
        }}
        className="w-full inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
      >
        Add Incident
      </button>
    </div>
  </div>
);

const MedicalAdherenceSection = ({ newPatient, handleChange }) => (
  <div>
    <h2 className="text-xl font-semibold mb-2">Medical Adherence</h2>
    <div className="space-y-4">
      <div>
        <label htmlFor="medicationCompliance" className="block text-sm font-medium text-gray-700">Medication Compliance</label>
        <input
          type="text"
          id="medicationCompliance"
          value={newPatient.medicalAdherence.medicationCompliance}
          onChange={(e) => handleChange('medicalAdherence', 'medicationCompliance', e.target.value)}
          className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
        />
      </div>
      <div>
        <label htmlFor="appointmentAttendance" className="block text-sm font-medium text-gray-700">Appointment Attendance</label>
        <input
          type="text"
          id="appointmentAttendance"
          value={newPatient.medicalAdherence.appointmentAttendance}
          onChange={(e) => handleChange('medicalAdherence', 'appointmentAttendance', e.target.value)}
          className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
        />
      </div>
      <div>
        <label htmlFor="treatmentPlanAdherence" className="block text-sm font-medium text-gray-700">Treatment Plan Adherence</label>
        <input
          type="text"
          id="treatmentPlanAdherence"
          value={newPatient.medicalAdherence.treatmentPlanAdherence}
          onChange={(e) => handleChange('medicalAdherence', 'treatmentPlanAdherence', e.target.value)}
          className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
        />
      </div>
      <div>
        <label htmlFor="adherenceNotes" className="block text-sm font-medium text-gray-700">Notes</label>
        <textarea
          id="adherenceNotes"
          value={newPatient.medicalAdherence.notes}
          onChange={(e) => handleChange('medicalAdherence', 'notes', e.target.value)}
          rows="3"
          className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
        ></textarea>
      </div>
    </div>
  </div>
);

const TransfersSection = ({ newPatient, handleArrayChange }) => (
  <div>
    <h2 className="text-xl font-semibold mb-2">Transfers</h2>
    {newPatient.transfers.map((transfer, index) => (
      <div key={index} className="mb-4 p-4 bg-gray-100 rounded">
        <p><strong>Date:</strong> {transfer.date}</p>
        <p><strong>From:</strong> {transfer.from}</p>
        <p><strong>To:</strong> {transfer.to}</p>
        <p><strong>Reason:</strong> {transfer.reason}</p>
        <p><strong>Accompanying Staff:</strong> {transfer.staff}</p>
      </div>
    ))}
    <div className="space-y-4">
      <input type="date" id="transferDate" className="w-full border border-gray-300 rounded-md shadow-sm py-2 px-3" />
      <input type="text" placeholder="From" id="transferFrom" className="w-full border border-gray-300 rounded-md shadow-sm py-2 px-3" />
      <input type="text" placeholder="To" id="transferTo" className="w-full border border-gray-300 rounded-md shadow-sm py-2 px-3" />
      <input type="text" placeholder="Reason" id="transferReason" className="w-full border border-gray-300 rounded-md shadow-sm py-2 px-3" />
      <input type="text" placeholder="Accompanying Staff" id="transferStaff" className="w-full border border-gray-300 rounded-md shadow-sm py-2 px-3" />
      <button
        type="button"
        onClick={() => {
          const date = document.getElementById('transferDate').value;
          const from = document.getElementById('transferFrom').value;
          const to = document.getElementById('transferTo').value;
          const reason = document.getElementById('transferReason').value;
          const staff = document.getElementById('transferStaff').value;
          if (date && from && to && reason && staff) {
            handleArrayChange('transfers', { date, from, to, reason, staff });
            document.getElementById('transferDate').value = '';
            document.getElementById('transferFrom').value = '';
            document.getElementById('transferTo').value = '';
            document.getElementById('transferReason').value = '';
            document.getElementById('transferStaff').value = '';
          }
        }}
        className="w-full inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
      >
        Add Transfer
      </button>
    </div>
  </div>
);

const AssignedStaffSection = ({ newPatient, handleArrayChange }) => (
  <div>
    <h2 className="text-xl font-semibold mb-2">Assigned Staff</h2>
    {newPatient.assignedStaff.map((staff, index) => (
      <div key={index} className="mb-4 p-4 bg-gray-100 rounded">
        <p><strong>Name:</strong> {staff.name}</p>
        <p><strong>Role:</strong> {staff.role}</p>
        <p><strong>Contact:</strong> {staff.contact}</p>
        <p><strong>Schedule:</strong> {staff.schedule}</p>
      </div>
    ))}
    <div className="space-y-4">
      <input type="text" placeholder="Name" id="staffName" className="w-full border border-gray-300 rounded-md shadow-sm py-2 px-3" />
      <input type="text" placeholder="Role" id="staffRole" className="w-full border border-gray-300 rounded-md shadow-sm py-2 px-3" />
      <input type="text" placeholder="Contact" id="staffContact" className="w-full border border-gray-300 rounded-md shadow-sm py-2 px-3" />
      <input type="text" placeholder="Schedule" id="staffSchedule" className="w-full border border-gray-300 rounded-md shadow-sm py-2 px-3" />
      <button
        type="button"
        onClick={() => {
          const name = document.getElementById('staffName').value;
          const role = document.getElementById('staffRole').value;
          const contact = document.getElementById('staffContact').value;
          const schedule = document.getElementById('staffSchedule').value;
          if (name && role && contact && schedule) {
            handleArrayChange('assignedStaff', { name, role, contact, schedule });
            document.getElementById('staffName').value = '';
            document.getElementById('staffRole').value = '';
            document.getElementById('staffContact').value = '';
            document.getElementById('staffSchedule').value = '';
          }
        }}
        className="w-full inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
      >
        Add Staff
      </button>
    </div>
  </div>
);

const CommunityActivityLogsSection = ({ newPatient, handleArrayChange }) => (
  <div>
    <h2 className="text-xl font-semibold mb-2">Community Activity Logs</h2>
    {newPatient.communityActivityLogs.map((activity, index) => (
      <div key={index} className="mb-4 p-4 bg-gray-100 rounded">
        <p><strong>Date:</strong> {activity.date}</p>
        <p><strong>Activity:</strong> {activity.type}</p>
        <p><strong>Duration:</strong> {activity.duration}</p>
        <p><strong>Location:</strong> {activity.location}</p>
        <p><strong>Staff:</strong> {activity.staff}</p>
        <p><strong>Notes:</strong> {activity.notes}</p>
      </div>
    ))}
    <div className="space-y-4">
      <input type="date" id="activityDate" className="w-full border border-gray-300 rounded-md shadow-sm py-2 px-3" />
      <input type="text" placeholder="Activity Type" id="activityType" className="w-full border border-gray-300 rounded-md shadow-sm py-2 px-3" />
      <input type="text" placeholder="Duration" id="activityDuration" className="w-full border border-gray-300 rounded-md shadow-sm py-2 px-3" />
      <input type="text" placeholder="Location" id="activityLocation" className="w-full border border-gray-300 rounded-md shadow-sm py-2 px-3" />
      <input type="text" placeholder="Staff" id="activityStaff" className="w-full border border-gray-300 rounded-md shadow-sm py-2 px-3" />
      <textarea id="activityNotes" placeholder="Notes" rows="3" className="w-full border border-gray-300 rounded-md shadow-sm py-2 px-3"></textarea>
      <button
        type="button"
        onClick={() => {
          const date = document.getElementById('activityDate').value;
          const type = document.getElementById('activityType').value;
          const duration = document.getElementById('activityDuration').value;
          const location = document.getElementById('activityLocation').value;
          const staff = document.getElementById('activityStaff').value;
          const notes = document.getElementById('activityNotes').value;
          if (date && type && duration && location && staff) {
            handleArrayChange('communityActivityLogs', { date, type, duration, location, staff, notes });
            document.getElementById('activityDate').value = '';
            document.getElementById('activityType').value = '';
            document.getElementById('activityDuration').value = '';
            document.getElementById('activityLocation').value = '';
            document.getElementById('activityStaff').value = '';
            document.getElementById('activityNotes').value = '';
          }
        }}
        className="w-full inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
      >
        Add Activity
      </button>
    </div>
  </div>
);

const InventorySection = ({ newPatient, handleChange, handleArrayChange }) => (
  <div>
    <h2 className="text-xl font-semibold mb-2">Inventory</h2>
    <div className="space-y-4">
      <div>
        <label htmlFor="lastInventoryDate" className="block text-sm font-medium text-gray-700">Last Inventory Date</label>
        <input
          type="date"
          id="lastInventoryDate"
          value={newPatient.inventory.lastInventoryDate}
          onChange={(e) => handleChange('inventory', 'lastInventoryDate', e.target.value)}
          className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
        />
      </div>
      <div>
        <h3 className="text-lg font-semibold mt-4 mb-2">Personal Belongings</h3>
        {newPatient.inventory.personalBelongings.map((item, index) => (
          <div key={index} className="mb-2">
            {item.name} - Condition: {item.condition}
          </div>
        ))}
        <div className="flex space-x-2">
          <input type="text" placeholder="Item Name" id="belongingName" className="flex-1 border border-gray-300 rounded-md shadow-sm py-2 px-3" />
          <input type="text" placeholder="Condition" id="belongingCondition" className="flex-1 border border-gray-300 rounded-md shadow-sm py-2 px-3" />
          <button
            type="button"
            onClick={() => {
              const name = document.getElementById('belongingName').value;
              const condition = document.getElementById('belongingCondition').value;
              if (name && condition) {
                handleArrayChange('inventory.personalBelongings', { name, condition });
                document.getElementById('belongingName').value = '';
                document.getElementById('belongingCondition').value = '';
              }
            }}
            className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
          >
            Add
          </button>
        </div>
      </div>
      <div>
        <h3 className="text-lg font-semibold mt-4 mb-2">Medical Equipment</h3>
        {newPatient.inventory.medicalEquipment.map((item, index) => (
          <div key={index} className="mb-2">
            {item.name} - Condition: {item.condition}
          </div>
        ))}
        <div className="flex space-x-2">
          <input type="text" placeholder="Equipment Name" id="equipmentName" className="flex-1 border border-gray-300 rounded-md shadow-sm py-2 px-3" />
          <input type="text" placeholder="Condition" id="equipmentCondition" className="flex-1 border border-gray-300 rounded-md shadow-sm py-2 px-3" />
          <button
            type="button"
            onClick={() => {
              const name = document.getElementById('equipmentName').value;
              const condition = document.getElementById('equipmentCondition').value;
              if (name && condition) {
                handleArrayChange('inventory.medicalEquipment', { name, condition });
                document.getElementById('equipmentName').value = '';
                document.getElementById('equipmentCondition').value = '';
              }
            }}
            className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
          >
            Add
          </button>
        </div>
      </div>
    </div>
  </div>
);

const SignificantEventsSection = ({ newPatient, handleArrayChange }) => (
  <div>
    <h2 className="text-xl font-semibold mb-2">Significant Events</h2>
    {newPatient.significantEvents.map((event, index) => (
      <div key={index} className="mb-4 p-4 bg-gray-100 rounded">
        <p><strong>Date:</strong> {event.date}</p>
        <p><strong>Type:</strong> {event.type}</p>
        <p><strong>Description:</strong> {event.description}</p>
        <p><strong>Staff Involved:</strong> {event.staffInvolved}</p>
        <p><strong>Follow-up:</strong> {event.followUp}</p>
      </div>
    ))}
    <div className="space-y-4">
      <input type="date" id="eventDate" className="w-full border border-gray-300 rounded-md shadow-sm py-2 px-3" />
      <input type="text" placeholder="Event Type" id="eventType" className="w-full border border-gray-300 rounded-md shadow-sm py-2 px-3" />
      <textarea id="eventDescription" placeholder="Description" rows="3" className="w-full border border-gray-300 rounded-md shadow-sm py-2 px-3"></textarea>
      <input type="text" placeholder="Staff Involved" id="eventStaff" className="w-full border border-gray-300 rounded-md shadow-sm py-2 px-3" />
      <textarea id="eventFollowUp" placeholder="Follow-up" rows="3" className="w-full border border-gray-300 rounded-md shadow-sm py-2 px-3"></textarea>
      <button
        type="button"
        onClick={() => {
          const date = document.getElementById('eventDate').value;
          const type = document.getElementById('eventType').value;
          const description = document.getElementById('eventDescription').value;
          const staffInvolved = document.getElementById('eventStaff').value;
          const followUp = document.getElementById('eventFollowUp').value;
          if (date && type && description && staffInvolved) {
            handleArrayChange('significantEvents', { date, type, description, staffInvolved, followUp });
            document.getElementById('eventDate').value = '';
            document.getElementById('eventType').value = '';
            document.getElementById('eventDescription').value = '';
            document.getElementById('eventStaff').value = '';
            document.getElementById('eventFollowUp').value = '';
          }
        }}
        className="w-full inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
      >
        Add Event
      </button>
    </div>
  </div>
);

const MedicalContactsSection = ({ newPatient, handleArrayChange }) => (
  <div>
    <h2 className="text-xl font-semibold mb-2">Medical Contacts</h2>
    {newPatient.medicalContacts.map((contact, index) => (
      <div key={index} className="mb-4 p-4 bg-gray-100 rounded">
        <p><strong>Name:</strong> {contact.name}</p>
        <p><strong>Specialty:</strong> {contact.specialty}</p>
        <p><strong>Contact:</strong> {contact.contact}</p>
        <p><strong>Last Appointment:</strong> {contact.lastAppointment}</p>
        <p><strong>Next Appointment:</strong> {contact.nextAppointment}</p>
      </div>
    ))}
    <div className="space-y-4">
      <input type="text" placeholder="Name" id="contactName" className="w-full border border-gray-300 rounded-md shadow-sm py-2 px-3" />
      <input type="text" placeholder="Specialty" id="contactSpecialty" className="w-full border border-gray-300 rounded-md shadow-sm py-2 px-3" />
      <input type="text" placeholder="Contact" id="contactInfo" className="w-full border border-gray-300 rounded-md shadow-sm py-2 px-3" />
      <input type="date" placeholder="Last Appointment" id="lastAppointment" className="w-full border border-gray-300 rounded-md shadow-sm py-2 px-3" />
      <input type="date" placeholder="Next Appointment" id="nextAppointment" className="w-full border border-gray-300 rounded-md shadow-sm py-2 px-3" />
      <button
        type="button"
        onClick={() => {
          const name = document.getElementById('contactName').value;
          const specialty = document.getElementById('contactSpecialty').value;
          const contact = document.getElementById('contactInfo').value;
          const lastAppointment = document.getElementById('lastAppointment').value;
          const nextAppointment = document.getElementById('nextAppointment').value;
          if (name && specialty && contact) {
            handleArrayChange('medicalContacts', { name, specialty, contact, lastAppointment, nextAppointment });
            document.getElementById('contactName').value = '';
            document.getElementById('contactSpecialty').value = '';
            document.getElementById('contactInfo').value = '';
            document.getElementById('lastAppointment').value = '';
            document.getElementById('nextAppointment').value = '';
          }
        }}
        className="w-full inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
      >
        Add Medical Contact
      </button>
    </div>
  </div>
);

const FacesheetSection = ({ newPatient, handleChange, handleArrayChange }) => (
  <div>
    <h2 className="text-xl font-semibold mb-2">Facesheet</h2>
    <div className="space-y-4">
      <div>
        <label htmlFor="lawEnforcement" className="block text-sm font-medium text-gray-700">Law Enforcement</label>
        <input
          type="text"
          id="lawEnforcement"
          value={newPatient.facesheet.lawEnforcement}
          onChange={(e) => handleChange('facesheet', 'lawEnforcement', e.target.value)}
          className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
        />
      </div>
      <div>
        <label htmlFor="paramedics" className="block text-sm font-medium text-gray-700">Paramedics</label>
        <input
          type="text"
          id="paramedics"
          value={newPatient.facesheet.paramedics}
          onChange={(e) => handleChange('facesheet', 'paramedics', e.target.value)}
          className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
        />
      </div>
      <div>
        <label htmlFor="supportCoordinators" className="block text-sm font-medium text-gray-700">Support Coordinators</label>
        <input
          type="text"
          id="supportCoordinators"
          value={newPatient.facesheet.supportCoordinators}
          onChange={(e) => handleChange('facesheet', 'supportCoordinators', e.target.value)}
          className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
        />
      </div>
      <div>
        <label htmlFor="hospital" className="block text-sm font-medium text-gray-700">Hospital</label>
        <input
          type="text"
          id="hospital"
          value={newPatient.facesheet.hospital}
          onChange={(e) => handleChange('facesheet', 'hospital', e.target.value)}
          className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
        />
      </div>
      <div>
        <label htmlFor="apd" className="block text-sm font-medium text-gray-700">APD</label>
        <input
          type="text"
          id="apd"
          value={newPatient.facesheet.apd}
          onChange={(e) => handleChange('facesheet', 'apd', e.target.value)}
          className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
        />
      </div>
      <div>
        <h3 className="text-lg font-semibold mt-4 mb-2">Family Members</h3>
        {newPatient.facesheet.familyMembers.map((member, index) => (
          <div key={index} className="mb-2">
            {member.name} - {member.relationship} - {member.contact}
          </div>
        ))}
        <div className="flex space-x-2">
          <input type="text" placeholder="Name" id="familyMemberName" className="flex-1 border border-gray-300 rounded-md shadow-sm py-2 px-3" />
          <input type="text" placeholder="Relationship" id="familyMemberRelationship" className="flex-1 border border-gray-300 rounded-md shadow-sm py-2 px-3" />
          <input type="text" placeholder="Contact" id="familyMemberContact" className="flex-1 border border-gray-300 rounded-md shadow-sm py-2 px-3" />
          <button
            type="button"
            onClick={() => {
              const name = document.getElementById('familyMemberName').value;
              const relationship = document.getElementById('familyMemberRelationship').value;
              const contact = document.getElementById('familyMemberContact').value;
              if (name && relationship && contact) {
                handleArrayChange('facesheet.familyMembers', { name, relationship, contact });
                document.getElementById('familyMemberName').value = '';
                document.getElementById('familyMemberRelationship').value = '';
                document.getElementById('familyMemberContact').value = '';
              }
            }}
            className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
          >
            Add
          </button>
        </div>
      </div>
    </div>
  </div>
);

const IncomeAndExpensesSection = ({ newPatient, handleChange, handleArrayChange }) => (
  <div>
    <h2 className="text-xl font-semibold mb-2">Income and Expenses</h2>
    <div className="space-y-4">
      <div>
        <h3 className="text-lg font-semibold mt-4 mb-2">Income Sources</h3>
        {newPatient.incomeAndExpenses.incomeSources.map((source, index) => (
          <div key={index} className="mb-2">
            {source.name}: ${source.amount}
          </div>
        ))}
        <div className="flex space-x-2">
          <input type="text" placeholder="Source Name" id="incomeSourceName" className="flex-1 border border-gray-300 rounded-md shadow-sm py-2 px-3" />
          <input type="number" placeholder="Amount" id="incomeSourceAmount" className="flex-1 border border-gray-300 rounded-md shadow-sm py-2 px-3" />
          <button
            type="button"
            onClick={() => {
              const name = document.getElementById('incomeSourceName').value;
              const amount = document.getElementById('incomeSourceAmount').value;
              if (name && amount) {
                handleArrayChange('incomeAndExpenses.incomeSources', { name, amount: parseFloat(amount) });
                document.getElementById('incomeSourceName').value = '';
                document.getElementById('incomeSourceAmount').value = '';
              }
            }}
            className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
          >
            Add
          </button>
        </div>
      </div>
      <div>
        <label htmlFor="totalMonthlyIncome" className="block text-sm font-medium text-gray-700">Total Monthly Income</label>
        <input
          type="number"
          id="totalMonthlyIncome"
          value={newPatient.incomeAndExpenses.totalMonthlyIncome}
          onChange={(e) => handleChange('incomeAndExpenses', 'totalMonthlyIncome', parseFloat(e.target.value))}
          className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
        />
      </div>
      <div>
        <h3 className="text-lg font-semibold mt-4 mb-2">Fixed Expenses</h3>
        {newPatient.incomeAndExpenses.fixedExpenses.map((expense, index) => (
          <div key={index} className="mb-2">
            {expense.name}: ${expense.amount}
          </div>
        ))}
        <div className="flex space-x-2">
          <input type="text" placeholder="Expense Name" id="fixedExpenseName" className="flex-1 border border-gray-300 rounded-md shadow-sm py-2 px-3" />
          <input type="number" placeholder="Amount" id="fixedExpenseAmount" className="flex-1 border border-gray-300 rounded-md shadow-sm py-2 px-3" />
          <button
            type="button"
            onClick={() => {
              const name = document.getElementById('fixedExpenseName').value;
              const amount = document.getElementById('fixedExpenseAmount').value;
              if (name && amount) {
                handleArrayChange('incomeAndExpenses.fixedExpenses', { name, amount: parseFloat(amount) });
                document.getElementById('fixedExpenseName').value = '';
                document.getElementById('fixedExpenseAmount').value = '';
              }
            }}
            className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
          >
            Add
          </button>
        </div>
      </div>
      <div>
        <h3 className="text-lg font-semibold mt-4 mb-2">Variable Expenses</h3>
        {newPatient.incomeAndExpenses.variableExpenses.map((expense, index) => (
          <div key={index} className="mb-2">
            {expense.name}: ${expense.amount}
          </div>
        ))}
        <div className="flex space-x-2">
          <input type="text" placeholder="Expense Name" id="variableExpenseName" className="flex-1 border border-gray-300 rounded-md shadow-sm py-2 px-3" />
          <input type="number" placeholder="Amount" id="variableExpenseAmount" className="flex-1 border border-gray-300 rounded-md shadow-sm py-2 px-3" />
          <button
            type="button"
            onClick={() => {
              const name = document.getElementById('variableExpenseName').value;
              const amount = document.getElementById('variableExpenseAmount').value;
              if (name && amount) {
                handleArrayChange('incomeAndExpenses.variableExpenses', { name, amount: parseFloat(amount) });
                document.getElementById('variableExpenseName').value = '';
                document.getElementById('variableExpenseAmount').value = '';
              }
            }}
            className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
          >
            Add
          </button>
        </div>
      </div>
      <div>
        <label htmlFor="totalMonthlyExpenses" className="block text-sm font-medium text-gray-700">Total Monthly Expenses</label>
        <input
          type="number"
          id="totalMonthlyExpenses"
          value={newPatient.incomeAndExpenses.totalMonthlyExpenses}
          onChange={(e) => handleChange('incomeAndExpenses', 'totalMonthlyExpenses', parseFloat(e.target.value))}
          className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
        />
      </div>
      <div>
        <label htmlFor="monthlySavings" className="block text-sm font-medium text-gray-700">Monthly Savings</label>
        <input
          type="number"
          id="monthlySavings"
          value={newPatient.incomeAndExpenses.monthlySavings}
          onChange={(e) => handleChange('incomeAndExpenses', 'monthlySavings', parseFloat(e.target.value))}
          className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
        />
      </div>
    </div>
  </div>
);

const LogsSection = ({ newPatient, handleArrayChange }) => (
  <div>
    <h2 className="text-xl font-semibold mb-2">Logs</h2>
    <div className="space-y-6">
      <div>
        <h3 className="text-lg font-semibold mt-4 mb-2">Rehab Logs</h3>
        {newPatient.logs.rehabLogs.map((log, index) => (
          <div key={index} className="mb-2 p-2 bg-gray-100 rounded">
            <p><strong>Date:</strong> {log.date}</p>
            <p><strong>Activity:</strong> {log.activity}</p>
            <p><strong>Progress:</strong> {log.progress}</p>
          </div>
        ))}
        <div className="space-y-2">
          <input type="date" id="rehabDate" className="w-full border border-gray-300 rounded-md shadow-sm py-2 px-3" />
          <input type="text" placeholder="Activity" id="rehabActivity" className="w-full border border-gray-300 rounded-md shadow-sm py-2 px-3" />
          <textarea id="rehabProgress" placeholder="Progress" rows="3" className="w-full border border-gray-300 rounded-md shadow-sm py-2 px-3"></textarea>
          <button
            type="button"
            onClick={() => {
              const date = document.getElementById('rehabDate').value;
              const activity = document.getElementById('rehabActivity').value;
              const progress = document.getElementById('rehabProgress').value;
              if (date && activity && progress) {
                handleArrayChange('logs.rehabLogs', { date, activity, progress });
                document.getElementById('rehabDate').value = '';
                document.getElementById('rehabActivity').value = '';
                document.getElementById('rehabProgress').value = '';
              }
            }}
            className="w-full inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
          >
            Add Rehab Log
          </button>
        </div>
      </div>

      <div>
        <h3 className="text-lg font-semibold mt-4 mb-2">Respite Logs</h3>
        {newPatient.logs.respiteLogs.map((log, index) => (
          <div key={index} className="mb-2 p-2 bg-gray-100 rounded">
            <p><strong>Date:</strong> {log.date}</p>
            <p><strong>Duration:</strong> {log.duration}</p>
            <p><strong>Reason:</strong> {log.reason}</p>
          </div>
        ))}
        <div className="space-y-2">
          <input type="date" id="respiteDate" className="w-full border border-gray-300 rounded-md shadow-sm py-2 px-3" />
          <input type="text" placeholder="Duration" id="respiteDuration" className="w-full border border-gray-300 rounded-md shadow-sm py-2 px-3" />
          <textarea id="respiteReason" placeholder="Reason" rows="3" className="w-full border border-gray-300 rounded-md shadow-sm py-2 px-3"></textarea>
          <button
            type="button"
            onClick={() => {
              const date = document.getElementById('respiteDate').value;
              const duration = document.getElementById('respiteDuration').value;
              const reason = document.getElementById('respiteReason').value;
              if (date && duration && reason) {
                handleArrayChange('logs.respiteLogs', { date, duration, reason });
                document.getElementById('respiteDate').value = '';
                document.getElementById('respiteDuration').value = '';
                document.getElementById('respiteReason').value = '';
              }
            }}
            className="w-full inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
          >
            Add Respite Log
          </button>
        </div>
      </div>

      <div>
        <h3 className="text-lg font-semibold mt-4 mb-2">Life Skills Logs</h3>
        {newPatient.logs.lifeSkillsLogs.map((log, index) => (
          <div key={index} className="mb-2 p-2 bg-gray-100 rounded">
            <p><strong>Date:</strong> {log.date}</p>
            <p><strong>Skill Practiced:</strong> {log.skillPracticed}</p>
            <p><strong>Progress:</strong> {log.progress}</p>
          </div>
        ))}
        <div className="space-y-2">
          <input type="date" id="lifeSkillsDate" className="w-full border border-gray-300 rounded-md shadow-sm py-2 px-3" />
          <input type="text" placeholder="Skill Practiced" id="lifeSkillsPracticed" className="w-full border border-gray-300 rounded-md shadow-sm py-2 px-3" />
          <textarea id="lifeSkillsProgress" placeholder="Progress" rows="3" className="w-full border border-gray-300 rounded-md shadow-sm py-2 px-3"></textarea>
          <button
            type="button"
            onClick={() => {
              const date = document.getElementById('lifeSkillsDate').value;
              const skillPracticed = document.getElementById('lifeSkillsPracticed').value;
              const progress = document.getElementById('lifeSkillsProgress').value;
              if (date && skillPracticed && progress) {
                handleArrayChange('logs.lifeSkillsLogs', { date, skillPracticed, progress });
                document.getElementById('lifeSkillsDate').value = '';
                document.getElementById('lifeSkillsPracticed').value = '';
                document.getElementById('lifeSkillsProgress').value = '';
              }
            }}
            className="w-full inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
          >
            Add Life Skills Log
          </button>
        </div>
      </div>

      <div>
        <h3 className="text-lg font-semibold mt-4 mb-2">Personal Support Logs</h3>
        {newPatient.logs.personalSupportLogs.map((log, index) => (
          <div key={index} className="mb-2 p-2 bg-gray-100 rounded">
            <p><strong>Date:</strong> {log.date}</p>
            <p><strong>Type of Support:</strong> {log.supportType}</p>
            <p><strong>Staff:</strong> {log.staff}</p>
          </div>
        ))}
        <div className="space-y-2">
          <input type="date" id="personalSupportDate" className="w-full border border-gray-300 rounded-md shadow-sm py-2 px-3" />
          <input type="text" placeholder="Type of Support" id="personalSupportType" className="w-full border border-gray-300 rounded-md shadow-sm py-2 px-3" />
          <input type="text" placeholder="Staff" id="personalSupportStaff" className="w-full border border-gray-300 rounded-md shadow-sm py-2 px-3" />
          <button
            type="button"
            onClick={() => {
              const date = document.getElementById('personalSupportDate').value;
              const supportType = document.getElementById('personalSupportType').value;
              const staff = document.getElementById('personalSupportStaff').value;
              if (date && supportType && staff) {
                handleArrayChange('logs.personalSupportLogs', { date, supportType, staff });
                document.getElementById('personalSupportDate').value = '';
                document.getElementById('personalSupportType').value = '';
                document.getElementById('personalSupportStaff').value = '';
              }
            }}
            className="w-full inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
          >
            Add Personal Support Log
          </button>
        </div>
      </div>

      <div>
        <h3 className="text-lg font-semibold mt-4 mb-2">Seizure Logs</h3>
        {newPatient.logs.seizureLogs.map((log, index) => (
          <div key={index} className="mb-2 p-2 bg-gray-100 rounded">
            <p><strong>Date:</strong> {log.date}</p>
            <p><strong>Duration:</strong> {log.duration}</p>
            <p><strong>Type:</strong> {log.type}</p>
            <p><strong>Triggers:</strong> {log.triggers}</p>
          </div>
        ))}
        <div className="space-y-2">
          <input type="date" id="seizureDate" className="w-full border border-gray-300 rounded-md shadow-sm py-2 px-3" />
          <input type="text" placeholder="Duration" id="seizureDuration" className="w-full border border-gray-300 rounded-md shadow-sm py-2 px-3" />
          <input type="text" placeholder="Type" id="seizureType" className="w-full border border-gray-300 rounded-md shadow-sm py-2 px-3" />
          <textarea id="seizureTriggers" placeholder="Triggers" rows="3" className="w-full border border-gray-300 rounded-md shadow-sm py-2 px-3"></textarea>
          <button
            type="button"
            onClick={() => {
              const date = document.getElementById('seizureDate').value;
              const duration = document.getElementById('seizureDuration').value;
              const type = document.getElementById('seizureType').value;
              const triggers = document.getElementById('seizureTriggers').value;
              if (date && duration && type) {
                handleArrayChange('logs.seizureLogs', { date, duration, type, triggers });
                document.getElementById('seizureDate').value = '';
                document.getElementById('seizureDuration').value = '';
                document.getElementById('seizureType').value = '';
                document.getElementById('seizureTriggers').value = '';
              }
            }}
            className="w-full inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
          >
            Add Seizure Log
          </button>
        </div>
      </div>
    </div>
  </div>
);

export default AddPatientEMR;