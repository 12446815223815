import React, { useState } from 'react';
import { addDoc, collection } from 'firebase/firestore';
import { db } from '../firebase';
import { useNavigate } from 'react-router-dom';
import Layout from './Layout';

const AddSubscriber = () => {
  const navigate = useNavigate();
  const [subscriber, setSubscriber] = useState({
    name: '',
    email: '',
    subscriptionType: '',
    startDate: '',
    endDate: ''
  });
  const [error, setError] = useState(null);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setSubscriber(prev => ({ ...prev, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await addDoc(collection(db, 'subscribers'), {
        ...subscriber,
        createdAt: new Date()
      });
      navigate('/subscribers');
    } catch (err) {
      setError('Failed to add subscriber: ' + err.message);
    }
  };

  return (
    <Layout title="Add Subscriber">
      <div className="max-w-2xl mx-auto">
        <h1 className="text-2xl font-bold mb-4 text-left">Add New Subscriber</h1>
        {error && <p className="text-red-500 mb-4 text-left">{error}</p>}
        <form onSubmit={handleSubmit} className="space-y-4">
          <div>
            <label htmlFor="name" className="block text-sm font-medium text-gray-700 text-left">Name</label>
            <input
              type="text"
              id="name"
              name="name"
              value={subscriber.name}
              onChange={handleChange}
              required
              className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
            />
          </div>
          <div>
            <label htmlFor="email" className="block text-sm font-medium text-gray-700 text-left">Email</label>
            <input
              type="email"
              id="email"
              name="email"
              value={subscriber.email}
              onChange={handleChange}
              required
              className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
            />
          </div>
          <div>
            <label htmlFor="subscriptionType" className="block text-sm font-medium text-gray-700 text-left">Subscription Type</label>
            <select
              id="subscriptionType"
              name="subscriptionType"
              value={subscriber.subscriptionType}
              onChange={handleChange}
              required
              className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
            >
              <option value="">Select a subscription type</option>
              <option value="Basic">Basic</option>
              <option value="Premium">Premium</option>
              <option value="Enterprise">Enterprise</option>
            </select>
          </div>
          <div>
            <label htmlFor="startDate" className="block text-sm font-medium text-gray-700 text-left">Start Date</label>
            <input
              type="date"
              id="startDate"
              name="startDate"
              value={subscriber.startDate}
              onChange={handleChange}
              required
              className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
            />
          </div>
          <div>
            <label htmlFor="endDate" className="block text-sm font-medium text-gray-700 text-left">End Date</label>
            <input
              type="date"
              id="endDate"
              name="endDate"
              value={subscriber.endDate}
              onChange={handleChange}
              required
              className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
            />
          </div>
          <div className="flex justify-end space-x-2">
            <button
              type="submit"
              className="px-4 py-2 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
              Add Subscriber
            </button>
            <button
              type="button"
              onClick={() => navigate('/subscribers')}
              className="px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
              Cancel
            </button>
          </div>
        </form>
      </div>
    </Layout>
  );
};

export default AddSubscriber;