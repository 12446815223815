import React, { useState, useEffect, useCallback } from 'react';
import { setDoc, doc, GeoPoint, serverTimestamp } from 'firebase/firestore';
import { db } from '../firebase';
import { useNavigate } from 'react-router-dom';
import { loadGoogleMapsApi } from '../services/googleMapsLoader';
import Layout from './Layout';

const AddClinic = () => {
    const navigate = useNavigate();
    const [newClinic, setNewClinic] = useState({
      name: '',
      location: '',
      contact: '',
      email: '',
      website: '',
      specialties: [],
      operatingHours: {
        Monday: '',
        Tuesday: '',
        Wednesday: '',
        Thursday: '',
        Friday: '',
        Saturday: '',
        Sunday: ''
      },
      insuranceAccepted: [],
      capacity: '',
    });
    const [mapCenter, setMapCenter] = useState({ lat: 0, lng: 0 });
    const [errorMessage, setErrorMessage] = useState('');
    const [googleMapsLoaded, setGoogleMapsLoaded] = useState(false);
  
    useEffect(() => {
      loadGoogleMapsApi()
        .then(() => {
          setGoogleMapsLoaded(true);
        })
        .catch(err => {
          console.error("Error loading Google Maps API:", err);
          setErrorMessage('Failed to load Google Maps: ' + err.message);
        });
    }, []);

    const generateSlug = (name) => {
      return name.toLowerCase().replace(/\s+/g, '-').replace(/[^a-z0-9-]+/g, '');
    };
  
    const handleAddClinic = async (e) => {
      e.preventDefault();
      try {
        const slug = generateSlug(newClinic.name);
        const clinicData = {
          ...newClinic,
          slug,
          capacity: newClinic.capacity || '',
          coordinates: new GeoPoint(mapCenter.lat, mapCenter.lng),
          createdAt: serverTimestamp(),
          updatedAt: serverTimestamp()
        };
  
        await setDoc(doc(db, 'clinics', slug), clinicData);
        navigate('/clinics');
      } catch (err) {
        console.error("Error adding clinic:", err);
        setErrorMessage('Failed to add new clinic: ' + err.message);
      }
    };
  
    const handleChange = (e) => {
      const { name, value } = e.target;
      if (name.startsWith('operatingHours.')) {
        const day = name.split('.')[1];
        setNewClinic(prev => ({
          ...prev,
          operatingHours: {
            ...prev.operatingHours,
            [day]: value
          }
        }));
      } else if (name === 'specialties' || name === 'insuranceAccepted') {
        setNewClinic(prev => ({
          ...prev,
          [name]: value.split(',').map(item => item.trim())
        }));
      } else {
        setNewClinic(prev => ({ ...prev, [name]: value }));
      }
    };
  
    const handlePlaceSelect = useCallback(() => {
      if (googleMapsLoaded && window.google && window.google.maps && window.google.maps.places) {
        const autocomplete = new window.google.maps.places.Autocomplete(
          document.getElementById('location-input'),
          { types: ['geocode'] }
        );
        autocomplete.addListener('place_changed', () => {
          const place = autocomplete.getPlace();
          if (place.geometry && place.geometry.location) {
            setNewClinic(prev => ({
              ...prev,
              location: place.formatted_address,
            }));
            setMapCenter({
              lat: place.geometry.location.lat(),
              lng: place.geometry.location.lng(),
            });
          }
        });
      }
    }, [googleMapsLoaded]);
  
    useEffect(() => {
      if (googleMapsLoaded) {
        handlePlaceSelect();
      }
    }, [googleMapsLoaded, handlePlaceSelect]);

  return (
    <Layout title="Add Clinic">
      <div className="max-w-2xl mx-auto">
        <h2 className="text-2xl font-bold mb-4">Add New Clinic</h2>
        {errorMessage && <p className="text-red-500 mb-4">{errorMessage}</p>}
        <form onSubmit={handleAddClinic} className="space-y-4">
          <input
            name="name"
            value={newClinic.name}
            onChange={handleChange}
            placeholder="Clinic Name"
            required
            className="w-full p-2 border rounded"
          />
          <input
            id="location-input"
            name="location"
            value={newClinic.location}
            onChange={handleChange}
            placeholder="Location"
            required
            className="w-full p-2 border rounded"
          />
          <input
            name="contact"
            value={newClinic.contact}
            onChange={handleChange}
            placeholder="Contact Number"
            required
            className="w-full p-2 border rounded"
          />
          <input
            name="email"
            type="email"
            value={newClinic.email}
            onChange={handleChange}
            placeholder="Email"
            className="w-full p-2 border rounded"
          />
          <input
            name="website"
            type="url"
            value={newClinic.website}
            onChange={handleChange}
            placeholder="Website"
            className="w-full p-2 border rounded"
          />
          <input
            name="specialties"
            value={newClinic.specialties.join(', ')}
            onChange={handleChange}
            placeholder="Specialties (comma-separated)"
            className="w-full p-2 border rounded"
          />
          <input
            name="insuranceAccepted"
            value={newClinic.insuranceAccepted.join(', ')}
            onChange={handleChange}
            placeholder="Accepted Insurance (comma-separated)"
            className="w-full p-2 border rounded"
          />
          <input
            name="capacity"
            type="number"
            value={newClinic.capacity}
            onChange={handleChange}
            placeholder="Patient Capacity"
            className="w-full p-2 border rounded"
          />
          {Object.keys(newClinic.operatingHours).map((day) => (
            <input
              key={day}
              name={`operatingHours.${day}`}
              value={newClinic.operatingHours[day]}
              onChange={handleChange}
              placeholder={`${day} hours (e.g., 9:00 AM - 5:00 PM)`}
              className="w-full p-2 border rounded"
            />
          ))}
          <div className="flex justify-end">
            <button
              type="submit"
              className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600"
            >
              Add Clinic
            </button>
          </div>
        </form>
      </div>
    </Layout>
  );
};

export default AddClinic;