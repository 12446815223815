import React, { useState, useEffect } from 'react';
import { collection, getDocs, doc, deleteDoc, updateDoc } from 'firebase/firestore';
import { db } from '../firebase';
import Layout from './Layout';
import { Link } from 'react-router-dom';

const Admins = () => {
  const [admins, setAdmins] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [editingId, setEditingId] = useState(null);
  const [editForm, setEditForm] = useState({});

  const roles = [
    'Super Admin',
    'Clinical Admin',
    'Operational Admin',
    'Data Analyst',
    'Compliance Officer',
    'Technical Support',
    'Billing Admin',
    'Patient Care Coordinator'
  ];

  useEffect(() => {
    fetchAdmins();
  }, []);

  const fetchAdmins = async () => {
    try {
      const adminsCollection = collection(db, 'admins');
      const adminSnapshot = await getDocs(adminsCollection);
      const adminList = adminSnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));
      setAdmins(adminList);
      setLoading(false);
    } catch (err) {
      setError('Failed to fetch admins');
      setLoading(false);
    }
  };

  const handleEdit = (admin) => {
    setEditingId(admin.id);
    setEditForm(admin);
  };

  const handleSave = async () => {
    try {
      const adminRef = doc(db, 'admins', editingId);
      await updateDoc(adminRef, editForm);
      setEditingId(null);
      fetchAdmins();
    } catch (err) {
      setError('Failed to update admin');
    }
  };

  const handleDelete = async (id) => {
    if (window.confirm('Are you sure you want to delete this admin?')) {
      try {
        await deleteDoc(doc(db, 'admins', id));
        fetchAdmins();
      } catch (err) {
        setError('Failed to delete admin');
      }
    }
  };

  const handleChange = (e) => {
    setEditForm({ ...editForm, [e.target.name]: e.target.value });
  };

  const formatDate = (timestamp) => {
    if (!timestamp) return 'N/A';
    const date = timestamp.toDate();
    return date.toLocaleString('en-US', {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit'
    });
  };

  if (loading) {
    return <Layout title="Admins">Loading...</Layout>;
  }

  if (error) {
    return <Layout title="Admins">Error: {error}</Layout>;
  }

  return (
    <Layout title="Admins">
      <div className="mb-8 flex justify-between items-center">
        <h1 className="text-2xl font-bold">Admins</h1>
        <Link to="/add-admin" className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
          Add New Admin
        </Link>
      </div>

      <div className="flex flex-col">
        <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
            <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
              <table className="min-w-full divide-y divide-gray-200">
                <thead className="bg-gray-50">
                  <tr>
                    <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Name
                    </th>
                    <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Email
                    </th>
                    <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Role
                    </th>
                    <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Created At
                    </th>
                    <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Last Login
                    </th>
                    <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      IP Address
                    </th>
                    <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Actions
                    </th>
                  </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                  {admins.map((admin) => (
                    <tr key={admin.id}>
                      <td className="px-6 py-4 whitespace-nowrap text-left">
                        {editingId === admin.id ? (
                          <input
                            type="text"
                            name="name"
                            value={editForm.name}
                            onChange={handleChange}
                            className="border rounded px-2 py-1"
                          />
                        ) : (
                          <div className="text-sm font-medium text-gray-900">{admin.name}</div>
                        )}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-left">
                        <div className="text-sm text-gray-500">{admin.email}</div>
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-left">
                        {editingId === admin.id ? (
                          <select
                            name="role"
                            value={editForm.role}
                            onChange={handleChange}
                            className="border rounded px-2 py-1"
                          >
                            {roles.map(role => (
                              <option key={role} value={role}>{role}</option>
                            ))}
                          </select>
                        ) : (
                          <div className="text-sm text-gray-500">{admin.role}</div>
                        )}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-left">
                        <div className="text-sm text-gray-500">{formatDate(admin.createdAt)}</div>
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-left">
                        <div className="text-sm text-gray-500">
                          {admin.lastLogin ? formatDate(admin.lastLogin) : 'N/A'}
                        </div>
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-left">
                        <div className="text-sm text-gray-500">{admin.ipAddress || 'N/A'}</div>
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-left text-sm font-medium">
                        {editingId === admin.id ? (
                          <>
                            <button onClick={handleSave} className="text-indigo-600 hover:text-indigo-900 mr-2">
                              Save
                            </button>
                            <button onClick={() => setEditingId(null)} className="text-gray-600 hover:text-gray-900">
                              Cancel
                            </button>
                          </>
                        ) : (
                          <>
                            <button onClick={() => handleEdit(admin)} className="text-indigo-600 hover:text-indigo-900 mr-2">
                              Edit
                            </button>
                            <button onClick={() => handleDelete(admin.id)} className="text-red-600 hover:text-red-900">
                              Delete
                            </button>
                          </>
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Admins;