import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { doc, getDoc, updateDoc, GeoPoint } from 'firebase/firestore';
import { db } from '../firebase';
import Layout from './Layout';
import { loadGoogleMapsApi } from '../services/googleMapsLoader';

const EditDentalOffice = () => {
  const [dentalOffice, setDentalOffice] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [mapCenter, setMapCenter] = useState({ lat: 0, lng: 0 });
  const [googleMapsLoaded, setGoogleMapsLoaded] = useState(false);
  const { id } = useParams();
  const navigate = useNavigate();
  const locationInputRef = useRef(null);

  useEffect(() => {
    const fetchDentalOffice = async () => {
      try {
        const docRef = doc(db, 'dentalOffices', id);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
          const dentalOfficeData = { id: docSnap.id, ...docSnap.data() };
          setDentalOffice(dentalOfficeData);
          if (dentalOfficeData.coordinates) {
            setMapCenter({
              lat: dentalOfficeData.coordinates.latitude,
              lng: dentalOfficeData.coordinates.longitude
            });
          }
        } else {
          setError('Dental office not found');
        }
      } catch (err) {
        setError('Error fetching dental office details: ' + err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchDentalOffice();

    loadGoogleMapsApi()
      .then(() => {
        setGoogleMapsLoaded(true);
      })
      .catch(err => {
        console.error("Error loading Google Maps API:", err);
        setError('Failed to load Google Maps: ' + err.message);
      });
  }, [id]);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    if (name.startsWith('operatingHours.')) {
      const day = name.split('.')[1];
      setDentalOffice(prev => ({
        ...prev,
        operatingHours: {
          ...prev.operatingHours,
          [day]: value
        }
      }));
    } else if (['specialties', 'insuranceAccepted', 'servicesOffered', 'languagesSpoken', 'accessibilityFeatures'].includes(name)) {
      setDentalOffice(prev => ({
        ...prev,
        [name]: value.split(',').map(item => item.trim())
      }));
    } else if (type === 'checkbox') {
      setDentalOffice(prev => ({
        ...prev,
        [name]: checked
      }));
    } else {
      setDentalOffice(prev => ({ ...prev, [name]: value }));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const dentalOfficeRef = doc(db, 'dentalOffices', id);
      const updatedDentalOffice = {
        ...dentalOffice,
        coordinates: new GeoPoint(mapCenter.lat, mapCenter.lng),
        updatedAt: new Date()
      };
      await updateDoc(dentalOfficeRef, updatedDentalOffice);
      navigate(`/dental-offices/${id}`);
    } catch (err) {
      setError('Failed to update dental office: ' + err.message);
    }
  };

  const handlePlaceSelect = useCallback(() => {
    if (googleMapsLoaded && window.google && window.google.maps && window.google.maps.places && locationInputRef.current) {
      const autocomplete = new window.google.maps.places.Autocomplete(
        locationInputRef.current,
        { types: ['geocode'] }
      );
      autocomplete.addListener('place_changed', () => {
        const place = autocomplete.getPlace();
        if (place.geometry && place.geometry.location) {
          setDentalOffice(prev => ({
            ...prev,
            location: place.formatted_address,
          }));
          setMapCenter({
            lat: place.geometry.location.lat(),
            lng: place.geometry.location.lng(),
          });
        }
      });
    }
  }, [googleMapsLoaded]);

  useEffect(() => {
    if (googleMapsLoaded && locationInputRef.current) {
      handlePlaceSelect();
    }
  }, [googleMapsLoaded, handlePlaceSelect]);

  if (loading) return <Layout title="Edit Dental Office">Loading...</Layout>;
  if (error) return <Layout title="Edit Dental Office">Error: {error}</Layout>;
  if (!dentalOffice) return <Layout title="Edit Dental Office">Dental office not found</Layout>;

  return (
    <Layout title="Edit Dental Office">
      <form onSubmit={handleSubmit} className="space-y-4 text-left">
        <div>
          <label htmlFor="name" className="block text-sm font-medium text-gray-700">Name</label>
          <input
            type="text"
            name="name"
            id="name"
            value={dentalOffice.name}
            onChange={handleChange}
            className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
          />
        </div>

        <div>
          <label htmlFor="location" className="block text-sm font-medium text-gray-700">Location</label>
          <input
            type="text"
            name="location"
            id="location-input"
            ref={locationInputRef}
            value={dentalOffice.location}
            onChange={handleChange}
            className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
          />
        </div>

        <div>
          <label htmlFor="contact" className="block text-sm font-medium text-gray-700">Contact</label>
          <input
            type="text"
            name="contact"
            id="contact"
            value={dentalOffice.contact}
            onChange={handleChange}
            className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
          />
        </div>

        <div>
          <label htmlFor="email" className="block text-sm font-medium text-gray-700">Email</label>
          <input
            type="email"
            name="email"
            id="email"
            value={dentalOffice.email}
            onChange={handleChange}
            className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
          />
        </div>

        <div>
          <label htmlFor="website" className="block text-sm font-medium text-gray-700">Website</label>
          <input
            type="url"
            name="website"
            id="website"
            value={dentalOffice.website}
            onChange={handleChange}
            className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
          />
        </div>

        <div>
          <label htmlFor="specialties" className="block text-sm font-medium text-gray-700">Specialties</label>
          <input
            type="text"
            name="specialties"
            id="specialties"
            value={dentalOffice.specialties ? dentalOffice.specialties.join(', ') : ''}
            onChange={handleChange}
            className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
          />
        </div>

        <div>
          <label htmlFor="numberOfDentists" className="block text-sm font-medium text-gray-700">Number of Dentists</label>
          <input
            type="number"
            name="numberOfDentists"
            id="numberOfDentists"
            value={dentalOffice.numberOfDentists}
            onChange={handleChange}
            className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
          />
        </div>

        <div>
          <label htmlFor="servicesOffered" className="block text-sm font-medium text-gray-700">Services Offered</label>
          <input
            type="text"
            name="servicesOffered"
            id="servicesOffered"
            value={dentalOffice.servicesOffered ? dentalOffice.servicesOffered.join(', ') : ''}
            onChange={handleChange}
            className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
          />
        </div>

        <div className="flex items-center">
          <input
            type="checkbox"
            name="emergencyServices"
            id="emergencyServices"
            checked={dentalOffice.emergencyServices}
            onChange={handleChange}
            className="h-4 w-4 text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded"
          />
          <label htmlFor="emergencyServices" className="ml-2 block text-sm text-gray-900">
            Emergency Services Available
          </label>
        </div>

        <div>
          <label htmlFor="languagesSpoken" className="block text-sm font-medium text-gray-700">Languages Spoken</label>
          <input
            type="text"
            name="languagesSpoken"
            id="languagesSpoken"
            value={dentalOffice.languagesSpoken ? dentalOffice.languagesSpoken.join(', ') : ''}
            onChange={handleChange}
            className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
          />
        </div>

        <div className="flex items-center">
          <input
            type="checkbox"
            name="parkingAvailable"
            id="parkingAvailable"
            checked={dentalOffice.parkingAvailable}
            onChange={handleChange}
            className="h-4 w-4 text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded"
          />
          <label htmlFor="parkingAvailable" className="ml-2 block text-sm text-gray-900">
            Parking Available
          </label>
        </div>

        <div>
          <label htmlFor="accessibilityFeatures" className="block text-sm font-medium text-gray-700">Accessibility Features</label>
          <input
            type="text"
            name="accessibilityFeatures"
            id="accessibilityFeatures"
            value={dentalOffice.accessibilityFeatures ? dentalOffice.accessibilityFeatures.join(', ') : ''}
            onChange={handleChange}
            className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
          />
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700">Operating Hours</label>
          {['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'].map((day) => (
            <div key={day} className="flex items-center mt-2">
              <span className="w-24 text-sm">{day}:</span>
              <input
                type="text"
                name={`operatingHours.${day}`}
                value={dentalOffice.operatingHours?.[day] || ''}
                onChange={handleChange}
                className="ml-2 flex-grow border border-gray-300 rounded-md shadow-sm py-1 px-2 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                placeholder="e.g., 9:00 AM - 5:00 PM or Closed"
              />
            </div>
          ))}
        </div>

        <div>
          <label htmlFor="insuranceAccepted" className="block text-sm font-medium text-gray-700">Insurance Accepted</label>
          <input
            type="text"
            name="insuranceAccepted"
            id="insuranceAccepted"
            value={dentalOffice.insuranceAccepted ? dentalOffice.insuranceAccepted.join(', ') : ''}
            onChange={handleChange}
            className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
          />
        </div>

        <div className="flex justify-start">
          <button
            type="submit"
            className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            Save Changes
          </button>
        </div>
      </form>
    </Layout>
  );
};

export default EditDentalOffice;