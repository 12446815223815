import React, { useState } from 'react';
import { collection, addDoc } from 'firebase/firestore';
import { createUserWithEmailAndPassword } from 'firebase/auth';
import { db, auth } from '../firebase';
import Layout from './Layout';
import { useNavigate } from 'react-router-dom';

const AddAdmin = () => {
  const [newAdmin, setNewAdmin] = useState({ name: '', email: '', password: '', role: 'Clinical Admin' });
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  const roles = [
    'Super Admin',
    'Clinical Admin',
    'Operational Admin',
    'Data Analyst',
    'Compliance Officer',
    'Technical Support',
    'Billing Admin',
    'Patient Care Coordinator'
  ];

  const handleNewAdminChange = (e) => {
    setNewAdmin({ ...newAdmin, [e.target.name]: e.target.value });
  };

  const handleAddAdmin = async (e) => {
    e.preventDefault();
    try {
      // Create new user in Firebase Authentication
      const userCredential = await createUserWithEmailAndPassword(auth, newAdmin.email, newAdmin.password);
      const user = userCredential.user;

      // Add new admin to Firestore
      await addDoc(collection(db, 'admins'), {
        uid: user.uid,
        name: newAdmin.name,
        email: newAdmin.email,
        role: newAdmin.role,
        createdAt: new Date()
      });

      // Redirect to admins page after successful addition
      navigate('/admins');
    } catch (err) {
      setError('Failed to add new admin: ' + err.message);
    }
  };

  return (
    <Layout title="Add New Admin">
      <div className="max-w-md mx-auto">
        <h2 className="text-2xl font-bold mb-4">Add New Admin</h2>
        {error && <p className="text-red-500 mb-4">{error}</p>}
        <form onSubmit={handleAddAdmin} className="space-y-4">
          <input
            type="text"
            name="name"
            value={newAdmin.name}
            onChange={handleNewAdminChange}
            placeholder="Name"
            className="w-full p-2 border rounded"
            required
          />
          <input
            type="email"
            name="email"
            value={newAdmin.email}
            onChange={handleNewAdminChange}
            placeholder="Email"
            className="w-full p-2 border rounded"
            required
          />
          <input
            type="password"
            name="password"
            value={newAdmin.password}
            onChange={handleNewAdminChange}
            placeholder="Password"
            className="w-full p-2 border rounded"
            required
          />
          <select
            name="role"
            value={newAdmin.role}
            onChange={handleNewAdminChange}
            className="w-full p-2 border rounded"
            required
          >
            {roles.map(role => (
              <option key={role} value={role}>{role}</option>
            ))}
          </select>
          <button type="submit" className="bg-blue-500 text-white px-4 py-2 rounded w-full">
            Add Admin
          </button>
        </form>
      </div>
    </Layout>
  );
};

export default AddAdmin;