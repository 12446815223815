import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { collection, query, where, getDocs, doc, getDoc } from 'firebase/firestore';
import { db } from '../firebase';
import Layout from './Layout';

const InsuranceDetails = () => {
  const [insurance, setInsurance] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { slug } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchInsurance = async () => {
      try {
        let insuranceData = null;

        // First, try to fetch by slug
        const q = query(collection(db, 'insurance'), where('slug', '==', slug));
        let querySnapshot = await getDocs(q);

        if (!querySnapshot.empty) {
          insuranceData = { id: querySnapshot.docs[0].id, ...querySnapshot.docs[0].data() };
        } else {
          // If not found by slug, try to fetch by ID
          const docRef = doc(db, 'insurance', slug);
          const docSnap = await getDoc(docRef);

          if (docSnap.exists()) {
            insuranceData = { id: docSnap.id, ...docSnap.data() };
          }
        }

        if (insuranceData) {
          setInsurance(insuranceData);
        } else {
          setError('Insurance provider not found');
        }
      } catch (err) {
        setError('Error fetching insurance provider details: ' + err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchInsurance();
  }, [slug]);

  if (loading) return <Layout title="Insurance Provider Details">Loading...</Layout>;
  if (error) return <Layout title="Insurance Provider Details">Error: {error}</Layout>;
  if (!insurance) return <Layout title="Insurance Provider Details">Insurance provider not found</Layout>;

  return (
    <Layout title={`Insurance Provider: ${insurance.name}`}>
      <div className="bg-white shadow overflow-hidden sm:rounded-lg">
        <div className="px-4 py-5 sm:px-6">
          <h3 className="text-lg leading-6 font-medium text-gray-900 text-left">Insurance Provider Details</h3>
          <p className="mt-1 max-w-2xl text-sm text-gray-500 text-left">Detailed information about the insurance provider.</p>
        </div>
        <div className="border-t border-gray-200">
          <dl>
            <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500 text-left">Name</dt>
              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2 text-left">{insurance.name}</dd>
            </div>
            <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500 text-left">Type</dt>
              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2 text-left">{insurance.type}</dd>
            </div>
            <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500 text-left">Contact</dt>
              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2 text-left">{insurance.contact}</dd>
            </div>
            <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500 text-left">Email</dt>
              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2 text-left">{insurance.email}</dd>
            </div>
            <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500 text-left">Website</dt>
              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2 text-left">
                <a href={insurance.website} target="_blank" rel="noopener noreferrer" className="text-blue-600 hover:text-blue-800">
                  {insurance.website}
                </a>
              </dd>
            </div>
            <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500 text-left">Coverage Areas</dt>
              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2 text-left">
                {insurance.coverageAreas && insurance.coverageAreas.join(', ')}
              </dd>
            </div>
            <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500 text-left">Plan Types</dt>
              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2 text-left">
                {insurance.planTypes && insurance.planTypes.join(', ')}
              </dd>
            </div>
            <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500 text-left">Enrollment Process</dt>
              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2 text-left">{insurance.enrollmentProcess}</dd>
            </div>
            <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500 text-left">Customer Support</dt>
              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2 text-left">{insurance.customerSupport}</dd>
            </div>
          </dl>
        </div>
      </div>
      <div className="mt-4">
        <button
          onClick={() => navigate('/insurance')}
          className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded text-left"
        >
          Back to Insurance Providers
        </button>
      </div>
    </Layout>
  );
};

export default InsuranceDetails;