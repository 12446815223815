import React, { useState, useEffect } from 'react';
import { collection, getDocs, doc, deleteDoc } from 'firebase/firestore';
import { db } from '../firebase';
import Layout from './Layout';
import { useNavigate, Link } from 'react-router-dom';

const Providers = () => {
  const [providers, setProviders] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [sortBy, setSortBy] = useState('nameAZ');
  const [filterCountry, setFilterCountry] = useState('');
  const [filterDay, setFilterDay] = useState('');
  const [filterTime, setFilterTime] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    fetchProviders();
  }, []);

  const fetchProviders = async () => {
    try {
      const providersCollection = collection(db, 'providers');
      const providerSnapshot = await getDocs(providersCollection);
      const providerList = providerSnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data(),
        createdAt: doc.data().createdAt ? doc.data().createdAt.toDate() : new Date(0)
      }));
      setProviders(providerList);
      setLoading(false);
    } catch (err) {
      setError('Failed to fetch providers: ' + err.message);
      setLoading(false);
    }
  };

  const handleDelete = async (id) => {
    if (window.confirm('Are you sure you want to delete this provider?')) {
      try {
        await deleteDoc(doc(db, 'providers', id));
        fetchProviders();
      } catch (err) {
        setError('Failed to delete provider: ' + err.message);
      }
    }
  };

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleSortChange = (e) => {
    setSortBy(e.target.value);
  };

  const handleCountryFilterChange = (e) => {
    setFilterCountry(e.target.value);
  };

  const handleDayFilterChange = (e) => {
    setFilterDay(e.target.value);
  };

  const handleTimeFilterChange = (e) => {
    setFilterTime(e.target.value);
  };

  const generateSlug = (name) => {
    return name.toLowerCase().replace(/\s+/g, '-').replace(/[^a-z0-9-]+/g, '');
  };

  const filteredAndSortedProviders = providers
    .filter(provider => 
      (provider.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
      provider.specialty.toLowerCase().includes(searchTerm.toLowerCase()) ||
      provider.contact.toLowerCase().includes(searchTerm.toLowerCase())) &&
      (filterCountry === '' || provider.country.toLowerCase() === filterCountry.toLowerCase()) &&
      (filterDay === '' || (provider.availability[filterDay] && provider.availability[filterDay].isAvailable)) &&
      (filterTime === '' || (provider.availability[filterDay] && 
        provider.availability[filterDay].start <= filterTime &&
        provider.availability[filterDay].end >= filterTime))
    )
    .sort((a, b) => {
      switch (sortBy) {
        case 'nameAZ':
          return a.name.localeCompare(b.name);
        case 'nameZA':
          return b.name.localeCompare(a.name);
        case 'specialtyAZ':
          return a.specialty.localeCompare(b.specialty);
        case 'specialtyZA':
          return b.specialty.localeCompare(a.specialty);
        case 'mostRecent':
          return b.createdAt - a.createdAt;
        case 'oldest':
          return a.createdAt - b.createdAt;
        default:
          return 0;
      }
    });

  if (loading) {
    return <Layout title="Providers">Loading...</Layout>;
  }

  if (error) {
    return <Layout title="Providers">Error: {error}</Layout>;
  }

  return (
    <Layout title="Providers">
      <div className="mb-4 flex justify-between items-center">
        <h1 className="text-2xl font-bold">Providers</h1>
        <button
          onClick={() => navigate('/add-provider')}
          className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
        >
          Add Provider
        </button>
      </div>

      <div className="mb-4 space-y-2">
        <input
          type="text"
          placeholder="Search providers..."
          value={searchTerm}
          onChange={handleSearchChange}
          className="p-2 border rounded w-full"
        />
        <div className="flex space-x-2">
          <select
            value={sortBy}
            onChange={handleSortChange}
            className="p-2 border rounded"
          >
            <option value="nameAZ">Name (A-Z)</option>
            <option value="nameZA">Name (Z-A)</option>
            <option value="specialtyAZ">Specialty (A-Z)</option>
            <option value="specialtyZA">Specialty (Z-A)</option>
            <option value="mostRecent">Most Recent</option>
            <option value="oldest">Oldest</option>
          </select>
          <input
            type="text"
            placeholder="Filter by country"
            value={filterCountry}
            onChange={handleCountryFilterChange}
            className="p-2 border rounded flex-grow"
          />
          <select
            value={filterDay}
            onChange={handleDayFilterChange}
            className="p-2 border rounded"
          >
            <option value="">All Days</option>
            <option value="Monday">Monday</option>
            <option value="Tuesday">Tuesday</option>
            <option value="Wednesday">Wednesday</option>
            <option value="Thursday">Thursday</option>
            <option value="Friday">Friday</option>
            <option value="Saturday">Saturday</option>
            <option value="Sunday">Sunday</option>
          </select>
          <input
            type="time"
            value={filterTime}
            onChange={handleTimeFilterChange}
            className="p-2 border rounded"
          />
        </div>
      </div>

      {filteredAndSortedProviders.length === 0 ? (
        <p>No providers found.</p>
      ) : (
        <div className="overflow-x-auto">
          <table className="min-w-full divide-y divide-gray-200">
            <thead className="bg-gray-50">
              <tr>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Name</th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Specialty</th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Contact</th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Country</th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Actions</th>
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200">
              {filteredAndSortedProviders.map((provider) => (
                <tr key={provider.id}>
                  <td className="px-6 py-4 whitespace-nowrap text-left">
                    <span className="text-sm text-gray-900">{provider.name}</span>
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-left">
                    <span className="text-sm text-gray-900">{provider.specialty}</span>
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-left">
                    <span className="text-sm text-gray-900">{provider.contact}</span>
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-left">
                    <span className="text-sm text-gray-900">{provider.country}</span>
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-left text-sm font-medium">
                    <Link to={`/providers/${generateSlug(provider.name)}`} className="text-blue-600 hover:text-blue-900 mr-2">
                      View
                    </Link>
                    <Link to={`/edit-provider/${provider.id}`} className="text-indigo-600 hover:text-indigo-900 mr-2">
                      Edit
                    </Link>
                    <button onClick={() => handleDelete(provider.id)} className="text-red-600 hover:text-red-900">
                      Delete
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </Layout>
  );
};

export default Providers;