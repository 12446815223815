import React, { useState, useEffect } from 'react';
import { collection, getDocs, doc, deleteDoc } from 'firebase/firestore';
import { db } from '../firebase';
import Layout from './Layout';
import { useNavigate, Link } from 'react-router-dom';

const Labs = () => {
  const [labs, setLabs] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [sortBy, setSortBy] = useState('nameAZ');
  const navigate = useNavigate();

  useEffect(() => {
    fetchLabs();
  }, []);

  const fetchLabs = async () => {
    try {
      const labsCollection = collection(db, 'labs');
      const labSnapshot = await getDocs(labsCollection);
      const labList = labSnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data(),
        createdAt: doc.data().createdAt ? doc.data().createdAt.toDate() : new Date(0)
      }));
      setLabs(labList);
      setLoading(false);
    } catch (err) {
      setError('Failed to fetch laboratories: ' + err.message);
      setLoading(false);
    }
  };

  const handleDelete = async (id) => {
    if (window.confirm('Are you sure you want to delete this laboratory?')) {
      try {
        await deleteDoc(doc(db, 'labs', id));
        fetchLabs();
      } catch (err) {
        setError('Failed to delete laboratory: ' + err.message);
      }
    }
  };

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleSortChange = (e) => {
    setSortBy(e.target.value);
  };

  const filteredAndSortedLabs = labs
    .filter(lab => 
      lab.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
      lab.location.toLowerCase().includes(searchTerm.toLowerCase()) ||
      lab.contact.toLowerCase().includes(searchTerm.toLowerCase())
    )
    .sort((a, b) => {
      switch (sortBy) {
        case 'nameAZ':
          return a.name.localeCompare(b.name);
        case 'nameZA':
          return b.name.localeCompare(a.name);
        case 'locationAZ':
          return a.location.localeCompare(b.location);
        case 'locationZA':
          return b.location.localeCompare(a.location);
        case 'mostRecent':
          return b.createdAt - a.createdAt;
        case 'oldest':
          return a.createdAt - b.createdAt;
        default:
          return 0;
      }
    });

  if (loading) {
    return <Layout title="Laboratories">Loading...</Layout>;
  }

  if (error) {
    return <Layout title="Laboratories">Error: {error}</Layout>;
  }

  return (
    <Layout title="Laboratories">
      <div className="mb-4 flex justify-between items-center">
        <h1 className="text-2xl font-bold text-left">Laboratories</h1>
        <button
          onClick={() => navigate('/add-lab')}
          className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
        >
          Add Laboratory
        </button>
      </div>

      <div className="mb-4 flex justify-between items-center">
        <input
          type="text"
          placeholder="Search laboratories..."
          value={searchTerm}
          onChange={handleSearchChange}
          className="p-2 border rounded w-1/2 text-left"
        />
        <select
          value={sortBy}
          onChange={handleSortChange}
          className="p-2 border rounded"
        >
          <option value="nameAZ">Name (A-Z)</option>
          <option value="nameZA">Name (Z-A)</option>
          <option value="locationAZ">Location (A-Z)</option>
          <option value="locationZA">Location (Z-A)</option>
          <option value="mostRecent">Most Recent</option>
          <option value="oldest">Oldest</option>
        </select>
      </div>

      {filteredAndSortedLabs.length === 0 ? (
        <p className="text-left">No laboratories found.</p>
      ) : (
        <div className="overflow-x-auto">
          <table className="min-w-full divide-y divide-gray-200">
            <thead className="bg-gray-50">
              <tr>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Name</th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Location</th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Contact</th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Actions</th>
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200">
              {filteredAndSortedLabs.map((lab) => (
                <tr key={lab.id}>
                  <td className="px-6 py-4 whitespace-nowrap text-left">
                    <span className="text-sm text-gray-900">{lab.name}</span>
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-left">
                    <span className="text-sm text-gray-900">{lab.location}</span>
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-left">
                    <span className="text-sm text-gray-900">{lab.contact}</span>
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-left text-sm font-medium">
                    <Link to={`/labs/${lab.slug || lab.id}`} className="text-blue-600 hover:text-blue-900 mr-2">
                      View
                    </Link>
                    <Link to={`/edit-lab/${lab.slug || lab.id}`} className="text-indigo-600 hover:text-indigo-900 mr-2">
                      Edit
                    </Link>
                    <button onClick={() => handleDelete(lab.id)} className="text-red-600 hover:text-red-900">
                      Delete
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </Layout>
  );
};

export default Labs;