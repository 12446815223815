import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { collection, query, where, getDocs, doc, getDoc } from 'firebase/firestore';
import { db } from '../firebase';
import Layout from './Layout';

const HospitalDetails = () => {
  const [hospital, setHospital] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { slug } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchHospital = async () => {
      try {
        let hospitalData = null;

        // First, try to fetch by slug
        const q = query(collection(db, 'hospitals'), where('slug', '==', slug));
        let querySnapshot = await getDocs(q);

        if (!querySnapshot.empty) {
          hospitalData = { id: querySnapshot.docs[0].id, ...querySnapshot.docs[0].data() };
        } else {
          // If not found by slug, try to fetch by ID
          const docRef = doc(db, 'hospitals', slug);
          const docSnap = await getDoc(docRef);

          if (docSnap.exists()) {
            hospitalData = { id: docSnap.id, ...docSnap.data() };
          }
        }

        if (hospitalData) {
          setHospital(hospitalData);
        } else {
          setError('Hospital not found');
        }
      } catch (err) {
        setError('Error fetching hospital details: ' + err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchHospital();
  }, [slug]);

  if (loading) return <Layout title="Hospital Details">Loading...</Layout>;
  if (error) return <Layout title="Hospital Details">Error: {error}</Layout>;
  if (!hospital) return <Layout title="Hospital Details">Hospital not found</Layout>;

  const daysOrder = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];

  return (
    <Layout title={`Hospital: ${hospital.name}`}>
      <div className="bg-white shadow overflow-hidden sm:rounded-lg">
        <div className="px-4 py-5 sm:px-6">
          <h3 className="text-lg leading-6 font-medium text-gray-900 text-left">Hospital Details</h3>
          <p className="mt-1 max-w-2xl text-sm text-gray-500 text-left">Detailed information about the hospital.</p>
        </div>
        <div className="border-t border-gray-200">
          <dl>
            <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500 text-left">Name</dt>
              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2 text-left">{hospital.name}</dd>
            </div>
            <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500 text-left">Location</dt>
              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2 text-left">{hospital.location}</dd>
            </div>
            <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500 text-left">Contact</dt>
              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2 text-left">{hospital.contact}</dd>
            </div>
            <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500 text-left">Email</dt>
              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2 text-left">{hospital.email}</dd>
            </div>
            <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500 text-left">Website</dt>
              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2 text-left">
                <a href={hospital.website} target="_blank" rel="noopener noreferrer" className="text-blue-600 hover:text-blue-800">
                  {hospital.website}
                </a>
              </dd>
            </div>
            <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500 text-left">Departments</dt>
              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2 text-left">
                {hospital.departments && hospital.departments.join(', ')}
              </dd>
            </div>
            <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500 text-left">Operating Hours</dt>
              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2 text-left">
                <ul className="list-disc list-inside">
                  {hospital.operatingHours && daysOrder.map(day => (
                    <li key={day}>{`${day}: ${hospital.operatingHours[day] || 'Not specified'}`}</li>
                  ))}
                </ul>
              </dd>
            </div>
            <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500 text-left">Insurance Accepted</dt>
              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2 text-left">
                {hospital.insuranceAccepted && hospital.insuranceAccepted.join(', ')}
              </dd>
            </div>
            <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500 text-left">Bed Capacity</dt>
              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2 text-left">{hospital.bedCapacity}</dd>
            </div>
          </dl>
        </div>
      </div>
      <div className="mt-4">
        <button
          onClick={() => navigate('/hospitals')}
          className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded text-left"
        >
          Back to Hospitals
        </button>
      </div>
    </Layout>
  );
};

export default HospitalDetails;