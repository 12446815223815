import React, { useState, useEffect, useCallback } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { doc, getDoc, updateDoc, collection, getDocs } from 'firebase/firestore';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { db, storage } from '../firebase';
import Layout from './Layout';

const EditDocument = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [document, setDocument] = useState({
    name: '',
    type: '',
    description: '',
    associatedWith: '',
    associatedId: '',
    fileUrl: '',
    fileName: '',
  });
  const [file, setFile] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [entities, setEntities] = useState([]);

  const fetchDocument = useCallback(async () => {
    try {
      const docRef = doc(db, 'documents', id);
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        setDocument(docSnap.data());
      } else {
        setError('Document not found');
      }
    } catch (err) {
      setError('Error fetching document: ' + err.message);
    } finally {
      setLoading(false);
    }
  }, [id]);

  const fetchEntities = useCallback(async () => {
    if (!document.associatedWith) return;
    
    try {
      const querySnapshot = await getDocs(collection(db, document.associatedWith));
      const entityList = querySnapshot.docs.map(doc => ({
        id: doc.id,
        name: doc.data().name || doc.data().demographics?.name || 'Unknown'
      }));
      setEntities(entityList);
    } catch (err) {
      console.error("Error fetching entities:", err);
      setError('Failed to fetch entities: ' + err.message);
    }
  }, [document.associatedWith]);

  useEffect(() => {
    fetchDocument();
  }, [fetchDocument]);

  useEffect(() => {
    fetchEntities();
  }, [fetchEntities]);

  const handleChange = useCallback((e) => {
    const { name, value } = e.target;
    setDocument(prev => ({ ...prev, [name]: value }));
  }, []);

  const handleFileChange = useCallback((e) => {
    const selectedFile = e.target.files[0];
    const maxSize = 20 * 1024 * 1024; // 20MB limit
    if (selectedFile && selectedFile.size <= maxSize) {
      setFile(selectedFile);
      setError(null);
    } else {
      setFile(null);
      setError('Please select a file smaller than 20MB.');
    }
  }, []);

  const handleSubmit = useCallback(async (e) => {
    e.preventDefault();
    setLoading(true);
    setError(null);

    try {
      let updatedDocument = { ...document };

      if (file) {
        const storageRef = ref(storage, `documents/${Date.now()}_${file.name}`);
        await uploadBytes(storageRef, file);
        const downloadURL = await getDownloadURL(storageRef);
        updatedDocument.fileUrl = downloadURL;
        updatedDocument.fileName = file.name;
        updatedDocument.fileType = file.type;
        updatedDocument.fileSize = file.size;
      }

      const docRef = doc(db, 'documents', id);
      await updateDoc(docRef, updatedDocument);
      navigate('/documents');
    } catch (err) {
      setError('Error updating document: ' + err.message);
    } finally {
      setLoading(false);
    }
  }, [document, file, id, navigate]);

  if (loading) return <Layout title="Edit Document">Loading...</Layout>;
  if (error) return <Layout title="Edit Document">Error: {error}</Layout>;

  return (
    <Layout title="Edit Document">
      <form onSubmit={handleSubmit} className="space-y-4 text-left">
        <div>
          <label htmlFor="name" className="block text-sm font-medium text-gray-700">Document Name</label>
          <input
            type="text"
            name="name"
            id="name"
            value={document.name}
            onChange={handleChange}
            className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
          />
        </div>

        <div>
          <label htmlFor="type" className="block text-sm font-medium text-gray-700">Document Type</label>
          <select
            name="type"
            id="type"
            value={document.type}
            onChange={handleChange}
            className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
          >
            <option value="">Select a type</option>
            <option value="Medical Record">Medical Record</option>
            <option value="Lab Result">Lab Result</option>
            <option value="Prescription">Prescription</option>
            <option value="Insurance Claim">Insurance Claim</option>
            <option value="Consent Form">Consent Form</option>
          </select>
        </div>

        <div>
          <label htmlFor="description" className="block text-sm font-medium text-gray-700">Description</label>
          <textarea
            name="description"
            id="description"
            value={document.description}
            onChange={handleChange}
            rows="3"
            className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
          ></textarea>
        </div>

        <div>
          <label htmlFor="associatedWith" className="block text-sm font-medium text-gray-700">Associated With</label>
          <select
            name="associatedWith"
            id="associatedWith"
            value={document.associatedWith}
            onChange={handleChange}
            className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
          >
            <option value="">Select an entity</option>
            <option value="patients">Patient</option>
            <option value="providers">Provider</option>
            <option value="clinics">Clinic</option>
            <option value="hospitals">Hospital</option>
          </select>
        </div>

        {document.associatedWith && (
          <div>
            <label htmlFor="associatedId" className="block text-sm font-medium text-gray-700">Associated {document.associatedWith.slice(0, -1)}</label>
            <select
              name="associatedId"
              id="associatedId"
              value={document.associatedId}
              onChange={handleChange}
              className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
            >
              <option value="">Select {document.associatedWith.slice(0, -1)}</option>
              {entities.map(entity => (
                <option key={entity.id} value={entity.id}>{entity.name}</option>
              ))}
            </select>
          </div>
        )}

        <div>
          <label htmlFor="file" className="block text-sm font-medium text-gray-700">File (Max 20MB)</label>
          <input
            type="file"
            id="file"
            onChange={handleFileChange}
            className="mt-1 block w-full"
          />
          {!file && document.fileUrl && (
            <p className="mt-2 text-sm text-gray-500">
              Current file: <a href={document.fileUrl} target="_blank" rel="noopener noreferrer" className="text-indigo-600 hover:text-indigo-800">{document.fileName || 'View'}</a>
            </p>
          )}
        </div>

        <div className="flex justify-start space-x-4">
          <button
            type="submit"
            disabled={loading}
            className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            {loading ? 'Updating...' : 'Save Changes'}
          </button>
          <button
            type="button"
            onClick={() => navigate('/documents')}
            className="inline-flex justify-center py-2 px-4 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            Cancel
          </button>
        </div>
      </form>
    </Layout>
  );
};

export default EditDocument;