import React, { useState } from 'react';
import { setDoc, doc, serverTimestamp } from 'firebase/firestore';
import { db } from '../firebase';
import { useNavigate } from 'react-router-dom';
import Layout from './Layout';

const AddInsurance = () => {
    const navigate = useNavigate();
    const [newInsurance, setNewInsurance] = useState({
      name: '',
      type: '',
      contact: '',
      email: '',
      website: '',
      coverageAreas: [],
      planTypes: [],
      enrollmentProcess: '',
      customerSupport: '',
    });
    const [errorMessage, setErrorMessage] = useState('');
  
    const generateSlug = (name) => {
      return name.toLowerCase().replace(/\s+/g, '-').replace(/[^a-z0-9-]+/g, '');
    };
  
    const handleAddInsurance = async (e) => {
      e.preventDefault();
      try {
        const slug = generateSlug(newInsurance.name);
        const insuranceData = {
          ...newInsurance,
          slug,
          createdAt: serverTimestamp(),
          updatedAt: serverTimestamp()
        };
  
        await setDoc(doc(db, 'insurance', slug), insuranceData);
        navigate('/insurance');
      } catch (err) {
        console.error("Error adding insurance provider:", err);
        setErrorMessage('Failed to add new insurance provider: ' + err.message);
      }
    };
  
    const handleChange = (e) => {
      const { name, value } = e.target;
      if (name === 'coverageAreas' || name === 'planTypes') {
        setNewInsurance(prev => ({
          ...prev,
          [name]: value.split(',').map(item => item.trim())
        }));
      } else {
        setNewInsurance(prev => ({ ...prev, [name]: value }));
      }
    };

  return (
    <Layout title="Add Insurance Provider">
      <div className="max-w-2xl mx-auto">
        <h2 className="text-2xl font-bold mb-4 text-left">Add New Insurance Provider</h2>
        {errorMessage && <p className="text-red-500 mb-4 text-left">{errorMessage}</p>}
        <form onSubmit={handleAddInsurance} className="space-y-4">
          <input
            name="name"
            value={newInsurance.name}
            onChange={handleChange}
            placeholder="Insurance Provider Name"
            required
            className="w-full p-2 border rounded text-left"
          />
          <input
            name="type"
            value={newInsurance.type}
            onChange={handleChange}
            placeholder="Insurance Type (e.g., Health, Auto, Life)"
            required
            className="w-full p-2 border rounded text-left"
          />
          <input
            name="contact"
            value={newInsurance.contact}
            onChange={handleChange}
            placeholder="Contact Number"
            required
            className="w-full p-2 border rounded text-left"
          />
          <input
            name="email"
            type="email"
            value={newInsurance.email}
            onChange={handleChange}
            placeholder="Email"
            className="w-full p-2 border rounded text-left"
          />
          <input
            name="website"
            type="url"
            value={newInsurance.website}
            onChange={handleChange}
            placeholder="Website"
            className="w-full p-2 border rounded text-left"
          />
          <input
            name="coverageAreas"
            value={newInsurance.coverageAreas.join(', ')}
            onChange={handleChange}
            placeholder="Coverage Areas (comma-separated)"
            className="w-full p-2 border rounded text-left"
          />
          <input
            name="planTypes"
            value={newInsurance.planTypes.join(', ')}
            onChange={handleChange}
            placeholder="Plan Types (comma-separated)"
            className="w-full p-2 border rounded text-left"
          />
          <textarea
            name="enrollmentProcess"
            value={newInsurance.enrollmentProcess}
            onChange={handleChange}
            placeholder="Enrollment Process"
            className="w-full p-2 border rounded text-left"
            rows="4"
          />
          <textarea
            name="customerSupport"
            value={newInsurance.customerSupport}
            onChange={handleChange}
            placeholder="Customer Support Information"
            className="w-full p-2 border rounded text-left"
            rows="4"
          />
          <div className="flex justify-start">
            <button
              type="submit"
              className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600"
            >
              Add Insurance Provider
            </button>
          </div>
        </form>
      </div>
    </Layout>
  );
};

export default AddInsurance;