import React, { useState, useEffect } from 'react';
import { collection, getDocs, doc, deleteDoc } from 'firebase/firestore';
import { db } from '../firebase';
import Layout from './Layout';
import { useNavigate, Link } from 'react-router-dom';

const Hospitals = () => {
  const [hospitals, setHospitals] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [sortBy, setSortBy] = useState('nameAZ');
  const navigate = useNavigate();

  useEffect(() => {
    fetchHospitals();
  }, []);

  const fetchHospitals = async () => {
    try {
      const hospitalsCollection = collection(db, 'hospitals');
      const hospitalSnapshot = await getDocs(hospitalsCollection);
      const hospitalList = hospitalSnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data(),
        createdAt: doc.data().createdAt ? doc.data().createdAt.toDate() : new Date(0)
      }));
      setHospitals(hospitalList);
      setLoading(false);
    } catch (err) {
      setError('Failed to fetch hospitals: ' + err.message);
      setLoading(false);
    }
  };

  const handleDelete = async (id) => {
    if (window.confirm('Are you sure you want to delete this hospital?')) {
      try {
        await deleteDoc(doc(db, 'hospitals', id));
        fetchHospitals();
      } catch (err) {
        setError('Failed to delete hospital: ' + err.message);
      }
    }
  };

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleSortChange = (e) => {
    setSortBy(e.target.value);
  };

  const filteredAndSortedHospitals = hospitals
    .filter(hospital => 
      hospital.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
      hospital.location.toLowerCase().includes(searchTerm.toLowerCase()) ||
      hospital.contact.toLowerCase().includes(searchTerm.toLowerCase())
    )
    .sort((a, b) => {
      switch (sortBy) {
        case 'nameAZ':
          return a.name.localeCompare(b.name);
        case 'nameZA':
          return b.name.localeCompare(a.name);
        case 'locationAZ':
          return a.location.localeCompare(b.location);
        case 'locationZA':
          return b.location.localeCompare(a.location);
        case 'mostRecent':
          return b.createdAt - a.createdAt;
        case 'oldest':
          return a.createdAt - b.createdAt;
        default:
          return 0;
      }
    });

  if (loading) {
    return <Layout title="Hospitals">Loading...</Layout>;
  }

  if (error) {
    return <Layout title="Hospitals">Error: {error}</Layout>;
  }

  return (
    <Layout title="Hospitals">
      <div className="mb-4 flex justify-between items-center">
        <h1 className="text-2xl font-bold text-left">Hospitals</h1>
        <button
          onClick={() => navigate('/add-hospital')}
          className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
        >
          Add Hospital
        </button>
      </div>

      <div className="mb-4 flex justify-between items-center">
        <input
          type="text"
          placeholder="Search hospitals..."
          value={searchTerm}
          onChange={handleSearchChange}
          className="p-2 border rounded w-1/2"
        />
        <select
          value={sortBy}
          onChange={handleSortChange}
          className="p-2 border rounded"
        >
          <option value="nameAZ">Name (A-Z)</option>
          <option value="nameZA">Name (Z-A)</option>
          <option value="locationAZ">Location (A-Z)</option>
          <option value="locationZA">Location (Z-A)</option>
          <option value="mostRecent">Most Recent</option>
          <option value="oldest">Oldest</option>
        </select>
      </div>

      {filteredAndSortedHospitals.length === 0 ? (
        <p className="text-left">No hospitals found.</p>
      ) : (
        <div className="overflow-x-auto">
          <table className="min-w-full divide-y divide-gray-200">
            <thead className="bg-gray-50">
              <tr>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Name</th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Location</th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Contact</th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Actions</th>
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200">
              {filteredAndSortedHospitals.map((hospital) => (
                <tr key={hospital.id}>
                  <td className="px-6 py-4 whitespace-nowrap text-left">
                    <span className="text-sm text-gray-900">{hospital.name}</span>
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-left">
                    <span className="text-sm text-gray-900">{hospital.location}</span>
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-left">
                    <span className="text-sm text-gray-900">{hospital.contact}</span>
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-left text-sm font-medium">
                    <Link to={`/hospitals/${hospital.slug || hospital.id}`} className="text-blue-600 hover:text-blue-900 mr-2">
                      View
                    </Link>
                    <Link to={`/edit-hospital/${hospital.slug || hospital.id}`} className="text-indigo-600 hover:text-indigo-900 mr-2">
                      Edit
                    </Link>
                    <button onClick={() => handleDelete(hospital.id)} className="text-red-600 hover:text-red-900">
                      Delete
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </Layout>
  );
};

export default Hospitals;