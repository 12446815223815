import React, { useState, useEffect } from 'react';
import { collection, getDocs, doc, deleteDoc } from 'firebase/firestore';
import { db } from '../firebase';
import Layout from './Layout';
import { useNavigate, Link } from 'react-router-dom';

const AiBot = () => {
  const [aiBots, setAiBots] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [sortBy, setSortBy] = useState('createdAtDesc');
  const navigate = useNavigate();

  useEffect(() => {
    fetchAiBots();
  }, []);

  const fetchAiBots = async () => {
    try {
      const aiBotsCollection = collection(db, 'ai');
      const aiBotSnapshot = await getDocs(aiBotsCollection);
      const aiBotList = aiBotSnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data(),
        createdAt: doc.data().createdAt ? doc.data().createdAt.toDate() : new Date(0),
        date: doc.data().date ? doc.data().date.toDate() : new Date(0)
      }));
      setAiBots(aiBotList);
      setLoading(false);
    } catch (err) {
      setError('Failed to fetch AI bot conversation: ' + err.message);
      setLoading(false);
    }
  };

  const handleDelete = async (id) => {
    if (window.confirm('Are you sure you want to delete this AI bot conversation?')) {
      try {
        await deleteDoc(doc(db, 'ai', id));
        fetchAiBots();
      } catch (err) {
        setError('Failed to delete AI bot conversation: ' + err.message);
      }
    }
  };

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleSortChange = (e) => {
    setSortBy(e.target.value);
  };

  const filteredAndSortedAiBots = aiBots
    .filter(bot => 
      bot.doctor.toLowerCase().includes(searchTerm.toLowerCase()) ||
      bot.status.toLowerCase().includes(searchTerm.toLowerCase()) ||
      bot.type.toLowerCase().includes(searchTerm.toLowerCase())
    )
    .sort((a, b) => {
      switch (sortBy) {
        case 'createdAtDesc':
          return b.createdAt - a.createdAt;
        case 'createdAtAsc':
          return a.createdAt - b.createdAt;
        case 'dateDesc':
          return b.date - a.date;
        case 'dateAsc':
          return a.date - b.date;
        case 'doctorAZ':
          return a.doctor.localeCompare(b.doctor);
        case 'doctorZA':
          return b.doctor.localeCompare(a.doctor);
        default:
          return 0;
      }
    });

  if (loading) {
    return <Layout title="AI Bot Conversation">Loading...</Layout>;
  }

  if (error) {
    return <Layout title="AI Bot">Error: {error}</Layout>;
  }

  return (
    <Layout title="AI Bot Conversation">
      <div className="mb-4 flex justify-between items-center">
        <h1 className="text-2xl font-bold">AI Bot Interaction</h1>
        
      </div>

      <div className="mb-4 flex justify-between items-center">
        <input
          type="text"
          placeholder="Search AI bot interaction..."
          value={searchTerm}
          onChange={handleSearchChange}
          className="p-2 border rounded w-1/2"
        />
        <select
          value={sortBy}
          onChange={handleSortChange}
          className="p-2 border rounded"
        >
          <option value="createdAtDesc">Created At (Newest First)</option>
          <option value="createdAtAsc">Created At (Oldest First)</option>
          <option value="dateDesc">Date (Newest First)</option>
          <option value="dateAsc">Date (Oldest First)</option>
          <option value="doctorAZ">Doctor (A-Z)</option>
          <option value="doctorZA">Doctor (Z-A)</option>
        </select>
      </div>

      {filteredAndSortedAiBots.length === 0 ? (
        <p>No AI bot conversation found.</p>
      ) : (
        <div className="overflow-x-auto">
          <table className="min-w-full divide-y divide-gray-200">
            <thead className="bg-gray-50">
              <tr>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Created At</th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Date</th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Doctor</th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Status</th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Type</th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Actions</th>
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200">
              {filteredAndSortedAiBots.map((bot) => (
                <tr key={bot.id}>
                  <td className="px-6 py-4 whitespace-nowrap text-left">
                    <span className="text-sm text-gray-900">{bot.createdAt.toLocaleString()}</span>
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-left">
                    <span className="text-sm text-gray-900">{bot.date.toLocaleString()}</span>
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-left">
                    <span className="text-sm text-gray-900">{bot.doctor}</span>
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-left">
                    <span className="text-sm text-gray-900">{bot.status}</span>
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-left">
                    <span className="text-sm text-gray-900">{bot.type}</span>
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-left text-sm font-medium">
                    <Link to={`/ai-bots/${bot.id}`} className="text-blue-600 hover:text-blue-900 mr-2">
                      View
                    </Link>
                    <Link to={`/edit-ai-bot/${bot.id}`} className="text-indigo-600 hover:text-indigo-900 mr-2">
                      Edit
                    </Link>
                    <button onClick={() => handleDelete(bot.id)} className="text-red-600 hover:text-red-900">
                      Delete
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </Layout>
  );
};

export default AiBot;