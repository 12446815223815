import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { doc, getDoc, updateDoc, serverTimestamp } from 'firebase/firestore';
import { db } from '../firebase';
import Layout from './Layout';

const EditPatientEMR = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState(0);
  const [patient, setPatient] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchPatient = async () => {
      try {
        const docRef = doc(db, 'patients', id);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
          setPatient({ id: docSnap.id, ...docSnap.data() });
        } else {
          setError('Patient not found');
        }
      } catch (err) {
        setError('Error fetching patient details: ' + err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchPatient();
  }, [id]);

  const handleChange = (section, field, value) => {
    setPatient(prev => ({
      ...prev,
      [section]: { ...prev[section], [field]: value }
    }));
  };

  const handleArrayChange = (path, index, value) => {
    setPatient(prev => {
      const newPatient = { ...prev };
      const keys = path.split('.');
      let current = newPatient;
      for (let i = 0; i < keys.length - 1; i++) {
        if (!current[keys[i]]) current[keys[i]] = {};
        current = current[keys[i]];
      }
      const lastKey = keys[keys.length - 1];
      if (!Array.isArray(current[lastKey])) current[lastKey] = [];
      current[lastKey][index] = value;
      return newPatient;
    });
  };
  
  const handleArrayAdd = (path, value) => {
    setPatient(prev => {
      const newPatient = { ...prev };
      const keys = path.split('.');
      let current = newPatient;
      for (let i = 0; i < keys.length - 1; i++) {
        if (!current[keys[i]]) current[keys[i]] = {};
        current = current[keys[i]];
      }
      const lastKey = keys[keys.length - 1];
      if (!Array.isArray(current[lastKey])) current[lastKey] = [];
      current[lastKey].push(value);
      return newPatient;
    });
  };
  
  const handleArrayRemove = (path, index) => {
    setPatient(prev => {
      const newPatient = { ...prev };
      const keys = path.split('.');
      let current = newPatient;
      for (let i = 0; i < keys.length - 1; i++) {
        if (!current[keys[i]]) return prev; // If the path doesn't exist, return the previous state
        current = current[keys[i]];
      }
      const lastKey = keys[keys.length - 1];
      if (Array.isArray(current[lastKey])) {
        current[lastKey] = current[lastKey].filter((_, i) => i !== index);
      }
      return newPatient;
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const patientRef = doc(db, 'patients', id);
      await updateDoc(patientRef, {
        ...patient,
        updatedAt: serverTimestamp()
      });
      navigate(`/patients/${id}`);
    } catch (err) {
      setError('Failed to update patient: ' + err.message);
    }
  };

  if (loading) return <Layout title="Edit Patient EMR">Loading...</Layout>;
  if (error) return <Layout title="Edit Patient EMR">Error: {error}</Layout>;
  if (!patient) return <Layout title="Edit Patient EMR">Patient not found</Layout>;

  const tabSections = [
    { title: 'Demographics', content: <DemographicsSection patient={patient} handleChange={handleChange} /> },
    { title: 'Medical History', content: <MedicalHistorySection patient={patient} handleArrayChange={handleArrayChange} handleArrayAdd={handleArrayAdd} handleArrayRemove={handleArrayRemove} /> },
    { title: 'Medications', content: <MedicationsSection patient={patient} handleArrayChange={handleArrayChange} handleArrayAdd={handleArrayAdd} handleArrayRemove={handleArrayRemove} /> },
    { title: 'Lab Results', content: <LabResultsSection patient={patient} handleArrayChange={handleArrayChange} handleArrayAdd={handleArrayAdd} handleArrayRemove={handleArrayRemove} /> },
    { title: 'Imaging', content: <ImagingSection patient={patient} handleArrayChange={handleArrayChange} handleArrayAdd={handleArrayAdd} handleArrayRemove={handleArrayRemove} /> },
    { title: 'Notes', content: <NotesSection patient={patient} handleArrayChange={handleArrayChange} handleArrayAdd={handleArrayAdd} handleArrayRemove={handleArrayRemove} /> },
    { title: 'Support Plan Goals', content: <SupportPlanGoalsSection patient={patient} handleArrayChange={handleArrayChange} handleArrayAdd={handleArrayAdd} handleArrayRemove={handleArrayRemove} /> },
    { title: 'Behavior', content: <BehaviorSection patient={patient} handleArrayChange={handleArrayChange} handleArrayAdd={handleArrayAdd} handleArrayRemove={handleArrayRemove} /> },
    { title: 'Medical Adherence', content: <MedicalAdherenceSection patient={patient} handleChange={handleChange} /> },
    { title: 'Transfers', content: <TransfersSection patient={patient} handleArrayChange={handleArrayChange} handleArrayAdd={handleArrayAdd} handleArrayRemove={handleArrayRemove} /> },
    { title: 'Assigned Staff', content: <AssignedStaffSection patient={patient} handleArrayChange={handleArrayChange} handleArrayAdd={handleArrayAdd} handleArrayRemove={handleArrayRemove} /> },
    { title: 'Community Activity Logs', content: <CommunityActivityLogsSection patient={patient} handleArrayChange={handleArrayChange} handleArrayAdd={handleArrayAdd} handleArrayRemove={handleArrayRemove} /> },
    { title: 'Inventory', content: <InventorySection patient={patient} handleChange={handleChange} handleArrayChange={handleArrayChange} handleArrayAdd={handleArrayAdd} handleArrayRemove={handleArrayRemove} /> },
    { title: 'Significant Events', content: <SignificantEventsSection patient={patient} handleArrayChange={handleArrayChange} handleArrayAdd={handleArrayAdd} handleArrayRemove={handleArrayRemove} /> },
    { title: 'Medical Contacts', content: <MedicalContactsSection patient={patient} handleArrayChange={handleArrayChange} handleArrayAdd={handleArrayAdd} handleArrayRemove={handleArrayRemove} /> },
    { title: 'Facesheet', content: <FacesheetSection patient={patient} handleChange={handleChange} handleArrayChange={handleArrayChange} handleArrayAdd={handleArrayAdd} handleArrayRemove={handleArrayRemove} /> },
    { title: 'Income and Expenses', content: <IncomeAndExpensesSection patient={patient} handleChange={handleChange} handleArrayChange={handleArrayChange} handleArrayAdd={handleArrayAdd} handleArrayRemove={handleArrayRemove} /> },
    { title: 'Logs', content: <LogsSection patient={patient} handleArrayChange={handleArrayChange} handleArrayAdd={handleArrayAdd} handleArrayRemove={handleArrayRemove} /> },
  ];

  return (
    <Layout title="Edit Patient EMR">
      <div className="container mx-auto p-4 text-left">
        <h1 className="text-2xl font-bold mb-4">Edit Patient EMR - {patient.demographics?.name}</h1>
        <form onSubmit={handleSubmit}>
          <div className="mb-4">
            {tabSections.map((section, index) => (
              <button
                key={index}
                type="button"
                onClick={() => setActiveTab(index)}
                className={`mr-2 px-4 py-2 rounded ${
                  activeTab === index
                    ? 'bg-blue-500 text-white'
                    : 'bg-gray-200 text-gray-700'
                }`}
              >
                {section.title}
              </button>
            ))}
          </div>
          <div className="bg-white p-4 rounded-lg shadow">
            {tabSections[activeTab].content}
          </div>
          <div className="mt-4 flex justify-end space-x-2">
            <button
              type="submit"
              className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
            >
              Save Changes
            </button>
            <button
              type="button"
              onClick={() => navigate(`/patients/${id}`)}
              className="bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded"
            >
              Cancel
            </button>
          </div>
        </form>
      </div>
    </Layout>
  );
};

const DemographicsSection = ({ patient, handleChange }) => (
  <div className="space-y-4">
    <div>
      <label htmlFor="name" className="block text-sm font-medium text-gray-700">Name</label>
      <input
        type="text"
        id="name"
        value={patient.demographics?.name || ''}
        onChange={(e) => handleChange('demographics', 'name', e.target.value)}
        className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
      />
    </div>
    <div>
      <label htmlFor="dateOfBirth" className="block text-sm font-medium text-gray-700">Date of Birth</label>
      <input
        type="date"
        id="dateOfBirth"
        value={patient.demographics?.dateOfBirth || ''}
        onChange={(e) => handleChange('demographics', 'dateOfBirth', e.target.value)}
        className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
      />
    </div>
    <div>
      <label htmlFor="gender" className="block text-sm font-medium text-gray-700">Gender</label>
      <select
        id="gender"
        value={patient.demographics?.gender || ''}
        onChange={(e) => handleChange('demographics', 'gender', e.target.value)}
        className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
      >
        <option value="">Select Gender</option>
        <option value="Male">Male</option>
        <option value="Female">Female</option>
        <option value="Other">Other</option>
      </select>
    </div>
    <div>
      <label htmlFor="contact" className="block text-sm font-medium text-gray-700">Contact</label>
      <input
        type="tel"
        id="contact"
        value={patient.demographics?.contact || ''}
        onChange={(e) => handleChange('demographics', 'contact', e.target.value)}
        className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
      />
    </div>
  </div>
);

const MedicalHistorySection = ({ patient, handleArrayChange, handleArrayAdd, handleArrayRemove }) => (
  <div>
    <h2 className="text-xl font-semibold mb-2">Medical History</h2>
    {patient.medicalHistory?.map((item, index) => (
      <div key={index} className="flex items-center mb-2">
        <input
          type="text"
          value={item}
          onChange={(e) => handleArrayChange('medicalHistory', index, e.target.value)}
          className="flex-grow mr-2 border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
        />
        <button
          type="button"
          onClick={() => handleArrayRemove('medicalHistory', index)}
          className="bg-red-500 text-white px-2 py-1 rounded hover:bg-red-600"
        >
          Remove
        </button>
      </div>
    ))}
    <button
      type="button"
      onClick={() => handleArrayAdd('medicalHistory', '')}
      className="mt-2 bg-green-500 text-white px-4 py-2 rounded hover:bg-green-600"
    >
      Add Medical History Item
    </button>
  </div>
);

const MedicationsSection = ({ patient, handleArrayChange, handleArrayAdd, handleArrayRemove }) => (
  <div>
    <h2 className="text-xl font-semibold mb-2">Current Medications</h2>
    {patient.medications?.map((med, index) => (
      <div key={index} className="flex items-center mb-2">
        <input
          type="text"
          value={med}
          onChange={(e) => handleArrayChange('medications', index, e.target.value)}
          className="flex-grow mr-2 border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
        />
        <button
          type="button"
          onClick={() => handleArrayRemove('medications', index)}
          className="bg-red-500 text-white px-2 py-1 rounded hover:bg-red-600"
        >
          Remove
        </button>
      </div>
    ))}
    <button
      type="button"
      onClick={() => handleArrayAdd('medications', '')}
      className="mt-2 bg-green-500 text-white px-4 py-2 rounded hover:bg-green-600"
    >
      Add Medication
    </button>
  </div>
);

const LabResultsSection = ({ patient, handleArrayChange, handleArrayAdd, handleArrayRemove }) => (
  <div>
    <h2 className="text-xl font-semibold mb-2">Lab Results</h2>
    {patient.labResults?.map((result, index) => (
      <div key={index} className="mb-4 p-4 border border-gray-200 rounded">
        <div className="flex items-center mb-2">
          <input
            type="text"
            value={result.test}
            onChange={(e) => handleArrayChange('labResults', index, {...result, test: e.target.value})}
            placeholder="Test"
            className="flex-grow mr-2 border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
          />
          <input
            type="text"
            value={result.result}
            onChange={(e) => handleArrayChange('labResults', index, {...result, result: e.target.value})}
            placeholder="Result"
            className="flex-grow mr-2 border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
          />
          <input
            type="date"
            value={result.date}
            onChange={(e) => handleArrayChange('labResults', index, {...result, date: e.target.value})}
            className="flex-grow mr-2 border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
          />
          <button
            type="button"
            onClick={() => handleArrayRemove('labResults', index)}
            className="bg-red-500 text-white px-2 py-1 rounded hover:bg-red-600"
          >
            Remove
          </button>
        </div>
      </div>
    ))}
    <button
      type="button"
      onClick={() => handleArrayAdd('labResults', { test: '', result: '', date: '' })}
      className="mt-2 bg-green-500 text-white px-4 py-2 rounded hover:bg-green-600"
    >
      Add Lab Result
    </button>
  </div>
);

const ImagingSection = ({ patient, handleArrayChange, handleArrayAdd, handleArrayRemove }) => (
  <div>
    <h2 className="text-xl font-semibold mb-2">Imaging Studies</h2>
    {patient.imagingStudies?.map((study, index) => (
      <div key={index} className="flex items-center mb-2">
        <input
          type="text"
          value={study}
          onChange={(e) => handleArrayChange('imagingStudies', index, e.target.value)}
          className="flex-grow mr-2 border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
        />
        <button
          type="button"
          onClick={() => handleArrayRemove('imagingStudies', index)}
          className="bg-red-500 text-white px-2 py-1 rounded hover:bg-red-600"
        >
          Remove
        </button>
      </div>
    ))}
    <button
      type="button"
      onClick={() => handleArrayAdd('imagingStudies', '')}
      className="mt-2 bg-green-500 text-white px-4 py-2 rounded hover:bg-green-600"
    >
      Add Imaging Study
    </button>
  </div>
);

const NotesSection = ({ patient, handleArrayChange, handleArrayAdd, handleArrayRemove }) => (
  <div>
    <h2 className="text-xl font-semibold mb-2">Clinical Notes</h2>
    {patient.clinicalNotes?.map((note, index) => (
      <div key={index} className="mb-4 p-4 border border-gray-200 rounded">
        <div className="mb-2">
          <label className="block text-sm font-medium text-gray-700">Date</label>
          <input
            type="date"
            value={note.date}
            onChange={(e) => handleArrayChange('clinicalNotes', index, {...note, date: e.target.value})}
            className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
          />
        </div>
        <div className="mb-2">
          <label className="block text-sm font-medium text-gray-700">Doctor</label>
          <input
            type="text"
            value={note.doctor}
            onChange={(e) => handleArrayChange('clinicalNotes', index, {...note, doctor: e.target.value})}
            className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
          />
        </div>
        <div className="mb-2">
          <label className="block text-sm font-medium text-gray-700">Note Content</label>
          <textarea
            value={note.content}
            onChange={(e) => handleArrayChange('clinicalNotes', index, {...note, content: e.target.value})}
            rows="4"
            className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
          ></textarea>
        </div>
        <button
          type="button"
          onClick={() => handleArrayRemove('clinicalNotes', index)}
          className="mt-2 bg-red-500 text-white px-4 py-2 rounded hover:bg-red-600"
        >
          Remove Note
        </button>
      </div>
    ))}
    <button
      type="button"
      onClick={() => handleArrayAdd('clinicalNotes', { date: '', doctor: '', content: '' })}
      className="mt-2 bg-green-500 text-white px-4 py-2 rounded hover:bg-green-600"
    >
      Add Clinical Note
    </button>
  </div>
);

const SupportPlanGoalsSection = ({ patient, handleArrayChange, handleArrayAdd, handleArrayRemove }) => (
  <div>
    <h2 className="text-xl font-semibold mb-2">Support Plan Goals</h2>
    {patient.supportPlanGoals?.map((goal, index) => (
      <div key={index} className="mb-4 p-4 bg-gray-100 rounded">
        <input
          type="text"
          value={goal.description}
          onChange={(e) => handleArrayChange('supportPlanGoals', index, {...goal, description: e.target.value})}
          placeholder="Goal Description"
          className="w-full mb-2 p-2 border rounded"
        />
        <input
          type="date"
          value={goal.targetDate}
          onChange={(e) => handleArrayChange('supportPlanGoals', index, {...goal, targetDate: e.target.value})}
          className="w-full mb-2 p-2 border rounded"
        />
        <input
          type="text"
          value={goal.status}
          onChange={(e) => handleArrayChange('supportPlanGoals', index, {...goal, status: e.target.value})}
          placeholder="Status"
          className="w-full mb-2 p-2 border rounded"
        />
        <textarea
          value={goal.notes}
          onChange={(e) => handleArrayChange('supportPlanGoals', index, {...goal, notes: e.target.value})}
          placeholder="Notes"
          className="w-full mb-2 p-2 border rounded"
        ></textarea>
        <button
          type="button"
          onClick={() => handleArrayRemove('supportPlanGoals', index)}
          className="bg-red-500 text-white px-2 py-1 rounded hover:bg-red-600"
        >
          Remove
        </button>
      </div>
    ))}
    <button
      type="button"
      onClick={() => handleArrayAdd('supportPlanGoals', { description: '', targetDate: '', status: '', notes: '' })}
      className="mt-2 bg-green-500 text-white px-4 py-2 rounded hover:bg-green-600"
    >
      Add Goal
    </button>
  </div>
);

const BehaviorSection = ({ patient, handleArrayChange, handleArrayAdd, handleArrayRemove }) => (
  <div>
    <h2 className="text-xl font-semibold mb-2">Behavior Incidents</h2>
    {patient.behaviorIncidents?.map((incident, index) => (
      <div key={index} className="mb-4 p-4 bg-gray-100 rounded">
        <input
          type="date"
          value={incident.date}
          onChange={(e) => handleArrayChange('behaviorIncidents', index, {...incident, date: e.target.value})}
          className="w-full mb-2 p-2 border rounded"
        />
        <input
          type="text"
          value={incident.description}
          onChange={(e) => handleArrayChange('behaviorIncidents', index, {...incident, description: e.target.value})}
          placeholder="Description"
          className="w-full mb-2 p-2 border rounded"
        />
        <input
          type="text"
          value={incident.severity}
          onChange={(e) => handleArrayChange('behaviorIncidents', index, {...incident, severity: e.target.value})}
          placeholder="Severity"
          className="w-full mb-2 p-2 border rounded"
        />
        <input
          type="text"
          value={incident.intervention}
          onChange={(e) => handleArrayChange('behaviorIncidents', index, {...incident, intervention: e.target.value})}
          placeholder="Intervention"
          className="w-full mb-2 p-2 border rounded"
        />
        <button
          type="button"
          onClick={() => handleArrayRemove('behaviorIncidents', index)}
          className="bg-red-500 text-white px-2 py-1 rounded hover:bg-red-600"
        >
          Remove
        </button>
      </div>
    ))}
    <button
      type="button"
      onClick={() => handleArrayAdd('behaviorIncidents', { date: '', description: '', severity: '', intervention: '' })}
      className="mt-2 bg-green-500 text-white px-4 py-2 rounded hover:bg-green-600"
    >
      Add Incident
    </button>
  </div>
);

const MedicalAdherenceSection = ({ patient, handleChange }) => (
  <div>
    <h2 className="text-xl font-semibold mb-2">Medical Adherence</h2>
    <div className="space-y-4">
      <div>
        <label htmlFor="medicationCompliance" className="block text-sm font-medium text-gray-700">Medication Compliance</label>
        <input
          type="text"
          id="medicationCompliance"
          value={patient.medicalAdherence?.medicationCompliance || ''}
          onChange={(e) => handleChange('medicalAdherence', 'medicationCompliance', e.target.value)}
          className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
        />
      </div>
      <div>
        <label htmlFor="appointmentAttendance" className="block text-sm font-medium text-gray-700">Appointment Attendance</label>
        <input
          type="text"
          id="appointmentAttendance"
          value={patient.medicalAdherence?.appointmentAttendance || ''}
          onChange={(e) => handleChange('medicalAdherence', 'appointmentAttendance', e.target.value)}
          className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
        />
      </div>
      <div>
        <label htmlFor="treatmentPlanAdherence" className="block text-sm font-medium text-gray-700">Treatment Plan Adherence</label>
        <input
          type="text"
          id="treatmentPlanAdherence"
          value={patient.medicalAdherence?.treatmentPlanAdherence || ''}
          onChange={(e) => handleChange('medicalAdherence', 'treatmentPlanAdherence', e.target.value)}
          className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
        />
      </div>
      <div>
        <label htmlFor="adherenceNotes" className="block text-sm font-medium text-gray-700">Notes</label>
        <textarea
          id="adherenceNotes"
          value={patient.medicalAdherence?.notes || ''}
          onChange={(e) => handleChange('medicalAdherence', 'notes', e.target.value)}
          rows="3"
          className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
        ></textarea>
      </div>
    </div>
  </div>
);

const TransfersSection = ({ patient, handleArrayChange, handleArrayAdd, handleArrayRemove }) => (
  <div>
    <h2 className="text-xl font-semibold mb-2">Transfers</h2>
    {patient.transfers?.map((transfer, index) => (
      <div key={index} className="mb-4 p-4 bg-gray-100 rounded">
        <input
          type="date"
          value={transfer.date}
          onChange={(e) => handleArrayChange('transfers', index, {...transfer, date: e.target.value})}
          className="w-full mb-2 p-2 border rounded"
        />
        <input
          type="text"
          value={transfer.from}
          onChange={(e) => handleArrayChange('transfers', index, {...transfer, from: e.target.value})}
          placeholder="From"
          className="w-full mb-2 p-2 border rounded"
        />
        <input
          type="text"
          value={transfer.to}
          onChange={(e) => handleArrayChange('transfers', index, {...transfer, to: e.target.value})}
          placeholder="To"
          className="w-full mb-2 p-2 border rounded"
        />
        <input
          type="text"
          value={transfer.reason}
          onChange={(e) => handleArrayChange('transfers', index, {...transfer, reason: e.target.value})}
          placeholder="Reason"
          className="w-full mb-2 p-2 border rounded"
        />
        <input
          type="text"
          value={transfer.staff}
          onChange={(e) => handleArrayChange('transfers', index, {...transfer, staff: e.target.value})}
          placeholder="Accompanying Staff"
          className="w-full mb-2 p-2 border rounded"
        />
        <button
          type="button"
          onClick={() => handleArrayRemove('transfers', index)}
          className="bg-red-500 text-white px-2 py-1 rounded hover:bg-red-600"
        >
          Remove
        </button>
      </div>
    ))}
    <button
      type="button"
      onClick={() => handleArrayAdd('transfers', { date: '', from: '', to: '', reason: '', staff: '' })}
      className="mt-2 bg-green-500 text-white px-4 py-2 rounded hover:bg-green-600"
    >
      Add Transfer
    </button>
  </div>
);

const AssignedStaffSection = ({ patient, handleArrayChange, handleArrayAdd, handleArrayRemove }) => (
  <div>
    <h2 className="text-xl font-semibold mb-2">Assigned Staff</h2>
    {patient.assignedStaff?.map((staff, index) => (
      <div key={index} className="mb-4 p-4 bg-gray-100 rounded">
        <input
          type="text"
          value={staff.name}
          onChange={(e) => handleArrayChange('assignedStaff', index, {...staff, name: e.target.value})}
          placeholder="Name"
          className="w-full mb-2 p-2 border rounded"
        />
        <input
          type="text"
          value={staff.role}
          onChange={(e) => handleArrayChange('assignedStaff', index, {...staff, role: e.target.value})}
          placeholder="Role"
          className="w-full mb-2 p-2 border rounded"
        />
        <input
          type="text"
          value={staff.contact}
          onChange={(e) => handleArrayChange('assignedStaff', index, {...staff, contact: e.target.value})}
          placeholder="Contact"
          className="w-full mb-2 p-2 border rounded"
        />
        <input
          type="text"
          value={staff.schedule}
          onChange={(e) => handleArrayChange('assignedStaff', index, {...staff, schedule: e.target.value})}
          placeholder="Schedule"
          className="w-full mb-2 p-2 border rounded"
        />
        <button
          type="button"
          onClick={() => handleArrayRemove('assignedStaff', index)}
          className="bg-red-500 text-white px-2 py-1 rounded hover:bg-red-600"
        >
          Remove
        </button>
      </div>
    ))}
    <button
      type="button"
      onClick={() => handleArrayAdd('assignedStaff', { name: '', role: '', contact: '', schedule: '' })}
      className="mt-2 bg-green-500 text-white px-4 py-2 rounded hover:bg-green-600"
    >
      Add Staff
    </button>
  </div>
);

const CommunityActivityLogsSection = ({ patient, handleArrayChange, handleArrayAdd, handleArrayRemove }) => (
  <div>
    <h2 className="text-xl font-semibold mb-2">Community Activity Logs</h2>
    {patient.communityActivityLogs?.map((activity, index) => (
      <div key={index} className="mb-4 p-4 bg-gray-100 rounded">
        <input
          type="date"
          value={activity.date}
          onChange={(e) => handleArrayChange('communityActivityLogs', index, {...activity, date: e.target.value})}
          className="w-full mb-2 p-2 border rounded"
        />
        <input
          type="text"
          value={activity.type}
          onChange={(e) => handleArrayChange('communityActivityLogs', index, {...activity, type: e.target.value})}
          placeholder="Activity Type"
          className="w-full mb-2 p-2 border rounded"
        />
        <input
          type="text"
          value={activity.duration}
          onChange={(e) => handleArrayChange('communityActivityLogs', index, {...activity, duration: e.target.value})}
          placeholder="Duration"
          className="w-full mb-2 p-2 border rounded"
        />
        <input
          type="text"
          value={activity.location}
          onChange={(e) => handleArrayChange('communityActivityLogs', index, {...activity, location: e.target.value})}
          placeholder="Location"
          className="w-full mb-2 p-2 border rounded"
        />
        <input
          type="text"
          value={activity.staff}
          onChange={(e) => handleArrayChange('communityActivityLogs', index, {...activity, staff: e.target.value})}
          placeholder="Staff"
          className="w-full mb-2 p-2 border rounded"
        />
        <textarea
          value={activity.notes}
          onChange={(e) => handleArrayChange('communityActivityLogs', index, {...activity, notes: e.target.value})}
          placeholder="Notes"
          className="w-full mb-2 p-2 border rounded"
        ></textarea>
        <button
          type="button"
          onClick={() => handleArrayRemove('communityActivityLogs', index)}
          className="bg-red-500 text-white px-2 py-1 rounded hover:bg-red-600"
        >
          Remove
        </button>
      </div>
    ))}
    <button
      type="button"
      onClick={() => handleArrayAdd('communityActivityLogs', { date: '', type: '', duration: '', location: '', staff: '', notes: '' })}
      className="mt-2 bg-green-500 text-white px-4 py-2 rounded hover:bg-green-600"
    >
      Add Activity
    </button>
  </div>
);

const InventorySection = ({ patient, handleChange, handleArrayChange, handleArrayAdd, handleArrayRemove }) => (
  <div>
    <h2 className="text-xl font-semibold mb-2">Inventory</h2>
    <div className="space-y-4">
      <div>
        <label htmlFor="lastInventoryDate" className="block text-sm font-medium text-gray-700">Last Inventory Date</label>
        <input
          type="date"
          id="lastInventoryDate"
          value={patient.inventory?.lastInventoryDate || ''}
          onChange={(e) => handleChange('inventory', 'lastInventoryDate', e.target.value)}
          className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
        />
      </div>
      <div>
        <h3 className="text-lg font-semibold mt-4 mb-2">Personal Belongings</h3>
        {patient.inventory?.personalBelongings?.map((item, index) => (
          <div key={index} className="flex items-center mb-2">
            <input
              type="text"
              value={item.name}
              onChange={(e) => handleArrayChange('inventory.personalBelongings', index, {...item, name: e.target.value})}
              placeholder="Item Name"
              className="flex-grow mr-2 border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
            />
            <input
              type="text"
              value={item.condition}
              onChange={(e) => handleArrayChange('inventory.personalBelongings', index, {...item, condition: e.target.value})}
              placeholder="Condition"
              className="flex-grow mr-2 border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
            />
            <button
              type="button"
              onClick={() => handleArrayRemove('inventory.personalBelongings', index)}
              className="bg-red-500 text-white px-2 py-1 rounded hover:bg-red-600"
            >
              Remove
            </button>
          </div>
        ))}
        <button
          type="button"
          onClick={() => handleArrayAdd('inventory.personalBelongings', { name: '', condition: '' })}
          className="mt-2 bg-green-500 text-white px-4 py-2 rounded hover:bg-green-600"
        >
          Add Personal Belonging
        </button>
      </div>
      <div>
        <h3 className="text-lg font-semibold mt-4 mb-2">Medical Equipment</h3>
        {patient.inventory?.medicalEquipment?.map((item, index) => (
          <div key={index} className="flex items-center mb-2">
            <input
              type="text"
              value={item.name}
              onChange={(e) => handleArrayChange('inventory.medicalEquipment', index, {...item, name: e.target.value})}
              placeholder="Equipment Name"
              className="flex-grow mr-2 border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
            />
            <input
              type="text"
              value={item.condition}
              onChange={(e) => handleArrayChange('inventory.medicalEquipment', index, {...item, condition: e.target.value})}
              placeholder="Condition"
              className="flex-grow mr-2 border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
            />
            <button
              type="button"
              onClick={() => handleArrayRemove('inventory.medicalEquipment', index)}
              className="bg-red-500 text-white px-2 py-1 rounded hover:bg-red-600"
            >
              Remove
            </button>
          </div>
        ))}
        <button
          type="button"
          onClick={() => handleArrayAdd('inventory.medicalEquipment', { name: '', condition: '' })}
          className="mt-2 bg-green-500 text-white px-4 py-2 rounded hover:bg-green-600"
        >
          Add Medical Equipment
        </button>
      </div>
    </div>
  </div>
);

const SignificantEventsSection = ({ patient, handleArrayChange, handleArrayAdd, handleArrayRemove }) => (
  <div>
    <h2 className="text-xl font-semibold mb-2">Significant Events</h2>
    {patient.significantEvents?.map((event, index) => (
      <div key={index} className="mb-4 p-4 bg-gray-100 rounded">
        <input
          type="date"
          value={event.date}
          onChange={(e) => handleArrayChange('significantEvents', index, {...event, date: e.target.value})}
          className="w-full mb-2 p-2 border rounded"
        />
        <input
          type="text"
          value={event.type}
          onChange={(e) => handleArrayChange('significantEvents', index, {...event, type: e.target.value})}
          placeholder="Event Type"
          className="w-full mb-2 p-2 border rounded"
        />
        <textarea
          value={event.description}
          onChange={(e) => handleArrayChange('significantEvents', index, {...event, description: e.target.value})}
          placeholder="Description"
          className="w-full mb-2 p-2 border rounded"
        ></textarea>
        <input
          type="text"
          value={event.staffInvolved}
          onChange={(e) => handleArrayChange('significantEvents', index, {...event, staffInvolved: e.target.value})}
          placeholder="Staff Involved"
          className="w-full mb-2 p-2 border rounded"
        />
        <textarea
          value={event.followUp}
          onChange={(e) => handleArrayChange('significantEvents', index, {...event, followUp: e.target.value})}
          placeholder="Follow-up"
          className="w-full mb-2 p-2 border rounded"
        ></textarea>
        <button
          type="button"
          onClick={() => handleArrayRemove('significantEvents', index)}
          className="bg-red-500 text-white px-2 py-1 rounded hover:bg-red-600"
        >
          Remove
        </button>
      </div>
    ))}
    <button
      type="button"
      onClick={() => handleArrayAdd('significantEvents', { date: '', type: '', description: '', staffInvolved: '', followUp: '' })}
      className="mt-2 bg-green-500 text-white px-4 py-2 rounded hover:bg-green-600"
    >
      Add Event
    </button>
  </div>
);

const MedicalContactsSection = ({ patient, handleArrayChange, handleArrayAdd, handleArrayRemove }) => (
  <div>
    <h2 className="text-xl font-semibold mb-2">Medical Contacts</h2>
    {patient.medicalContacts?.map((contact, index) => (
      <div key={index} className="mb-4 p-4 bg-gray-100 rounded">
        <input
          type="text"
          value={contact.name}
          onChange={(e) => handleArrayChange('medicalContacts', index, {...contact, name: e.target.value})}
          placeholder="Name"
          className="w-full mb-2 p-2 border rounded"
        />
        <input
          type="text"
          value={contact.specialty}
          onChange={(e) => handleArrayChange('medicalContacts', index, {...contact, specialty: e.target.value})}
          placeholder="Specialty"
          className="w-full mb-2 p-2 border rounded"
        />
        <input
          type="text"
          value={contact.contact}
          onChange={(e) => handleArrayChange('medicalContacts', index, {...contact, contact: e.target.value})}
          placeholder="Contact"
          className="w-full mb-2 p-2 border rounded"
        />
        <input
          type="date"
          value={contact.lastAppointment}
          onChange={(e) => handleArrayChange('medicalContacts', index, {...contact, lastAppointment: e.target.value})}
          placeholder="Last Appointment"
          className="w-full mb-2 p-2 border rounded"
        />
        <input
          type="date"
          value={contact.nextAppointment}
          onChange={(e) => handleArrayChange('medicalContacts', index, {...contact, nextAppointment: e.target.value})}
          placeholder="Next Appointment"
          className="w-full mb-2 p-2 border rounded"
        />
        <button
          type="button"
          onClick={() => handleArrayRemove('medicalContacts', index)}
          className="bg-red-500 text-white px-2 py-1 rounded hover:bg-red-600"
        >
          Remove
        </button>
      </div>
    ))}
    <button
      type="button"
      onClick={() => handleArrayAdd('medicalContacts', { name: '', specialty: '', contact: '', lastAppointment: '', nextAppointment: '' })}
      className="mt-2 bg-green-500 text-white px-4 py-2 rounded hover:bg-green-600"
    >
      Add Medical Contact
    </button>
  </div>
);

const FacesheetSection = ({ patient, handleChange, handleArrayChange, handleArrayAdd, handleArrayRemove }) => (
  <div>
    <h2 className="text-xl font-semibold mb-2">Facesheet</h2>
    <div className="space-y-4">
      <div>
        <label htmlFor="lawEnforcement" className="block text-sm font-medium text-gray-700">Law Enforcement</label>
        <input
          type="text"
          id="lawEnforcement"
          value={patient.facesheet?.lawEnforcement || ''}
          onChange={(e) => handleChange('facesheet', 'lawEnforcement', e.target.value)}
          className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
        />
      </div>
      <div>
        <label htmlFor="paramedics" className="block text-sm font-medium text-gray-700">Paramedics</label>
        <input
          type="text"
          id="paramedics"
          value={patient.facesheet?.paramedics || ''}
          onChange={(e) => handleChange('facesheet', 'paramedics', e.target.value)}
          className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
        />
      </div>
      <div>
        <label htmlFor="supportCoordinators" className="block text-sm font-medium text-gray-700">Support Coordinators</label>
        <input
          type="text"
          id="supportCoordinators"
          value={patient.facesheet?.supportCoordinators || ''}
          onChange={(e) => handleChange('facesheet', 'supportCoordinators', e.target.value)}
          className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
        />
      </div>
      <div>
        <label htmlFor="hospital" className="block text-sm font-medium text-gray-700">Hospital</label>
        <input
          type="text"
          id="hospital"
          value={patient.facesheet?.hospital || ''}
          onChange={(e) => handleChange('facesheet', 'hospital', e.target.value)}
          className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
        />
      </div>
      <div>
        <label htmlFor="apd" className="block text-sm font-medium text-gray-700">APD</label>
        <input
          type="text"
          id="apd"
          value={patient.facesheet?.apd || ''}
          onChange={(e) => handleChange('facesheet', 'apd', e.target.value)}
          className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
        />
      </div>
      <div>
        <h3 className="text-lg font-semibold mt-4 mb-2">Family Members</h3>
        {patient.facesheet?.familyMembers?.map((member, index) => (
          <div key={index} className="flex items-center mb-2">
            <input
              type="text"
              value={member.name}
              onChange={(e) => handleArrayChange('facesheet.familyMembers', index, {...member, name: e.target.value})}
              placeholder="Name"
              className="flex-grow mr-2 border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
            />
            <input
              type="text"
              value={member.relationship}
              onChange={(e) => handleArrayChange('facesheet.familyMembers', index, {...member, relationship: e.target.value})}
              placeholder="Relationship"
              className="flex-grow mr-2 border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
            />
            <input
              type="text"
              value={member.contact}
              onChange={(e) => handleArrayChange('facesheet.familyMembers', index, {...member, contact: e.target.value})}
              placeholder="Contact"
              className="flex-grow mr-2 border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
            />
            <button
              type="button"
              onClick={() => handleArrayRemove('facesheet.familyMembers', index)}
              className="bg-red-500 text-white px-2 py-1 rounded hover:bg-red-600"
            >
              Remove
            </button>
          </div>
        ))}
        <button
          type="button"
          onClick={() => handleArrayAdd('facesheet.familyMembers', { name: '', relationship: '', contact: '' })}
          className="mt-2 bg-green-500 text-white px-4 py-2 rounded hover:bg-green-600"
        >
          Add Family Member
        </button>
      </div>
    </div>
  </div>
);

const IncomeAndExpensesSection = ({ patient, handleChange, handleArrayChange, handleArrayAdd, handleArrayRemove }) => (
  <div>
    <h2 className="text-xl font-semibold mb-2">Income and Expenses</h2>
    <div className="space-y-4">
      <div>
        <h3 className="text-lg font-semibold mt-4 mb-2">Income Sources</h3>
        {patient.incomeAndExpenses?.incomeSources?.map((source, index) => (
          <div key={index} className="flex items-center mb-2">
            <input
              type="text"
              value={source.name}
              onChange={(e) => handleArrayChange('incomeAndExpenses.incomeSources', index, {...source, name: e.target.value})}
              placeholder="Source Name"
              className="flex-grow mr-2 border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
            />
            <input
              type="number"
              value={source.amount}
              onChange={(e) => handleArrayChange('incomeAndExpenses.incomeSources', index, {...source, amount: parseFloat(e.target.value)})}
              placeholder="Amount"
              className="flex-grow mr-2 border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
            />
            <button
              type="button"
              onClick={() => handleArrayRemove('incomeAndExpenses.incomeSources', index)}
              className="bg-red-500 text-white px-2 py-1 rounded hover:bg-red-600"
            >
              Remove
            </button>
          </div>
        ))}
        <button
          type="button"
          onClick={() => handleArrayAdd('incomeAndExpenses.incomeSources', { name: '', amount: 0 })}
          className="mt-2 bg-green-500 text-white px-4 py-2 rounded hover:bg-green-600"
        >
          Add Income Source
        </button>
      </div>
      <div>
        <label htmlFor="totalMonthlyIncome" className="block text-sm font-medium text-gray-700">Total Monthly Income</label>
        <input
          type="number"
          id="totalMonthlyIncome"
          value={patient.incomeAndExpenses?.totalMonthlyIncome || 0}
          onChange={(e) => handleChange('incomeAndExpenses', 'totalMonthlyIncome', parseFloat(e.target.value))}
          className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
        />
      </div>
      <div>
        <h3 className="text-lg font-semibold mt-4 mb-2">Fixed Expenses</h3>
        {patient.incomeAndExpenses?.fixedExpenses?.map((expense, index) => (
          <div key={index} className="flex items-center mb-2">
            <input
              type="text"
              value={expense.name}
              onChange={(e) => handleArrayChange('incomeAndExpenses.fixedExpenses', index, {...expense, name: e.target.value})}
              placeholder="Expense Name"
              className="flex-grow mr-2 border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
            />
            <input
              type="number"
              value={expense.amount}
              onChange={(e) => handleArrayChange('incomeAndExpenses.fixedExpenses', index, {...expense, amount: parseFloat(e.target.value)})}
              placeholder="Amount"
              className="flex-grow mr-2 border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
            />
            <button
              type="button"
              onClick={() => handleArrayRemove('incomeAndExpenses.fixedExpenses', index)}
              className="bg-red-500 text-white px-2 py-1 rounded hover:bg-red-600"
            >
              Remove
            </button>
          </div>
        ))}
        <button
          type="button"
          onClick={() => handleArrayAdd('incomeAndExpenses.fixedExpenses', { name: '', amount: 0 })}
          className="mt-2 bg-green-500 text-white px-4 py-2 rounded hover:bg-green-600"
        >
          Add Fixed Expense
        </button>
      </div>
      <div>
        <h3 className="text-lg font-semibold mt-4 mb-2">Variable Expenses</h3>
        {patient.incomeAndExpenses?.variableExpenses?.map((expense, index) => (
          <div key={index} className="flex items-center mb-2">
            <input
              type="text"
              value={expense.name}
              onChange={(e) => handleArrayChange('incomeAndExpenses.variableExpenses', index, {...expense, name: e.target.value})}
              placeholder="Expense Name"
              className="flex-grow mr-2 border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
            />
            <input
              type="number"
              value={expense.amount}
              onChange={(e) => handleArrayChange('incomeAndExpenses.variableExpenses', index, {...expense, amount: parseFloat(e.target.value)})}
              placeholder="Amount"
              className="flex-grow mr-2 border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
            />
            <button
              type="button"
              onClick={() => handleArrayRemove('incomeAndExpenses.variableExpenses', index)}
              className="bg-red-500 text-white px-2 py-1 rounded hover:bg-red-600"
            >
              Remove
            </button>
          </div>
        ))}
        <button
          type="button"
          onClick={() => handleArrayAdd('incomeAndExpenses.variableExpenses', { name: '', amount: 0 })}
          className="mt-2 bg-green-500 text-white px-4 py-2 rounded hover:bg-green-600"
        >
          Add Variable Expense
        </button>
      </div>
      <div>
        <label htmlFor="totalMonthlyExpenses" className="block text-sm font-medium text-gray-700">Total Monthly Expenses</label>
        <input
          type="number"
          id="totalMonthlyExpenses"
          value={patient.incomeAndExpenses?.totalMonthlyExpenses || 0}
          onChange={(e) => handleChange('incomeAndExpenses', 'totalMonthlyExpenses', parseFloat(e.target.value))}
          className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
        />
      </div>
      <div>
        <label htmlFor="monthlySavings" className="block text-sm font-medium text-gray-700">Monthly Savings</label>
        <input
          type="number"
          id="monthlySavings"
          value={patient.incomeAndExpenses?.monthlySavings || 0}
          onChange={(e) => handleChange('incomeAndExpenses', 'monthlySavings', parseFloat(e.target.value))}
          className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
        />
      </div>
    </div>
  </div>
);

const LogsSection = ({ patient, handleArrayChange, handleArrayAdd, handleArrayRemove }) => (
  <div>
    <h2 className="text-xl font-semibold mb-2">Logs</h2>
    <div className="space-y-6">
      <div>
        <h3 className="text-lg font-semibold mt-4 mb-2">Rehab Logs</h3>
        {patient.logs?.rehabLogs?.map((log, index) => (
          <div key={index} className="mb-2 p-2 bg-gray-100 rounded">
            <input
              type="date"
              value={log.date}
              onChange={(e) => handleArrayChange('logs.rehabLogs', index, {...log, date: e.target.value})}
              className="w-full mb-2 p-2 border rounded"
            />
            <input
              type="text"
              value={log.activity}
              onChange={(e) => handleArrayChange('logs.rehabLogs', index, {...log, activity: e.target.value})}
              placeholder="Activity"
              className="w-full mb-2 p-2 border rounded"
            />
            <textarea
              value={log.progress}
              onChange={(e) => handleArrayChange('logs.rehabLogs', index, {...log, progress: e.target.value})}
              placeholder="Progress"
              className="w-full mb-2 p-2 border rounded"
            ></textarea>
            <button
              type="button"
              onClick={() => handleArrayRemove('logs.rehabLogs', index)}
              className="bg-red-500 text-white px-2 py-1 rounded hover:bg-red-600"
            >
              Remove
            </button>
          </div>
        ))}
        <button
          type="button"
          onClick={() => handleArrayAdd('logs.rehabLogs', { date: '', activity: '', progress: '' })}
          className="mt-2 bg-green-500 text-white px-4 py-2 rounded hover:bg-green-600"
        >
          Add Rehab Log
        </button>
      </div>

      <div>
        <h3 className="text-lg font-semibold mt-4 mb-2">Respite Logs</h3>
        {patient.logs?.respiteLogs?.map((log, index) => (
          <div key={index} className="mb-2 p-2 bg-gray-100 rounded">
            <input
              type="date"
              value={log.date}
              onChange={(e) => handleArrayChange('logs.respiteLogs', index, {...log, date: e.target.value})}
              className="w-full mb-2 p-2 border rounded"
            />
            <input
              type="text"
              value={log.duration}
              onChange={(e) => handleArrayChange('logs.respiteLogs', index, {...log, duration: e.target.value})}
              placeholder="Duration"
              className="w-full mb-2 p-2 border rounded"
            />
            <textarea
              value={log.reason}
              onChange={(e) => handleArrayChange('logs.respiteLogs', index, {...log, reason: e.target.value})}
              placeholder="Reason"
              className="w-full mb-2 p-2 border rounded"
            ></textarea>
            <button
              type="button"
              onClick={() => handleArrayRemove('logs.respiteLogs', index)}
              className="bg-red-500 text-white px-2 py-1 rounded hover:bg-red-600"
            >
              Remove
            </button>
          </div>
        ))}
        <button
          type="button"
          onClick={() => handleArrayAdd('logs.respiteLogs', { date: '', duration: '', reason: '' })}
          className="mt-2 bg-green-500 text-white px-4 py-2 rounded hover:bg-green-600"
        >
          Add Respite Log
        </button>
      </div>

      <div>
        <h3 className="text-lg font-semibold mt-4 mb-2">Life Skills Logs</h3>
        {patient.logs?.lifeSkillsLogs?.map((log, index) => (
          <div key={index} className="mb-2 p-2 bg-gray-100 rounded">
            <input
              type="date"
              value={log.date}
              onChange={(e) => handleArrayChange('logs.lifeSkillsLogs', index, {...log, date: e.target.value})}
              className="w-full mb-2 p-2 border rounded"
            />
            <input
              type="text"
              value={log.skillPracticed}
              onChange={(e) => handleArrayChange('logs.lifeSkillsLogs', index, {...log, skillPracticed: e.target.value})}
              placeholder="Skill Practiced"
              className="w-full mb-2 p-2 border rounded"
            />
            <textarea
              value={log.progress}
              onChange={(e) => handleArrayChange('logs.lifeSkillsLogs', index, {...log, progress: e.target.value})}
              placeholder="Progress"
              className="w-full mb-2 p-2 border rounded"
            ></textarea>
            <button
              type="button"
              onClick={() => handleArrayRemove('logs.lifeSkillsLogs', index)}
              className="bg-red-500 text-white px-2 py-1 rounded hover:bg-red-600"
            >
              Remove
            </button>
          </div>
        ))}
        <button
          type="button"
          onClick={() => handleArrayAdd('logs.lifeSkillsLogs', { date: '', skillPracticed: '', progress: '' })}
          className="mt-2 bg-green-500 text-white px-4 py-2 rounded hover:bg-green-600"
        >
          Add Life Skills Log
        </button>
      </div>

      <div>
        <h3 className="text-lg font-semibold mt-4 mb-2">Personal Support Logs</h3>
        {patient.logs?.personalSupportLogs?.map((log, index) => (
          <div key={index} className="mb-2 p-2 bg-gray-100 rounded">
            <input
              type="date"
              value={log.date}
              onChange={(e) => handleArrayChange('logs.personalSupportLogs', index, {...log, date: e.target.value})}
              className="w-full mb-2 p-2 border rounded"
            />
            <input
              type="text"
              value={log.supportType}
              onChange={(e) => handleArrayChange('logs.personalSupportLogs', index, {...log, supportType: e.target.value})}
              placeholder="Type of Support"
              className="w-full mb-2 p-2 border rounded"
            />
            <input
              type="text"
              value={log.staff}
              onChange={(e) => handleArrayChange('logs.personalSupportLogs', index, {...log, staff: e.target.value})}
              placeholder="Staff"
              className="w-full mb-2 p-2 border rounded"
            />
            <button
              type="button"
              onClick={() => handleArrayRemove('logs.personalSupportLogs', index)}
              className="bg-red-500 text-white px-2 py-1 rounded hover:bg-red-600"
            >
              Remove
            </button>
          </div>
        ))}
        <button
          type="button"
          onClick={() => handleArrayAdd('logs.personalSupportLogs', { date: '', supportType: '', staff: '' })}
          className="mt-2 bg-green-500 text-white px-4 py-2 rounded hover:bg-green-600"
        >
          Add Personal Support Log
        </button>
      </div>

      <div>
        <h3 className="text-lg font-semibold mt-4 mb-2">Seizure Logs</h3>
        {patient.logs?.seizureLogs?.map((log, index) => (
          <div key={index} className="mb-2 p-2 bg-gray-100 rounded">
            <input
              type="date"
              value={log.date}
              onChange={(e) => handleArrayChange('logs.seizureLogs', index, {...log, date: e.target.value})}
              className="w-full mb-2 p-2 border rounded"
            />
            <input
              type="text"
              value={log.duration}
              onChange={(e) => handleArrayChange('logs.seizureLogs', index, {...log, duration: e.target.value})}
              placeholder="Duration"
              className="w-full mb-2 p-2 border rounded"
            />
            <input
              type="text"
              value={log.type}
              onChange={(e) => handleArrayChange('logs.seizureLogs', index, {...log, type: e.target.value})}
              placeholder="Type"
              className="w-full mb-2 p-2 border rounded"
            />
            <textarea
              value={log.triggers}
              onChange={(e) => handleArrayChange('logs.seizureLogs', index, {...log, triggers: e.target.value})}
              placeholder="Triggers"
              className="w-full mb-2 p-2 border rounded"
            ></textarea>
            <button
              type="button"
              onClick={() => handleArrayRemove('logs.seizureLogs', index)}
              className="bg-red-500 text-white px-2 py-1 rounded hover:bg-red-600"
            >
              Remove
            </button>
          </div>
        ))}
        <button
          type="button"
          onClick={() => handleArrayAdd('logs.seizureLogs', { date: '', duration: '', type: '', triggers: '' })}
          className="mt-2 bg-green-500 text-white px-4 py-2 rounded hover:bg-green-600"
        >
          Add Seizure Log
        </button>
      </div>
    </div>
  </div>
);

export default EditPatientEMR;