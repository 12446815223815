import React, { useState, useEffect } from 'react';
import { collection, getDocs, doc, deleteDoc } from 'firebase/firestore';
import { db } from '../firebase';
import Layout from './Layout';
import { useNavigate, Link } from 'react-router-dom';

const Insurance = () => {
  const [insuranceProviders, setInsuranceProviders] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [sortBy, setSortBy] = useState('nameAZ');
  const navigate = useNavigate();

  useEffect(() => {
    fetchInsuranceProviders();
  }, []);

  const fetchInsuranceProviders = async () => {
    try {
      const insuranceCollection = collection(db, 'insurance');
      const insuranceSnapshot = await getDocs(insuranceCollection);
      const insuranceList = insuranceSnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data(),
        createdAt: doc.data().createdAt ? doc.data().createdAt.toDate() : new Date(0)
      }));
      setInsuranceProviders(insuranceList);
      setLoading(false);
    } catch (err) {
      setError('Failed to fetch insurance providers: ' + err.message);
      setLoading(false);
    }
  };

  const handleDelete = async (id) => {
    if (window.confirm('Are you sure you want to delete this insurance provider?')) {
      try {
        await deleteDoc(doc(db, 'insurance', id));
        fetchInsuranceProviders();
      } catch (err) {
        setError('Failed to delete insurance provider: ' + err.message);
      }
    }
  };

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleSortChange = (e) => {
    setSortBy(e.target.value);
  };

  const filteredAndSortedInsurance = insuranceProviders
    .filter(provider => 
      provider.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
      provider.type.toLowerCase().includes(searchTerm.toLowerCase()) ||
      provider.contact.toLowerCase().includes(searchTerm.toLowerCase())
    )
    .sort((a, b) => {
      switch (sortBy) {
        case 'nameAZ':
          return a.name.localeCompare(b.name);
        case 'nameZA':
          return b.name.localeCompare(a.name);
        case 'typeAZ':
          return a.type.localeCompare(b.type);
        case 'typeZA':
          return b.type.localeCompare(a.type);
        case 'mostRecent':
          return b.createdAt - a.createdAt;
        case 'oldest':
          return a.createdAt - b.createdAt;
        default:
          return 0;
      }
    });

  if (loading) {
    return <Layout title="Insurance Providers">Loading...</Layout>;
  }

  if (error) {
    return <Layout title="Insurance Providers">Error: {error}</Layout>;
  }

  return (
    <Layout title="Insurance Providers">
      <div className="mb-4 flex justify-between items-center">
        <h1 className="text-2xl font-bold text-left">Insurance Providers</h1>
        <button
          onClick={() => navigate('/add-insurance')}
          className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
        >
          Add Insurance Provider
        </button>
      </div>

      <div className="mb-4 flex justify-between items-center">
        <input
          type="text"
          placeholder="Search insurance providers..."
          value={searchTerm}
          onChange={handleSearchChange}
          className="p-2 border rounded w-1/2 text-left"
        />
        <select
          value={sortBy}
          onChange={handleSortChange}
          className="p-2 border rounded"
        >
          <option value="nameAZ">Name (A-Z)</option>
          <option value="nameZA">Name (Z-A)</option>
          <option value="typeAZ">Type (A-Z)</option>
          <option value="typeZA">Type (Z-A)</option>
          <option value="mostRecent">Most Recent</option>
          <option value="oldest">Oldest</option>
        </select>
      </div>

      {filteredAndSortedInsurance.length === 0 ? (
        <p className="text-left">No insurance providers found.</p>
      ) : (
        <div className="overflow-x-auto">
          <table className="min-w-full divide-y divide-gray-200">
            <thead className="bg-gray-50">
              <tr>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Name</th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Type</th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Contact</th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Actions</th>
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200">
              {filteredAndSortedInsurance.map((provider) => (
                <tr key={provider.id}>
                  <td className="px-6 py-4 whitespace-nowrap text-left">
                    <span className="text-sm text-gray-900">{provider.name}</span>
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-left">
                    <span className="text-sm text-gray-900">{provider.type}</span>
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-left">
                    <span className="text-sm text-gray-900">{provider.contact}</span>
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-left text-sm font-medium">
                    <Link to={`/insurance/${provider.slug || provider.id}`} className="text-blue-600 hover:text-blue-900 mr-2">
                      View
                    </Link>
                    <Link to={`/edit-insurance/${provider.slug || provider.id}`} className="text-indigo-600 hover:text-indigo-900 mr-2">
                      Edit
                    </Link>
                    <button onClick={() => handleDelete(provider.id)} className="text-red-600 hover:text-red-900">
                      Delete
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </Layout>
  );
};

export default Insurance;