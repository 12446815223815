import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { collection, addDoc, getDocs } from 'firebase/firestore';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { db, storage } from '../firebase';
import Layout from './Layout';

const AddDocument = () => {
  const navigate = useNavigate();
  const [document, setDocument] = useState({
    name: '',
    description: '',
    associatedWith: '',
    associatedId: '',
    type: '',
  });
  const [file, setFile] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [entities, setEntities] = useState([]);

  const fetchEntities = useCallback(async () => {
    if (!document.associatedWith) return;
    
    try {
      const querySnapshot = await getDocs(collection(db, document.associatedWith));
      const entityList = querySnapshot.docs.map(doc => ({
        id: doc.id,
        name: doc.data().name || doc.data().demographics?.name || 'Unknown'
      }));
      setEntities(entityList);
    } catch (err) {
      console.error("Error fetching entities:", err);
      setError('Failed to fetch entities: ' + err.message);
    }
  }, [document.associatedWith]);

  useEffect(() => {
    fetchEntities();
  }, [fetchEntities]);

  const getFileType = useCallback((fileName) => {
    const extension = fileName.split('.').pop().toLowerCase();
    if (['jpg', 'jpeg', 'png', 'gif', 'bmp'].includes(extension)) return 'image';
    if (['pdf'].includes(extension)) return 'pdf';
    if (['doc', 'docx'].includes(extension)) return 'word';
    if (['xls', 'xlsx'].includes(extension)) return 'excel';
    if (['ppt', 'pptx'].includes(extension)) return 'powerpoint';
    return 'other';
  }, []);

  const handleChange = useCallback((e) => {
    const { name, value } = e.target;
    setDocument(prev => ({ ...prev, [name]: value }));
  }, []);

  const handleFileChange = useCallback((e) => {
    if (e.target.files[0]) {
      setFile(e.target.files[0]);
    }
  }, []);

  const handleSubmit = useCallback(async (e) => {
    e.preventDefault();
    setLoading(true);
    setError(null);

    try {
      if (!file) {
        throw new Error('Please select a file to upload.');
      }

      // Upload file to Firebase Storage
      const storageRef = ref(storage, `documents/${file.name}`);
      await uploadBytes(storageRef, file);
      const downloadURL = await getDownloadURL(storageRef);

      // Add document to Firestore
      const docRef = await addDoc(collection(db, 'documents'), {
        ...document,
        fileUrl: downloadURL,
        fileName: file.name,
        fileType: getFileType(file.name),
        uploadedBy: 'Current User', // Replace with actual user info when available
        uploadedAt: new Date().toISOString(),
      });

      navigate(`/documents/${docRef.id}`);
    } catch (err) {
      console.error("Error adding document:", err);
      setError('Failed to add new document: ' + err.message);
    } finally {
      setLoading(false);
    }
  }, [document, file, getFileType, navigate]);

  if (loading) {
    return <Layout title="Add Document">Loading...</Layout>;
  }

  return (
    <Layout title="Add Document">
      <div className="max-w-2xl mx-auto text-left">
        <h2 className="text-2xl font-bold mb-4">Add New Document</h2>
        {error && <p className="text-red-500 mb-4">{error}</p>}
        <form onSubmit={handleSubmit} className="space-y-4">
          <div>
            <label htmlFor="name" className="block text-sm font-medium text-gray-700">Document Name</label>
            <input
              type="text"
              id="name"
              name="name"
              value={document.name}
              onChange={handleChange}
              required
              className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
            />
          </div>
          <div>
            <label htmlFor="type" className="block text-sm font-medium text-gray-700">Document Type</label>
            <select
              id="type"
              name="type"
              value={document.type}
              onChange={handleChange}
              required
              className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
            >
              <option value="">Select Document Type</option>
              <option value="Medical Record">Medical Record</option>
              <option value="Lab Result">Lab Result</option>
              <option value="Prescription">Prescription</option>
              <option value="Insurance Claim">Insurance Claim</option>
              <option value="Consent Form">Consent Form</option>
            </select>
          </div>
          <div>
            <label htmlFor="description" className="block text-sm font-medium text-gray-700">Description</label>
            <textarea
              id="description"
              name="description"
              value={document.description}
              onChange={handleChange}
              rows="3"
              className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
            ></textarea>
          </div>
          <div>
            <label htmlFor="file" className="block text-sm font-medium text-gray-700">File</label>
            <input
              type="file"
              id="file"
              onChange={handleFileChange}
              required
              className="mt-1 block w-full"
            />
          </div>
          <div>
            <label htmlFor="associatedWith" className="block text-sm font-medium text-gray-700">Associated With</label>
            <select
              id="associatedWith"
              name="associatedWith"
              value={document.associatedWith}
              onChange={handleChange}
              className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
            >
              <option value="">Select Associated Entity</option>
              <option value="patients">Patient</option>
              <option value="providers">Provider</option>
              <option value="clinics">Clinic</option>
              <option value="hospitals">Hospital</option>
            </select>
          </div>
          {document.associatedWith && (
            <div>
              <label htmlFor="associatedId" className="block text-sm font-medium text-gray-700">Associated {document.associatedWith.slice(0, -1)}</label>
              <select
                id="associatedId"
                name="associatedId"
                value={document.associatedId}
                onChange={handleChange}
                className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              >
                <option value="">Select {document.associatedWith.slice(0, -1)}</option>
                {entities.map(entity => (
                  <option key={entity.id} value={entity.id}>{entity.name}</option>
                ))}
              </select>
            </div>
          )}
          <div className="flex justify-end space-x-2">
            <button
              type="submit"
              disabled={loading}
              className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
              {loading ? 'Adding...' : 'Add Document'}
            </button>
            <button
              type="button"
              onClick={() => navigate('/documents')}
              className="inline-flex justify-center py-2 px-4 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
              Cancel
            </button>
          </div>
        </form>
      </div>
    </Layout>
  );
};

export default AddDocument;