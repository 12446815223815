import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { doc, getDoc, updateDoc } from 'firebase/firestore';
import { db } from '../firebase';
import Layout from './Layout';

const EditInsurance = () => {
  const [insurance, setInsurance] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { id } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchInsurance = async () => {
      try {
        const docRef = doc(db, 'insurance', id);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
          const insuranceData = { id: docSnap.id, ...docSnap.data() };
          setInsurance(insuranceData);
        } else {
          setError('Insurance provider not found');
        }
      } catch (err) {
        setError('Error fetching insurance provider details: ' + err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchInsurance();
  }, [id]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === 'coverageAreas' || name === 'planTypes') {
      setInsurance(prev => ({
        ...prev,
        [name]: value.split(',').map(item => item.trim())
      }));
    } else {
      setInsurance(prev => ({
        ...prev,
        [name]: value
      }));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const insuranceRef = doc(db, 'insurance', id);
      await updateDoc(insuranceRef, {
        ...insurance,
        updatedAt: new Date()
      });
      navigate(`/insurance/${id}`);
    } catch (err) {
      setError('Failed to update insurance provider: ' + err.message);
    }
  };

  if (loading) return <Layout title="Edit Insurance Provider">Loading...</Layout>;
  if (error) return <Layout title="Edit Insurance Provider">Error: {error}</Layout>;
  if (!insurance) return <Layout title="Edit Insurance Provider">Insurance provider not found</Layout>;

  return (
    <Layout title="Edit Insurance Provider">
      <form onSubmit={handleSubmit} className="space-y-4 text-left">
        <div>
          <label htmlFor="name" className="block text-sm font-medium text-gray-700 text-left">Name</label>
          <input
            type="text"
            name="name"
            id="name"
            value={insurance.name}
            onChange={handleChange}
            className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm text-left"
          />
        </div>

        <div>
          <label htmlFor="type" className="block text-sm font-medium text-gray-700 text-left">Type</label>
          <input
            type="text"
            name="type"
            id="type"
            value={insurance.type}
            onChange={handleChange}
            className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm text-left"
          />
        </div>

        <div>
          <label htmlFor="contact" className="block text-sm font-medium text-gray-700 text-left">Contact</label>
          <input
            type="text"
            name="contact"
            id="contact"
            value={insurance.contact}
            onChange={handleChange}
            className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm text-left"
          />
        </div>

        <div>
          <label htmlFor="email" className="block text-sm font-medium text-gray-700 text-left">Email</label>
          <input
            type="email"
            name="email"
            id="email"
            value={insurance.email}
            onChange={handleChange}
            className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm text-left"
          />
        </div>

        <div>
          <label htmlFor="website" className="block text-sm font-medium text-gray-700 text-left">Website</label>
          <input
            type="url"
            name="website"
            id="website"
            value={insurance.website}
            onChange={handleChange}
            className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm text-left"
          />
        </div>

        <div>
          <label htmlFor="coverageAreas" className="block text-sm font-medium text-gray-700 text-left">Coverage Areas</label>
          <input
            type="text"
            name="coverageAreas"
            id="coverageAreas"
            value={insurance.coverageAreas ? insurance.coverageAreas.join(', ') : ''}
            onChange={handleChange}
            className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm text-left"
          />
        </div>

        <div>
          <label htmlFor="planTypes" className="block text-sm font-medium text-gray-700 text-left">Plan Types</label>
          <input
            type="text"
            name="planTypes"
            id="planTypes"
            value={insurance.planTypes ? insurance.planTypes.join(', ') : ''}
            onChange={handleChange}
            className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm text-left"
          />
        </div>

        <div>
          <label htmlFor="enrollmentProcess" className="block text-sm font-medium text-gray-700 text-left">Enrollment Process</label>
          <textarea
            name="enrollmentProcess"
            id="enrollmentProcess"
            value={insurance.enrollmentProcess}
            onChange={handleChange}
            rows="4"
            className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm text-left"
          />
        </div>

        <div>
          <label htmlFor="customerSupport" className="block text-sm font-medium text-gray-700 text-left">Customer Support</label>
          <textarea
            name="customerSupport"
            id="customerSupport"
            value={insurance.customerSupport}
            onChange={handleChange}
            rows="4"
            className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm text-left"
          />
        </div>

        <div className="flex justify-start">
          <button
            type="submit"
            className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            Save Changes
          </button>
        </div>
      </form>
    </Layout>
  );
};

export default EditInsurance;