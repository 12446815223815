import React, { useState } from 'react';
import { setDoc, doc, serverTimestamp } from 'firebase/firestore';
import { db } from '../firebase';
import { useNavigate } from 'react-router-dom';
import Layout from './Layout';

const AddProvider = () => {
  const navigate = useNavigate();
  const [newProvider, setNewProvider] = useState({
    name: '',
    specialty: '',
    contact: '',
    email: '',
    licenseNumber: '',
    yearsOfExperience: '',
    education: '',
    languages: [],
    country: '',
    availability: {
      Monday: { isAvailable: false, start: '', end: '' },
      Tuesday: { isAvailable: false, start: '', end: '' },
      Wednesday: { isAvailable: false, start: '', end: '' },
      Thursday: { isAvailable: false, start: '', end: '' },
      Friday: { isAvailable: false, start: '', end: '' },
      Saturday: { isAvailable: false, start: '', end: '' },
      Sunday: { isAvailable: false, start: '', end: '' }
    },
  });
  const [errorMessage, setErrorMessage] = useState('');

  const generateSlug = (name) => {
    return name.toLowerCase().replace(/\s+/g, '-').replace(/[^a-z0-9-]+/g, '');
  };

  const handleAddProvider = async (e) => {
    e.preventDefault();
    try {
      const slug = generateSlug(newProvider.name);
      const providerData = {
        ...newProvider,
        slug,
        yearsOfExperience: parseInt(newProvider.yearsOfExperience) || 0,
        createdAt: serverTimestamp(),
        updatedAt: serverTimestamp()
      };

      await setDoc(doc(db, 'providers', slug), providerData);
      navigate('/providers');
    } catch (err) {
      console.error("Error adding provider:", err);
      setErrorMessage('Failed to add new provider: ' + err.message);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === 'languages') {
      setNewProvider(prev => ({
        ...prev,
        [name]: value.split(',').map(item => item.trim())
      }));
    } else {
      setNewProvider(prev => ({ ...prev, [name]: value }));
    }
  };

  const handleAvailabilityToggle = (day) => {
    setNewProvider(prev => ({
      ...prev,
      availability: {
        ...prev.availability,
        [day]: {
          ...prev.availability[day],
          isAvailable: !prev.availability[day].isAvailable
        }
      }
    }));
  };

  const handleAvailabilityTimeChange = (day, field, value) => {
    setNewProvider(prev => ({
      ...prev,
      availability: {
        ...prev.availability,
        [day]: {
          ...prev.availability[day],
          [field]: value
        }
      }
    }));
  };

  return (
    <Layout title="Add Provider">
      <div className="max-w-2xl mx-auto">
        <h2 className="text-2xl font-bold mb-4">Add New Provider</h2>
        {errorMessage && <p className="text-red-500 mb-4">{errorMessage}</p>}
        <form onSubmit={handleAddProvider} className="space-y-4">
          <input
            name="name"
            value={newProvider.name}
            onChange={handleChange}
            placeholder="Provider Name"
            required
            className="w-full p-2 border rounded"
          />
          <input
            name="specialty"
            value={newProvider.specialty}
            onChange={handleChange}
            placeholder="Specialty"
            required
            className="w-full p-2 border rounded"
          />
          <input
            name="contact"
            value={newProvider.contact}
            onChange={handleChange}
            placeholder="Contact Number"
            required
            className="w-full p-2 border rounded"
          />
          <input
            name="email"
            type="email"
            value={newProvider.email}
            onChange={handleChange}
            placeholder="Email"
            required
            className="w-full p-2 border rounded"
          />
          <input
            name="licenseNumber"
            value={newProvider.licenseNumber}
            onChange={handleChange}
            placeholder="License Number"
            required
            className="w-full p-2 border rounded"
          />
          <input
            name="yearsOfExperience"
            type="number"
            value={newProvider.yearsOfExperience}
            onChange={handleChange}
            placeholder="Years of Experience"
            required
            className="w-full p-2 border rounded"
          />
          <input
            name="education"
            value={newProvider.education}
            onChange={handleChange}
            placeholder="Education"
            className="w-full p-2 border rounded"
          />
          <input
            name="languages"
            value={newProvider.languages.join(', ')}
            onChange={handleChange}
            placeholder="Languages (comma-separated)"
            className="w-full p-2 border rounded"
          />
          <input
            name="country"
            value={newProvider.country}
            onChange={handleChange}
            placeholder="Country"
            required
            className="w-full p-2 border rounded"
          />
          <div className="space-y-2">
            <h3 className="font-bold">Availability</h3>
            {Object.entries(newProvider.availability).map(([day, { isAvailable, start, end }]) => (
              <div key={day} className="flex items-center space-x-2">
                <label className="inline-flex items-center">
                  <input
                    type="checkbox"
                    checked={isAvailable}
                    onChange={() => handleAvailabilityToggle(day)}
                    className="form-checkbox h-5 w-5 text-blue-600"
                  />
                  <span className="ml-2 text-gray-700">{day}</span>
                </label>
                {isAvailable && (
                  <>
                    <input
                      type="time"
                      value={start}
                      onChange={(e) => handleAvailabilityTimeChange(day, 'start', e.target.value)}
                      className="p-1 border rounded"
                    />
                    <span>to</span>
                    <input
                      type="time"
                      value={end}
                      onChange={(e) => handleAvailabilityTimeChange(day, 'end', e.target.value)}
                      className="p-1 border rounded"
                    />
                  </>
                )}
              </div>
            ))}
          </div>
          <div className="flex justify-end">
            <button
              type="submit"
              className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600"
            >
              Add Provider
            </button>
          </div>
        </form>
      </div>
    </Layout>
  );
};

export default AddProvider;