import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { doc, getDoc, deleteDoc } from 'firebase/firestore';
import { db } from '../firebase';
import Layout from './Layout';

const SubscriberDetails = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [subscriber, setSubscriber] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchSubscriber = async () => {
      try {
        const docRef = doc(db, 'subscribers', id);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
          setSubscriber({ id: docSnap.id, ...docSnap.data() });
        } else {
          setError('Subscriber not found');
        }
      } catch (err) {
        setError('Error fetching subscriber details: ' + err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchSubscriber();
  }, [id]);

  const handleDelete = async () => {
    if (window.confirm('Are you sure you want to delete this subscriber?')) {
      try {
        await deleteDoc(doc(db, 'subscribers', id));
        navigate('/subscribers');
      } catch (err) {
        setError('Failed to delete subscriber: ' + err.message);
      }
    }
  };

  if (loading) return <Layout title="Subscriber Details">Loading...</Layout>;
  if (error) return <Layout title="Subscriber Details"><div className="text-red-500 text-left">{error}</div></Layout>;
  if (!subscriber) return <Layout title="Subscriber Details"><div className="text-left">Subscriber not found</div></Layout>;

  return (
    <Layout title="Subscriber Details">
      <div className="max-w-2xl mx-auto">
        <h1 className="text-2xl font-bold mb-4 text-left">Subscriber Details</h1>
        <div className="bg-white shadow overflow-hidden sm:rounded-lg">
          <div className="px-4 py-5 sm:px-6">
            <h3 className="text-lg leading-6 font-medium text-gray-900">
              {subscriber.name}
            </h3>
            <p className="mt-1 max-w-2xl text-sm text-gray-500">
              Subscriber personal details and subscription information.
            </p>
          </div>
          <div className="border-t border-gray-200">
            <dl>
              <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="text-sm font-medium text-gray-500">Email</dt>
                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{subscriber.email}</dd>
              </div>
              <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="text-sm font-medium text-gray-500">Subscription Type</dt>
                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{subscriber.subscriptionType}</dd>
              </div>
              <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="text-sm font-medium text-gray-500">Start Date</dt>
                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{subscriber.startDate}</dd>
              </div>
              <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="text-sm font-medium text-gray-500">End Date</dt>
                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{subscriber.endDate}</dd>
              </div>
            </dl>
          </div>
        </div>
        <div className="mt-4 flex justify-end space-x-2">
          <button
            onClick={() => navigate(`/edit-subscriber/${id}`)}
            className="px-4 py-2 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            Edit Subscriber
          </button>
          <button
            onClick={handleDelete}
            className="px-4 py-2 border border-transparent text-sm font-medium rounded-md text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
          >
            Delete Subscriber
          </button>
          <button
            onClick={() => navigate('/subscribers')}
            className="px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            Back to Subscribers
          </button>
        </div>
      </div>
    </Layout>
  );
};

export default SubscriberDetails;