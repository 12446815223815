import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { onAuthStateChanged } from 'firebase/auth';
import { auth } from './firebase';
import './App.css';
import './index.css';

// Import components
import Register from './components/Register';
import Login from './components/Login';
import Dashboard from './components/Dashboard';
import AdminPanel from './components/AdminPanel';
import MedicalEntityRegistration from './components/MedicalEntityRegistration';
import Admins from './components/Admins';
import AddAdmin from './components/AddAdmin';
import Appointments from './components/Appointments';
import AddAppointment from './components/AddAppointment';
import EditAppointment from './components/EditAppointment';
import AppointmentDetails from './components/AppointmentDetails';
import Clinics from './components/Clinics';
import AddClinic from './components/AddClinic';
import ClinicDetails from './components/ClinicDetails';
import EditClinic from './components/EditClinic';
import DentalOffices from './components/DentalOffices';
import AddDentalOffice from './components/AddDentalOffice';
import DentalOfficeDetails from './components/DentalOfficeDetails';
import EditDentalOffice from './components/EditDentalOffice';
import Documents from './components/Documents';
import AddDocument from './components/AddDocument';
import EditDocument from './components/EditDocument';
import DocumentDetails from './components/DocumentDetails';
import Hospitals from './components/Hospitals';
import AddHospital from './components/AddHospital';
import HospitalDetails from './components/HospitalDetails';
import EditHospital from './components/EditHospital';
import Insurance from './components/Insurance';
import AddInsurance from './components/AddInsurance';
import InsuranceDetails from './components/InsuranceDetails';
import EditInsurance from './components/EditInsurance';
import Labs from './components/Labs';
import AddLab from './components/AddLab';
import LabDetails from './components/LabDetails';
import EditLab from './components/EditLab';
// import AuditTrail from './components/AuditTrail';
import Patients from './components/Patients';
import AddPatientEMR from './components/AddPatientEMR';
import PatientEMRDetails from './components/PatientEMRDetails';
import EditPatientEMR from './components/EditPatientEMR';
import Pharmacies from './components/Pharmacies';
import AddPharmacy from './components/AddPharmacy';
import PharmacyDetails from './components/PharmacyDetails';
import EditPharmacy from './components/EditPharmacy';
import Providers from './components/Providers';
import AddProvider from './components/AddProvider';
import ProviderDetails from './components/ProviderDetails';
import EditProvider from './components/EditProvider';
import Subscribers from './components/Subscribers';
import AddSubscriber from './components/AddSubscriber';
import SubscriberDetails from './components/SubscriberDetails';
import EditSubscriber from './components/EditSubscriber';
import AiBot from './components/aiBot';
// import PlaceholderPage from './components/PlaceholderPage';

function App() {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setUser(user);
      setLoading(false);
    });

    return () => unsubscribe();
  }, []);

  const ProtectedRoute = ({ children }) => {
    if (loading) return <div>Loading...</div>;
    return user ? children : <Navigate to="/login" />;
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <Router>
      <div className="App">
        <Routes>
          {/* Public routes */}
          <Route path="/register" element={user ? <Navigate to="/dashboard" /> : <Register />} />
          <Route path="/login" element={user ? <Navigate to="/dashboard" /> : <Login />} />

          {/* Protected routes */}
          <Route path="/dashboard" element={<ProtectedRoute><Dashboard /></ProtectedRoute>} />
          <Route path="/admin" element={<ProtectedRoute><AdminPanel /></ProtectedRoute>} />
          <Route path="/register-entity" element={<ProtectedRoute><MedicalEntityRegistration /></ProtectedRoute>} />

          {/* AI Bot routes */}
          <Route path="/ai" element={<ProtectedRoute><AiBot /></ProtectedRoute>} />
          
          {/* Admin routes */}
          <Route path="/admins" element={<ProtectedRoute><Admins /></ProtectedRoute>} />
          <Route path="/add-admin" element={<ProtectedRoute><AddAdmin /></ProtectedRoute>} />

          {/* Appointment routes */}
          <Route path="/appointments" element={<Appointments />} />
          <Route path="/add-appointment" element={<AddAppointment />} />
          <Route path="/edit-appointment/:id" element={<EditAppointment />} />
          <Route path="/appointments/:id" element={<AppointmentDetails />} />
          
          {/* Clinic routes */}
          <Route path="/clinics" element={<ProtectedRoute><Clinics /></ProtectedRoute>} />
          <Route path="/add-clinic" element={<ProtectedRoute><AddClinic /></ProtectedRoute>} />
          <Route path="/clinics/:slug" element={<ProtectedRoute><ClinicDetails /></ProtectedRoute>} />
          <Route path="/edit-clinic/:id" element={<EditClinic />} />

          {/* Dental routes */}
          <Route path="/dental-offices" element={<DentalOffices />} />
          <Route path="/add-dental-office" element={<AddDentalOffice />} />
          <Route path="/dental-offices/:slug" element={<DentalOfficeDetails />} />
          <Route path="/edit-dental-office/:id" element={<EditDentalOffice />} />

          {/* Document Routes */}
          <Route path="/documents" element={<Documents />} />
          <Route path="/add-document" element={<AddDocument />} />
          <Route path="/edit-document/:id" element={<EditDocument />} />
          <Route path="/documents/:id" element={<DocumentDetails />} />

          {/* Hospital Routes */}
          <Route path="/hospitals" element={<Hospitals />} />
          <Route path="/add-hospital" element={<AddHospital />} />
          <Route path="/hospitals/:slug" element={<HospitalDetails />} />
          <Route path="/edit-hospital/:id" element={<EditHospital />} />

          {/* Insurance Routes */}
          <Route path="/insurance" element={<Insurance />} />
          <Route path="/add-insurance" element={<AddInsurance />} />
          <Route path="/insurance/:slug" element={<InsuranceDetails />} />
          <Route path="/edit-insurance/:id" element={<EditInsurance />} />

          {/* Lab Routes */}
          <Route path="/labs" element={<Labs />} />
          <Route path="/add-lab" element={<AddLab />} />
          <Route path="/labs/:slug" element={<LabDetails />} />
          <Route path="/edit-lab/:id" element={<EditLab />} />
        
          {/* Audit Trail / Logs Route */}
          {/* <Route path="/audit-trail" element={<AuditTrail />} /> */}
          
          {/* Patient Routes */}
          <Route path="/patients" element={<Patients />} />
          <Route path="/add-patient" element={<AddPatientEMR />} />
          <Route path="/patients/:id" element={<PatientEMRDetails />} />
          <Route path="/edit-patient/:id" element={<EditPatientEMR />} />
          
          {/* Pharmacy Routes */}
          <Route path="/pharmacies" element={<Pharmacies />} />
          <Route path="/add-pharmacy" element={<AddPharmacy />} />
          <Route path="/pharmacies/:slug" element={<PharmacyDetails />} />
          <Route path="/edit-pharmacy/:id" element={<EditPharmacy />} />
          
          {/* Provider Routes */}
          <Route path="/providers" element={<Providers />} />
          <Route path="/add-provider" element={<AddProvider />} />
          <Route path="/providers/:slug" element={<ProviderDetails />} />
          <Route path="/edit-provider/:id" element={<EditProvider />} />
          
          {/* Subscriber Routes */}
          <Route path="/subscribers" element={<Subscribers />} />
          <Route path="/add-subscriber" element={<AddSubscriber />} />
          <Route path="/subscribers/:id" element={<SubscriberDetails />} />
          <Route path="/edit-subscriber/:id" element={<EditSubscriber />} />
            
          {/* Placeholder routes for other sections */}
          {/* <Route path="/appointments" element={<ProtectedRoute><PlaceholderPage title="Appointments" /></ProtectedRoute>} /> */}
          {/* <Route path="/dental-offices" element={<ProtectedRoute><PlaceholderPage title="Dental Offices" /></ProtectedRoute>} /> */}
          {/* <Route path="/documents" element={<ProtectedRoute><PlaceholderPage title="Documents" /></ProtectedRoute>} />
          <Route path="/hospitals" element={<ProtectedRoute><PlaceholderPage title="Hospitals" /></ProtectedRoute>} />
          <Route path="/insurances" element={<ProtectedRoute><PlaceholderPage title="Insurances" /></ProtectedRoute>} /> */}
          {/* <Route path="/labs" element={<ProtectedRoute><PlaceholderPage title="Labs" /></ProtectedRoute>} />
          <Route path="/logs" element={<ProtectedRoute><PlaceholderPage title="Logs" /></ProtectedRoute>} />
          <Route path="/patients" element={<ProtectedRoute><PlaceholderPage title="Patients" /></ProtectedRoute>} />
          <Route path="/pharmacies" element={<ProtectedRoute><PlaceholderPage title="Pharmacies" /></ProtectedRoute>} />
          <Route path="/providers" element={<ProtectedRoute><PlaceholderPage title="Providers" /></ProtectedRoute>} />
          <Route path="/subscribers" element={<ProtectedRoute><PlaceholderPage title="Subscribers" /></ProtectedRoute>} /> */}

          {/* Default route */}
          <Route path="/" element={<Navigate to={user ? "/dashboard" : "/login"} />} />
          
          {/* Catch-all route for undefined paths */}
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;