import React, { useState, useEffect, useCallback } from 'react';
import { collection, getDocs, doc, deleteDoc } from 'firebase/firestore';
import { db } from '../firebase';
import { Link } from 'react-router-dom';
import Layout from './Layout';
import { CSVLink } from 'react-csv';
import * as XLSX from 'xlsx';

const Appointments = () => {
  const [appointments, setAppointments] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [sortBy, setSortBy] = useState('dateAsc');
  const [filterCountry, setFilterCountry] = useState('');
  const [filterDoctor, setFilterDoctor] = useState('');
  const [filterDay, setFilterDay] = useState('');
  const [doctors, setDoctors] = useState([]);
  const [refreshTrigger, setRefreshTrigger] = useState(0);

  const fetchAppointments = useCallback(async () => {
    setLoading(true);
    setError(null);
    try {
      const querySnapshot = await getDocs(collection(db, 'appointments'));
      const appointmentList = querySnapshot.docs.map(doc => {
        const data = doc.data();
        let appointmentDate;
        if (data.date && typeof data.date.toDate === 'function') {
          appointmentDate = data.date.toDate();
        } else if (data.date && data.date instanceof Date) {
          appointmentDate = data.date;
        } else if (data.date && typeof data.date === 'string') {
          appointmentDate = new Date(data.date);
        } else {
          console.warn(`Invalid date format for appointment ${doc.id}. Using current date.`);
          appointmentDate = new Date();
        }
        // Convert UTC date to local date
        const localDate = new Date(appointmentDate.getTime() + appointmentDate.getTimezoneOffset() * 60000);
        return {
          id: doc.id,
          ...data,
          date: localDate
        };
      });
      setAppointments(appointmentList);
    } catch (err) {
      console.error("Error fetching appointments: ", err);
      setError("Failed to fetch appointments. Please try again.");
    } finally {
      setLoading(false);
    }
  }, []);

  const fetchDoctors = useCallback(async () => {
    try {
      const querySnapshot = await getDocs(collection(db, 'providers'));
      const doctorList = querySnapshot.docs.map(doc => ({
        id: doc.id,
        name: doc.data().name
      }));
      setDoctors(doctorList);
    } catch (err) {
      console.error("Error fetching doctors: ", err);
      setError("Failed to fetch doctors. Please try again.");
    }
  }, []);

  useEffect(() => {
    fetchAppointments();
    fetchDoctors();
  }, [fetchAppointments, fetchDoctors, refreshTrigger]);

  const handleDeleteAppointment = async (id) => {
    if (window.confirm('Are you sure you want to delete this appointment?')) {
      try {
        await deleteDoc(doc(db, 'appointments', id));
        setRefreshTrigger(prev => prev + 1); // Trigger a refresh
      } catch (err) {
        console.error("Error deleting appointment: ", err);
        setError("Failed to delete appointment. Please try again.");
      }
    }
  };

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleSortChange = (e) => {
    setSortBy(e.target.value);
  };

  const handleCountryFilterChange = (e) => {
    setFilterCountry(e.target.value);
  };

  const handleDoctorFilterChange = (e) => {
    setFilterDoctor(e.target.value);
  };

  const handleDayFilterChange = (e) => {
    setFilterDay(e.target.value);
  };

  const formatDate = (date) => {
    const options = { year: 'numeric', month: 'short', day: 'numeric' };
    return date.toLocaleDateString('en-US', options);
  };

  const formatTime = (time) => {
    if (!time) return 'N/A';
    const [hours, minutes] = time.split(':');
    const hour = parseInt(hours, 10);
    const ampm = hour >= 12 ? 'PM' : 'AM';
    const formattedHour = hour % 12 || 12;
    return `${formattedHour}:${minutes} ${ampm}`;
  };

  const filteredAndSortedAppointments = appointments
    .filter(appointment => 
      (appointment.patientName?.toLowerCase().includes(searchTerm.toLowerCase()) ||
       appointment.doctorName?.toLowerCase().includes(searchTerm.toLowerCase())) &&
      (filterCountry === '' || appointment.country?.toLowerCase() === filterCountry.toLowerCase()) &&
      (filterDoctor === '' || appointment.doctorId === filterDoctor) &&
      (filterDay === '' || appointment.date.toLocaleDateString('en-US', { weekday: 'long' }) === filterDay)
    )
    .sort((a, b) => {
      switch (sortBy) {
        case 'dateAsc':
          return a.date - b.date;
        case 'dateDesc':
          return b.date - a.date;
        case 'patientNameAZ':
          return (a.patientName || '').localeCompare(b.patientName || '');
        case 'patientNameZA':
          return (b.patientName || '').localeCompare(a.patientName || '');
        case 'doctorNameAZ':
          return (a.doctorName || '').localeCompare(b.doctorName || '');
        case 'doctorNameZA':
          return (b.doctorName || '').localeCompare(a.doctorName || '');
        default:
          return 0;
      }
    });

  const exportData = filteredAndSortedAppointments.map(appointment => ({
    'Patient Name': appointment.patientName || 'N/A',
    'Doctor Name': appointment.doctorName || 'N/A',
    'Date': formatDate(appointment.date),
    'Time': formatTime(appointment.time),
    'Type': appointment.type || 'N/A',
    'Status': appointment.status || 'N/A'
  }));

  const exportToExcel = () => {
    const ws = XLSX.utils.json_to_sheet(exportData);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Appointments");
    XLSX.writeFile(wb, "appointments.xlsx");
  };

  if (loading) return <Layout title="Appointments">Loading appointments data...</Layout>;
  if (error) return <Layout title="Appointments"><div className="text-red-500 text-left">{error}</div></Layout>;

  return (
    <Layout title="Appointments">
      <div className="mb-4 flex justify-between items-center">
        <h1 className="text-2xl font-bold text-left">Appointments</h1>
        <Link to="/add-appointment" className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
          Add New Appointment
        </Link>
      </div>

      <p className="text-left mb-4">{filteredAndSortedAppointments.length} appointments</p>

      <div className="mb-4 flex flex-wrap gap-2">
        <input
          type="text"
          placeholder="Search appointments..."
          value={searchTerm}
          onChange={handleSearchChange}
          className="p-2 border rounded w-full sm:w-64"
        />
        <select
          value={sortBy}
          onChange={handleSortChange}
          className="p-2 border rounded"
        >
          <option value="dateAsc">Date (Ascending)</option>
          <option value="dateDesc">Date (Descending)</option>
          <option value="patientNameAZ">Patient Name (A-Z)</option>
          <option value="patientNameZA">Patient Name (Z-A)</option>
          <option value="doctorNameAZ">Doctor Name (A-Z)</option>
          <option value="doctorNameZA">Doctor Name (Z-A)</option>
        </select>
        <input
          type="text"
          placeholder="Filter by country"
          value={filterCountry}
          onChange={handleCountryFilterChange}
          className="p-2 border rounded w-full sm:w-48"
        />
        <select
          value={filterDoctor}
          onChange={handleDoctorFilterChange}
          className="p-2 border rounded"
        >
          <option value="">All Doctors</option>
          {doctors.map(doctor => (
            <option key={doctor.id} value={doctor.id}>{doctor.name}</option>
          ))}
        </select>
        <select
          value={filterDay}
          onChange={handleDayFilterChange}
          className="p-2 border rounded"
        >
          <option value="">All Days</option>
          <option value="Monday">Monday</option>
          <option value="Tuesday">Tuesday</option>
          <option value="Wednesday">Wednesday</option>
          <option value="Thursday">Thursday</option>
          <option value="Friday">Friday</option>
          <option value="Saturday">Saturday</option>
          <option value="Sunday">Sunday</option>
        </select>
        <button
          onClick={exportToExcel}
          className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded"
        >
          Export to Excel
        </button>
        <CSVLink
          data={exportData}
          filename={"appointments.csv"}
          className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
        >
          Export to CSV
        </CSVLink>
      </div>

      {filteredAndSortedAppointments.length === 0 ? (
        <p className="text-left">No appointments found.</p>
      ) : (
        <div className="overflow-x-auto">
          <table className="min-w-full table-auto">
            <thead className="bg-gray-200">
              <tr>
                <th className="px-4 py-2 text-left">Patient Name</th>
                <th className="px-4 py-2 text-left">Doctor Name</th>
                <th className="px-4 py-2 text-left">Date</th>
                <th className="px-4 py-2 text-left">Time</th>
                <th className="px-4 py-2 text-left">Type</th>
                <th className="px-4 py-2 text-left">Status</th>
                <th className="px-4 py-2 text-left">Actions</th>
              </tr>
            </thead>
            <tbody>
              {filteredAndSortedAppointments.map(appointment => (
                <tr key={appointment.id} className="bg-white border-b">
                  <td className="px-4 py-2 text-left">{appointment.patientName || 'N/A'}</td>
                  <td className="px-4 py-2 text-left">{appointment.doctorName || 'N/A'}</td>
                  <td className="px-4 py-2 text-left">{formatDate(appointment.date)}</td>
                  <td className="px-4 py-2 text-left">{formatTime(appointment.time)}</td>
                  <td className="px-4 py-2 text-left">{appointment.type}</td>
                  <td className="px-4 py-2 text-left">{appointment.status}</td>
                  <td className="px-4 py-2 text-left">
                    <Link to={`/appointments/${appointment.id}`} className="text-blue-600 hover:text-blue-900 mr-2">
                      View
                    </Link>
                    <Link to={`/edit-appointment/${appointment.id}`} className="text-indigo-600 hover:text-indigo-900 mr-2">
                      Edit
                    </Link>
                    <button
                      onClick={() => handleDeleteAppointment(appointment.id)}
                      className="text-red-600 hover:text-red-900"
                    >
                      Delete
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </Layout>
  );
};

export default Appointments;