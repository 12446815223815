import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { 
  LayoutDashboard, Calendar, Hospital, Briefcase, FileText, Building, 
  Shield, TestTube, User, PlusSquare, UserPlus, Bell, 
  Brain
} from 'lucide-react';

const Sidebar = () => {
  const location = useLocation();

  const menuItems = [
    { name: 'Dashboard', icon: LayoutDashboard, path: '/dashboard' },
    { name: 'Ai Bot Interaction', icon: Brain, path: '/ai' },
    { name: 'Admins', icon: Shield, path: '/admins' },
    { name: 'Appointments', icon: Calendar, path: '/appointments' },
    { name: 'Clinics', icon: Hospital, path: '/clinics' },
    { name: 'Dental Offices', icon: Briefcase, path: '/dental-offices' },
    { name: 'Documents', icon: FileText, path: '/documents' },
    { name: 'Hospitals', icon: Building, path: '/hospitals' },
    { name: 'Insurance', icon: PlusSquare, path: '/insurance' },
    { name: 'Labs', icon: TestTube, path: '/labs' },
    // { name: 'Audit Trail & Logs', icon: List, path: '/audit-trail' },
    { name: 'Patients', icon: User, path: '/patients' },
    { name: 'Pharmacies', icon: PlusSquare, path: '/pharmacies' },
    { name: 'Providers', icon: UserPlus, path: '/providers' },
    { name: 'Subscribers', icon: Bell, path: '/subscribers' },
  ];

  return (
    <div className="bg-gray-800 text-white w-64 space-y-6 py-7 px-2 absolute inset-y-0 left-0 transform -translate-x-full md:relative md:translate-x-0 transition duration-200 ease-in-out">
      <nav className="space-y-2 overflow-y-auto h-full">
        {menuItems.map((item) => (
          <Link
            key={item.path}
            to={item.path}
            className={`block py-2.5 px-4 rounded transition duration-200 ${
              location.pathname === item.path ? 'bg-blue-500' : 'hover:bg-gray-700'
            }`}
          >
            <div className="flex items-center">
              <item.icon className="mr-3 h-6 w-6" />
              {item.name}
            </div>
          </Link>
        ))}
      </nav>
    </div>
  );
};

export default Sidebar;