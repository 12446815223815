import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { doc, getDoc } from 'firebase/firestore';
import { db } from '../firebase';
import Layout from './Layout';
import { Page, Text, View, Document, StyleSheet, PDFDownloadLink } from '@react-pdf/renderer';
import { QRCodeSVG } from 'qrcode.react';
import CryptoJS from 'crypto-js';
import { Font } from '@react-pdf/renderer';
import RobotoRegular from './Roboto-Regular.ttf';

Font.register({
  family: 'Roboto',
  src: RobotoRegular,
});

// Helper function to format Firestore Timestamps
const formatTimestamp = (timestamp) => {
  if (timestamp && timestamp.seconds) {
    return new Date(timestamp.seconds * 1000).toLocaleString();
  }
  return 'N/A';
};

// Styles for PDF
const styles = StyleSheet.create({
  page: {
    flexDirection: 'column',
    backgroundColor: '#ffffff',
    padding: 30,
    fontFamily: 'Roboto',
  },
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1,
  },
  title: {
    fontSize: 16,
    marginBottom: 10,
    fontWeight: 'bold',
    color: '#2c3e50',
  },
  subtitle: {
    fontSize: 18,
    marginBottom: 10,
    marginTop: 20,
    fontWeight: 'bold',
    color: '#34495e',
    borderBottom: '1 solid #bdc3c7',
    paddingBottom: 5,
  },
  text: {
    fontSize: 12,
    marginBottom: 5,
    color: '#2c3e50',
  },
  table: {
    display: 'table',
    width: 'auto',
    borderStyle: 'solid',
    borderWidth: 1,
    borderColor: '#bdc3c7',
    marginBottom: 10,
  },
  tableRow: {
    flexDirection: 'row',
  },
  tableHeader: {
    backgroundColor: '#ecf0f1',
  },
  tableCell: {
    width: '33%',
    borderWidth: 1,
    borderColor: '#bdc3c7',
    padding: 5,
  },
});

// PDF Document component
const PatientPDF = ({ patient }) => (
  <Document>
    <Page size="A4" style={styles.page}>
      <Text style={styles.title}>Patient EMR - {patient.demographics?.name || 'Unknown'}</Text>
      
      {/* Demographics */}
      <View style={styles.section}>
        <Text style={styles.subtitle}>Demographics</Text>
        <Text style={styles.text}>Name: {patient.demographics?.name || 'N/A'}</Text>
        <Text style={styles.text}>Date of Birth: {formatTimestamp(patient.demographics?.dateOfBirth) || 'N/A'}</Text>
        <Text style={styles.text}>Gender: {patient.demographics?.gender || 'N/A'}</Text>
        <Text style={styles.text}>Contact: {patient.demographics?.contact || 'N/A'}</Text>
      </View>

      {/* Medical History */}
      <View style={styles.section}>
        <Text style={styles.subtitle}>Medical History</Text>
        {patient.medicalHistory && patient.medicalHistory.length > 0 ? (
          patient.medicalHistory.map((item, index) => (
            <Text key={index} style={styles.text}>• {item}</Text>
          ))
        ) : (
          <Text style={styles.text}>No medical history recorded.</Text>
        )}
      </View>

      {/* Medications */}
      <View style={styles.section}>
        <Text style={styles.subtitle}>Current Medications</Text>
        {patient.medications && patient.medications.length > 0 ? (
          patient.medications.map((med, index) => (
            <Text key={index} style={styles.text}>• {med}</Text>
          ))
        ) : (
          <Text style={styles.text}>No medications recorded.</Text>
        )}
      </View>

      {/* Lab Results */}
      <View style={styles.section}>
        <Text style={styles.subtitle}>Lab Results</Text>
        {patient.labResults && patient.labResults.length > 0 ? (
          <View style={styles.table}>
            <View style={[styles.tableRow, styles.tableHeader]}>
              <View style={styles.tableCell}><Text>Test</Text></View>
              <View style={styles.tableCell}><Text>Result</Text></View>
              <View style={styles.tableCell}><Text>Date</Text></View>
            </View>
            {patient.labResults.map((result, index) => (
              <View key={index} style={styles.tableRow}>
                <View style={styles.tableCell}><Text>{result.test}</Text></View>
                <View style={styles.tableCell}><Text>{result.result}</Text></View>
                <View style={styles.tableCell}><Text>{formatTimestamp(result.date)}</Text></View>
              </View>
            ))}
          </View>
        ) : (
          <Text style={styles.text}>No lab results recorded.</Text>
        )}
      </View>

      {/* Imaging Studies */}
      <View style={styles.section}>
        <Text style={styles.subtitle}>Imaging Studies</Text>
        {patient.imagingStudies && patient.imagingStudies.length > 0 ? (
          patient.imagingStudies.map((study, index) => (
            <Text key={index} style={styles.text}>• {study}</Text>
          ))
        ) : (
          <Text style={styles.text}>No imaging studies recorded.</Text>
        )}
      </View>

      {/* Clinical Notes */}
      <View style={styles.section}>
        <Text style={styles.subtitle}>Clinical Notes</Text>
        {patient.clinicalNotes && patient.clinicalNotes.length > 0 ? (
          patient.clinicalNotes.map((note, index) => (
            <View key={index} style={styles.subsection}>
              <Text style={styles.text}>{formatTimestamp(note.date)} - {note.doctor}</Text>
              <Text style={styles.text}>{note.content}</Text>
            </View>
          ))
        ) : (
          <Text style={styles.text}>No clinical notes recorded.</Text>
        )}
      </View>

      {/* Support Plan Goals */}
      <View style={styles.section}>
        <Text style={styles.subtitle}>Support Plan Goals</Text>
        {patient.supportPlanGoals && patient.supportPlanGoals.length > 0 ? (
          patient.supportPlanGoals.map((goal, index) => (
            <View key={index} style={styles.subsection}>
              <Text style={styles.text}>Goal: {goal.description}</Text>
              <Text style={styles.text}>Target Date: {formatTimestamp(goal.targetDate)}</Text>
              <Text style={styles.text}>Status: {goal.status}</Text>
              <Text style={styles.text}>Notes: {goal.notes}</Text>
            </View>
          ))
        ) : (
          <Text style={styles.text}>No support plan goals recorded.</Text>
        )}
      </View>

      {/* Behavior */}
      <View style={styles.section}>
        <Text style={styles.subtitle}>Behavior</Text>
        {patient.behaviorIncidents && patient.behaviorIncidents.length > 0 ? (
          patient.behaviorIncidents.map((incident, index) => (
            <View key={index} style={styles.subsection}>
              <Text style={styles.text}>Date: {formatTimestamp(incident.date)}</Text>
              <Text style={styles.text}>Description: {incident.description}</Text>
              <Text style={styles.text}>Severity: {incident.severity}</Text>
              <Text style={styles.text}>Intervention: {incident.intervention}</Text>
            </View>
          ))
        ) : (
          <Text style={styles.text}>No behavior incidents recorded.</Text>
        )}
      </View>

      {/* Medical Adherence */}
      <View style={styles.section}>
        <Text style={styles.subtitle}>Medical Adherence</Text>
        {patient.medicalAdherence ? (
          <View>
            <Text style={styles.text}>Medication Compliance: {patient.medicalAdherence.medicationCompliance}</Text>
            <Text style={styles.text}>Appointment Attendance: {patient.medicalAdherence.appointmentAttendance}</Text>
            <Text style={styles.text}>Treatment Plan Adherence: {patient.medicalAdherence.treatmentPlanAdherence}</Text>
            <Text style={styles.text}>Notes: {patient.medicalAdherence.notes}</Text>
          </View>
        ) : (
          <Text style={styles.text}>No medical adherence information recorded.</Text>
        )}
      </View>

      {/* Transfers */}
      <View style={styles.section}>
        <Text style={styles.subtitle}>Transfers</Text>
        {patient.transfers && patient.transfers.length > 0 ? (
          patient.transfers.map((transfer, index) => (
            <View key={index} style={styles.subsection}>
              <Text style={styles.text}>Date: {formatTimestamp(transfer.date)}</Text>
              <Text style={styles.text}>From: {transfer.from}</Text>
              <Text style={styles.text}>To: {transfer.to}</Text>
              <Text style={styles.text}>Reason: {transfer.reason}</Text>
              <Text style={styles.text}>Accompanying Staff: {transfer.staff}</Text>
            </View>
          ))
        ) : (
          <Text style={styles.text}>No transfers recorded.</Text>
        )}
      </View>

      {/* Assigned Staff */}
      <View style={styles.section}>
        <Text style={styles.subtitle}>Assigned Staff</Text>
        {patient.assignedStaff && patient.assignedStaff.length > 0 ? (
          patient.assignedStaff.map((staff, index) => (
            <View key={index} style={styles.subsection}>
              <Text style={styles.text}>Name: {staff.name}</Text>
              <Text style={styles.text}>Role: {staff.role}</Text>
              <Text style={styles.text}>Contact: {staff.contact}</Text>
              <Text style={styles.text}>Schedule: {staff.schedule}</Text>
            </View>
          ))
        ) : (
          <Text style={styles.text}>No assigned staff recorded.</Text>
        )}
      </View>

      {/* Community Activity Logs */}
      <View style={styles.section}>
        <Text style={styles.subtitle}>Community Activity Logs</Text>
        {patient.communityActivityLogs && patient.communityActivityLogs.length > 0 ? (
          patient.communityActivityLogs.map((activity, index) => (
            <View key={index} style={styles.subsection}>
              <Text style={styles.text}>Date: {formatTimestamp(activity.date)}</Text>
              <Text style={styles.text}>Activity: {activity.type}</Text>
              <Text style={styles.text}>Duration: {activity.duration}</Text>
              <Text style={styles.text}>Location: {activity.location}</Text>
              <Text style={styles.text}>Staff: {activity.staff}</Text>
              <Text style={styles.text}>Notes: {activity.notes}</Text>
            </View>
          ))
        ) : (
          <Text style={styles.text}>No community activities recorded.</Text>
        )}
      </View>

      {/* Inventory */}
      <View style={styles.section}>
        <Text style={styles.subtitle}>Inventory</Text>
        {patient.inventory ? (
          <View>
            <Text style={styles.text}>Last Inventory Date: {formatTimestamp(patient.inventory.lastInventoryDate)}</Text>
            <Text style={styles.subsectionTitle}>Personal Belongings</Text>
            {patient.inventory.personalBelongings.map((item, index) => (
              <Text key={index} style={styles.text}>• {item.name} - Condition: {item.condition}</Text>
            ))}
            <Text style={styles.subsectionTitle}>Medical Equipment</Text>
            {patient.inventory.medicalEquipment.map((item, index) => (
              <Text key={index} style={styles.text}>• {item.name} - Condition: {item.condition}</Text>
            ))}
          </View>
        ) : (
          <Text style={styles.text}>No inventory recorded.</Text>
        )}
      </View>

      {/* Significant Events */}
      <View style={styles.section}>
        <Text style={styles.subtitle}>Significant Events</Text>
        {patient.significantEvents && patient.significantEvents.length > 0 ? (
          patient.significantEvents.map((event, index) => (
            <View key={index} style={styles.subsection}>
              <Text style={styles.text}>Date: {formatTimestamp(event.date)}</Text>
              <Text style={styles.text}>Type: {event.type}</Text>
              <Text style={styles.text}>Description: {event.description}</Text>
              <Text style={styles.text}>Staff Involved: {event.staffInvolved}</Text>
              <Text style={styles.text}>Follow-up: {event.followUp}</Text>
            </View>
          ))
        ) : (
          <Text style={styles.text}>No significant events recorded.</Text>
        )}
      </View>

      {/* Medical Contacts */}
      <View style={styles.section}>
        <Text style={styles.subtitle}>Medical Contacts</Text>
        {patient.medicalContacts && patient.medicalContacts.length > 0 ? (
          patient.medicalContacts.map((contact, index) => (
            <View key={index} style={styles.subsection}>
              <Text style={styles.text}>Name: {contact.name}</Text>
              <Text style={styles.text}>Specialty: {contact.specialty}</Text>
              <Text style={styles.text}>Contact: {contact.contact}</Text>
              <Text style={styles.text}>Last Appointment: {formatTimestamp(contact.lastAppointment)}</Text>
              <Text style={styles.text}>Next Appointment: {formatTimestamp(contact.nextAppointment)}</Text>
            </View>
          ))
        ) : (
          <Text style={styles.text}>No medical contacts recorded.</Text>
        )}
      </View>

      {/* Facesheet */}
      <View style={styles.section}>
        <Text style={styles.subtitle}>Facesheet</Text>
        {patient.facesheet ? (
          <View>
            <Text style={styles.text}>Law Enforcement: {patient.facesheet.lawEnforcement}</Text>
            <Text style={styles.text}>Paramedics: {patient.facesheet.paramedics}</Text>
            <Text style={styles.text}>Support Coordinators: {patient.facesheet.supportCoordinators}</Text>
            <Text style={styles.text}>Hospital: {patient.facesheet.hospital}</Text>
            <Text style={styles.subsectionTitle}>Family Members</Text>
            {patient.facesheet.familyMembers.map((member, index) => (
              <Text key={index} style={styles.text}>• {member.name} - {member.relationship} - {member.contact}</Text>
            ))}
            <Text style={styles.text}>APD: {patient.facesheet.apd}</Text>
          </View>
        ) : (
          <Text style={styles.text}>No facesheet information recorded.</Text>
        )}
      </View>

      {/* Income and Expenses */}
      <View style={styles.section}>
        <Text style={styles.subtitle}>Income and Expenses</Text>
        {patient.incomeAndExpenses ? (
          <View>
            <Text style={styles.subsectionTitle}>Income Sources</Text>
            {patient.incomeAndExpenses.incomeSources.map((source, index) => (
              <Text key={index} style={styles.text}>• {source.name}: ${source.amount}</Text>
            ))}
            <Text style={styles.text}>Total Monthly Income: ${patient.incomeAndExpenses.totalMonthlyIncome}</Text>
            
            <Text style={styles.subsectionTitle}>Fixed Expenses</Text>
            {patient.incomeAndExpenses.fixedExpenses.map((expense, index) => (
              <Text key={index} style={styles.text}>• {expense.name}: ${expense.amount}</Text>
            ))}
            
            <Text style={styles.subsectionTitle}>Variable Expenses</Text>
            {patient.incomeAndExpenses.variableExpenses.map((expense, index) => (
              <Text key={index} style={styles.text}>• {expense.name}: ${expense.amount}</Text>
            ))}
            
            <Text style={styles.text}>Total Monthly Expenses: ${patient.incomeAndExpenses.totalMonthlyExpenses}</Text>
            <Text style={styles.text}>Monthly Savings: ${patient.incomeAndExpenses.monthlySavings}</Text>
          </View>
        ) : (
          <Text style={styles.text}>No income and expenses information recorded.</Text>
        )}
      </View>

      {/* Logs */}
      <View style={styles.section}>
        <Text style={styles.subtitle}>Logs</Text>
        {patient.logs ? (
          <View>
            <Text style={styles.subsectionTitle}>Rehab Logs</Text>
            {patient.logs.rehabLogs.length > 0 ? (
              patient.logs.rehabLogs.map((log, index) => (
                <View key={index} style={styles.subsection}>
                  <Text style={styles.text}>Date: {formatTimestamp(log.date)}</Text>
                  <Text style={styles.text}>Activity: {log.activity}</Text>
                  <Text style={styles.text}>Progress: {log.progress}</Text>
                </View>
              ))
            ) : (
              <Text style={styles.text}>No rehab logs recorded.</Text>
            )}

            <Text style={styles.subsectionTitle}>Respite Logs</Text>
            {patient.logs.respiteLogs.length > 0 ? (
              patient.logs.respiteLogs.map((log, index) => (
                <View key={index} style={styles.subsection}>
                  <Text style={styles.text}>Date: {formatTimestamp(log.date)}</Text>
                  <Text style={styles.text}>Duration: {log.duration}</Text>
                  <Text style={styles.text}>Reason: {log.reason}</Text>
                </View>
              ))
            ) : (
              <Text style={styles.text}>No respite logs recorded.</Text>
            )}

            <Text style={styles.subsectionTitle}>Life Skills Logs</Text>
            {patient.logs.lifeSkillsLogs.length > 0 ? (
              patient.logs.lifeSkillsLogs.map((log, index) => (
                <View key={index} style={styles.subsection}>
                  <Text style={styles.text}>Date: {formatTimestamp(log.date)}</Text>
                  <Text style={styles.text}>Skill Practiced: {log.skillPracticed}</Text>
                  <Text style={styles.text}>Progress: {log.progress}</Text>
                </View>
              ))
            ) : (
              <Text style={styles.text}>No life skills logs recorded.</Text>
            )}

            <Text style={styles.subsectionTitle}>Personal Support Logs</Text>
            {patient.logs.personalSupportLogs.length > 0 ? (
              patient.logs.personalSupportLogs.map((log, index) => (
                <View key={index} style={styles.subsection}>
                  <Text style={styles.text}>Date: {formatTimestamp(log.date)}</Text>
                  <Text style={styles.text}>Type of Support: {log.supportType}</Text>
                  <Text style={styles.text}>Staff: {log.staff}</Text>
                </View>
              ))
            ) : (
              <Text style={styles.text}>No personal support logs recorded.</Text>
            )}

            <Text style={styles.subsectionTitle}>Seizure Logs</Text>
            {patient.logs.seizureLogs.length > 0 ? (
              patient.logs.seizureLogs.map((log, index) => (
                <View key={index} style={styles.subsection}>
                  <Text style={styles.text}>Date: {formatTimestamp(log.date)}</Text>
                  <Text style={styles.text}>Duration: {log.duration}</Text>
                  <Text style={styles.text}>Type: {log.type}</Text>
                  <Text style={styles.text}>Triggers: {log.triggers}</Text>
                </View>
              ))
            ) : (
              <Text style={styles.text}>No seizure logs recorded.</Text>
            )}
          </View>
        ) : (
          <Text style={styles.text}>No logs recorded.</Text>
        )}
      </View>
    </Page>
  </Document>
);

const PatientEMRDetails = () => {
  const [patient, setPatient] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [activeTab, setActiveTab] = useState('demographics');
  const { id } = useParams();
  const navigate = useNavigate();

  const [qrCodeData, setQRCodeData] = useState('');
  const [showQRCode, setShowQRCode] = useState(false);
  const [passkey, setPasskey] = useState('');
  const [generatedPassword, setGeneratedPassword] = useState('');

  // New state for PDF document
  const [pdfDocument, setPdfDocument] = useState(null);

  useEffect(() => {
    const fetchPatient = async () => {
      try {
        setLoading(true);
        const docRef = doc(db, 'patients', id);
        const docSnap = await getDoc(docRef);
  
        if (docSnap.exists()) {
          const patientData = { id: docSnap.id, ...docSnap.data() };
          setPatient(patientData);
        } else {
          setError('Patient not found');
        }
      } catch (err) {
        setError('Error fetching patient details: ' + err.message);
      } finally {
        setLoading(false);
      }
    };
  
    fetchPatient();
  }, [id]);
  
  useEffect(() => {
    if (patient) {
      try {
        const pdfDoc = <PatientPDF patient={patient} />;
        setPdfDocument(pdfDoc);
      } catch (error) {
        console.error("Error generating PDF:", error);
        setError("Failed to generate PDF. Please try again.");
      }
    }
  }, [patient]);

  const generateEncryptedQRCode = () => {
    if (!passkey) {
      alert("Please enter a passkey or generate a strong password.");
      return;
    }

    const essentialData = {
      id: patient.id,
      name: patient.demographics?.name,
      dateOfBirth: patient.demographics?.dateOfBirth,
    };

    const jsonData = JSON.stringify(essentialData);
    const encryptedData = CryptoJS.AES.encrypt(jsonData, passkey).toString();

    setQRCodeData(encryptedData);
  };

  const generateStrongPassword = () => {
    const charset = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789!@#$%^&*()_+~`|}{[]:;?><,./-=";
    let password = "";
    for (let i = 0; i < 16; i++) {
      password += charset.charAt(Math.floor(Math.random() * charset.length));
    }
    setGeneratedPassword(password);
    setPasskey(password);
  };

  const toggleQRCode = () => {
    setShowQRCode(!showQRCode);
    if (!showQRCode) {
      setQRCodeData('');
      setPasskey('');
      setGeneratedPassword('');
    }
  };

  if (loading) return <Layout title="Patient EMR Details">Loading...</Layout>;
  if (error) return <Layout title="Patient EMR Details">Error: {error}</Layout>;
  if (!patient) return <Layout title="Patient EMR Details">Patient not found</Layout>;

  const tabSections = [
    { id: 'demographics', title: 'Demographics', content: <DemographicsSection patient={patient} /> },
    { id: 'medicalHistory', title: 'Medical History', content: <MedicalHistorySection patient={patient} /> },
    { id: 'medications', title: 'Medications', content: <MedicationsSection patient={patient} /> },
    { id: 'labResults', title: 'Lab Results', content: <LabResultsSection patient={patient} /> },
    { id: 'imagingStudies', title: 'Imaging', content: <ImagingSection patient={patient} /> },
    { id: 'clinicalNotes', title: 'Notes', content: <NotesSection patient={patient} /> },
    { id: 'supportPlanGoals', title: 'Support Plan Goals', content: <SupportPlanGoalsSection patient={patient} /> },
    { id: 'behavior', title: 'Behavior', content: <BehaviorSection patient={patient} /> },
    { id: 'medicalAdherence', title: 'Medical Adherence', content: <MedicalAdherenceSection patient={patient} /> },
    { id: 'transfers', title: 'Transfers', content: <TransfersSection patient={patient} /> },
    { id: 'assignedStaff', title: 'Assigned Staff', content: <AssignedStaffSection patient={patient} /> },
    { id: 'communityActivityLogs', title: 'Community Activity Logs', content: <CommunityActivityLogsSection patient={patient} /> },
    { id: 'inventory', title: 'Inventory', content: <InventorySection patient={patient} /> },
    { id: 'significantEvents', title: 'Significant Events', content: <SignificantEventsSection patient={patient} /> },
    { id: 'medicalContacts', title: 'Medical Contacts', content: <MedicalContactsSection patient={patient} /> },
    { id: 'facesheet', title: 'Facesheet', content: <FacesheetSection patient={patient} /> },
    { id: 'incomeAndExpenses', title: 'Income and Expenses', content: <IncomeAndExpensesSection patient={patient} /> },
    { id: 'logs', title: 'Logs', content: <LogsSection patient={patient} /> },
  ];

  return (
    <Layout title="Patient EMR Details">
      <div className="container mx-auto p-4 text-left">
        <h1 className="text-2xl font-bold mb-4">Patient EMR - {patient.demographics?.name || 'Unknown'}</h1>
        
        <div className="mb-4 flex space-x-2">
          <button
            onClick={() => navigate(`/edit-patient/${id}`)}
            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
          >
            Edit Patient
          </button>
          <button
            onClick={() => navigate('/patients')}
            className="bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded"
          >
            Back to Patients
          </button>
          {pdfDocument && (
            <PDFDownloadLink
            document={pdfDocument}
            fileName={`${patient.demographics?.name || 'Unknown'}_EMR.pdf`}
            className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded"
          >
            {({ blob, url, loading, error }) =>
              loading ? 'Generating PDF...' : 'Download PDF'
            }
          </PDFDownloadLink>
          )}
          <button
            onClick={toggleQRCode}
            className="bg-purple-500 hover:bg-purple-700 text-white font-bold py-2 px-4 rounded"
          >
            Generate QR Code
          </button>
        </div>
        
        {showQRCode && (
          <div className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full flex justify-center items-center">
            <div className="bg-white p-5 rounded-lg shadow-xl max-w-md w-full">
              <h2 className="text-xl font-bold mb-4">Generate Encrypted QR Code</h2>
              
              <div className="mb-4">
                <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="passkey">
                  Enter Passkey:
                </label>
                <input
                  id="passkey"
                  type="password"
                  value={passkey}
                  onChange={(e) => setPasskey(e.target.value)}
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  placeholder="Enter your passkey"
                />
              </div>
              
              <div className="mb-4">
                  <button
                    onClick={generateStrongPassword}
                    className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded w-full"
                  >
                    Generate Strong Password
                  </button>
                  {generatedPassword && (
                    <p className="mt-2 text-sm text-gray-600">
                      Generated Password: <strong>{generatedPassword}</strong>
                      <br/>
                      (This password has been set as the passkey)
                    </p>
                  )}
                </div>
                
                <button
                  onClick={generateEncryptedQRCode}
                  className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded w-full mb-4"
                >
                  Generate QR Code
                </button>
                
                {qrCodeData && (
                  <div className="mt-4">
                    <QRCodeSVG value={qrCodeData} size={256} />
                    <p className="mt-4 text-sm text-gray-600">Scan this QR code to access the patient's EMR securely.</p>
                    <p className="mt-2 text-sm text-red-600 font-bold">
                      Important: Remember the passkey used to generate this QR code. It will be needed to decrypt the information.
                    </p>
                  </div>
                )}
                
                <button
                  onClick={toggleQRCode}
                  className="mt-4 bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded w-full"
                >
                  Close
                </button>
              </div>
            </div>
          )}

        <div className="mb-4 flex flex-wrap">
          {tabSections.map((section) => (
            <button
              key={section.id}
              onClick={() => setActiveTab(section.id)}
              className={`mr-2 mb-2 px-4 py-2 rounded ${
                activeTab === section.id
                  ? 'bg-blue-500 text-white'
                  : 'bg-gray-200 text-gray-700'
              }`}
            >
              {section.title}
            </button>
          ))}
        </div>
        <div className="bg-white p-4 rounded-lg shadow">
          {tabSections.find(section => section.id === activeTab)?.content}
        </div>
      </div>
    </Layout>
  );
};

// Section components
const DemographicsSection = ({ patient }) => (
  <div>
    <h2 className="text-xl font-semibold mb-2">Demographics</h2>
    <p>Name: {patient.demographics?.name || 'N/A'}</p>
    <p>Date of Birth: {formatTimestamp(patient.demographics?.dateOfBirth) || 'N/A'}</p>
    <p>Gender: {patient.demographics?.gender || 'N/A'}</p>
    <p>Contact: {patient.demographics?.contact || 'N/A'}</p>
  </div>
);

const MedicalHistorySection = ({ patient }) => (
  <div>
    <h2 className="text-xl font-semibold mb-2">Medical History</h2>
    {patient.medicalHistory && patient.medicalHistory.length > 0 ? (
      <ul className="list-disc pl-5">
        {patient.medicalHistory.map((item, index) => (
          <li key={index}>{item}</li>
        ))}
      </ul>
    ) : (
      <p>No medical history recorded.</p>
    )}
  </div>
);

const MedicationsSection = ({ patient }) => (
  <div>
    <h2 className="text-xl font-semibold mb-2">Current Medications</h2>
    {patient.medications && patient.medications.length > 0 ? (
      <ul className="list-disc pl-5">
        {patient.medications.map((med, index) => (
          <li key={index}>{med}</li>
        ))}
      </ul>
    ) : (
      <p>No medications recorded.</p>
    )}
  </div>
);

const LabResultsSection = ({ patient }) => (
  <div>
    <h2 className="text-xl font-semibold mb-2">Lab Results</h2>
    {patient.labResults && patient.labResults.length > 0 ? (
      <table className="min-w-full divide-y divide-gray-200">
        <thead className="bg-gray-50">
          <tr>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Test</th>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Result</th>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Date</th>
          </tr>
        </thead>
        <tbody className="bg-white divide-y divide-gray-200">
          {patient.labResults.map((result, index) => (
            <tr key={index}>
              <td className="px-6 py-4 whitespace-nowrap">{result.test}</td>
              <td className="px-6 py-4 whitespace-nowrap">{result.result}</td>
              <td className="px-6 py-4 whitespace-nowrap">{formatTimestamp(result.date)}</td>
            </tr>
          ))}
        </tbody>
      </table>
    ) : (
      <p>No lab results recorded.</p>
    )}
  </div>
);

const ImagingSection = ({ patient }) => (
  <div>
    <h2 className="text-xl font-semibold mb-2">Imaging Studies</h2>
    {patient.imagingStudies && patient.imagingStudies.length > 0 ? (
      <ul className="list-disc pl-5">
        {patient.imagingStudies.map((study, index) => (
          <li key={index}>{study}</li>
        ))}
      </ul>
    ) : (
      <p>No imaging studies recorded.</p>
    )}
  </div>
);

const NotesSection = ({ patient }) => (
  <div>
    <h2 className="text-xl font-semibold mb-2">Clinical Notes</h2>
    {patient.clinicalNotes && patient.clinicalNotes.length > 0 ? (
      patient.clinicalNotes.map((note, index) => (
        <div key={index} className="bg-gray-100 p-4 rounded mb-4">
          <p className="font-semibold">{formatTimestamp(note.date)} - {note.doctor}</p>
          <p>{note.content}</p>
        </div>
      ))
    ) : (
      <p>No clinical notes recorded.</p>
    )}
  </div>
);

const SupportPlanGoalsSection = ({ patient }) => (
  <div>
    <h2 className="text-xl font-semibold mb-2">Support Plan Goals</h2>
    {patient.supportPlanGoals && patient.supportPlanGoals.length > 0 ? (
      patient.supportPlanGoals.map((goal, index) => (
        <div key={index} className="mb-4 p-4 bg-gray-100 rounded">
          <p><strong>Goal:</strong> {goal.description}</p>
          <p><strong>Target Date:</strong> {formatTimestamp(goal.targetDate)}</p>
          <p><strong>Status:</strong> {goal.status}</p>
          <p><strong>Notes:</strong> {goal.notes}</p>
        </div>
      ))
    ) : (
      <p>No support plan goals recorded.</p>
    )}
  </div>
);

const BehaviorSection = ({ patient }) => (
  <div>
    <h2 className="text-xl font-semibold mb-2">Behavior</h2>
    {patient.behaviorIncidents && patient.behaviorIncidents.length > 0 ? (
      patient.behaviorIncidents.map((incident, index) => (
        <div key={index} className="mb-4 p-4 bg-gray-100 rounded">
          <p><strong>Date:</strong> {formatTimestamp(incident.date)}</p>
          <p><strong>Description:</strong> {incident.description}</p>
          <p><strong>Severity:</strong> {incident.severity}</p>
          <p><strong>Intervention:</strong> {incident.intervention}</p>
        </div>
      ))
    ) : (
      <p>No behavior incidents recorded.</p>
    )}
  </div>
);

const MedicalAdherenceSection = ({ patient }) => (
  <div>
    <h2 className="text-xl font-semibold mb-2">Medical Adherence</h2>
    {patient.medicalAdherence ? (
      <div className="p-4 bg-gray-100 rounded">
        <p><strong>Medication Compliance:</strong> {patient.medicalAdherence.medicationCompliance}</p>
        <p><strong>Appointment Attendance:</strong> {patient.medicalAdherence.appointmentAttendance}</p>
        <p><strong>Treatment Plan Adherence:</strong> {patient.medicalAdherence.treatmentPlanAdherence}</p>
        <p><strong>Notes:</strong> {patient.medicalAdherence.notes}</p>
      </div>
    ) : (
      <p>No medical adherence information recorded.</p>
    )}
  </div>
);

const TransfersSection = ({ patient }) => (
  <div>
    <h2 className="text-xl font-semibold mb-2">Transfers</h2>
    {patient.transfers && patient.transfers.length > 0 ? (
      patient.transfers.map((transfer, index) => (
        <div key={index} className="mb-4 p-4 bg-gray-100 rounded">
          <p><strong>Date:</strong> {formatTimestamp(transfer.date)}</p>
          <p><strong>From:</strong> {transfer.from}</p>
          <p><strong>To:</strong> {transfer.to}</p>
          <p><strong>Reason:</strong> {transfer.reason}</p>
          <p><strong>Accompanying Staff:</strong> {transfer.staff}</p>
        </div>
      ))
    ) : (
      <p>No transfers recorded.</p>
    )}
  </div>
);

const AssignedStaffSection = ({ patient }) => (
  <div>
    <h2 className="text-xl font-semibold mb-2">Assigned Staff</h2>
    {patient.assignedStaff && patient.assignedStaff.length > 0 ? (
      patient.assignedStaff.map((staff, index) => (
        <div key={index} className="mb-4 p-4 bg-gray-100 rounded">
          <p><strong>Name:</strong> {staff.name}</p>
          <p><strong>Role:</strong> {staff.role}</p>
          <p><strong>Contact:</strong> {staff.contact}</p>
          <p><strong>Schedule:</strong> {staff.schedule}</p>
        </div>
      ))
    ) : (
      <p>No assigned staff recorded.</p>
    )}
  </div>
);

const CommunityActivityLogsSection = ({ patient }) => (
  <div>
    <h2 className="text-xl font-semibold mb-2">Community Activity Logs</h2>
    {patient.communityActivityLogs && patient.communityActivityLogs.length > 0 ? (
      patient.communityActivityLogs.map((activity, index) => (
        <div key={index} className="mb-4 p-4 bg-gray-100 rounded">
          <p><strong>Date:</strong> {formatTimestamp(activity.date)}</p>
          <p><strong>Activity:</strong> {activity.type}</p>
          <p><strong>Duration:</strong> {activity.duration}</p>
          <p><strong>Location:</strong> {activity.location}</p>
          <p><strong>Staff:</strong> {activity.staff}</p>
          <p><strong>Notes:</strong> {activity.notes}</p>
        </div>
      ))
    ) : (
      <p>No community activities recorded.</p>
    )}
  </div>
);

const InventorySection = ({ patient }) => (
  <div>
    <h2 className="text-xl font-semibold mb-2">Inventory</h2>
    {patient.inventory ? (
      <div className="p-4 bg-gray-100 rounded">
        <p><strong>Last Inventory Date:</strong> {formatTimestamp(patient.inventory.lastInventoryDate)}</p>
        <h3 className="text-lg font-semibold mt-4 mb-2">Personal Belongings</h3>
        <ul className="list-disc pl-5">
          {patient.inventory.personalBelongings.map((item, index) => (
            <li key={index}>{item.name} - Condition: {item.condition}</li>
          ))}
        </ul>
        <h3 className="text-lg font-semibold mt-4 mb-2">Medical Equipment</h3>
        <ul className="list-disc pl-5">
          {patient.inventory.medicalEquipment.map((item, index) => (
            <li key={index}>{item.name} - Condition: {item.condition}</li>
          ))}
        </ul>
      </div>
    ) : (
      <p>No inventory recorded.</p>
    )}
  </div>
);

const SignificantEventsSection = ({ patient }) => (
  <div>
    <h2 className="text-xl font-semibold mb-2">Significant Events</h2>
    {patient.significantEvents && patient.significantEvents.length > 0 ? (
      patient.significantEvents.map((event, index) => (
        <div key={index} className="mb-4 p-4 bg-gray-100 rounded">
          <p><strong>Date:</strong> {formatTimestamp(event.date)}</p>
          <p><strong>Type:</strong> {event.type}</p>
          <p><strong>Description:</strong> {event.description}</p>
          <p><strong>Staff Involved:</strong> {event.staffInvolved}</p>
          <p><strong>Follow-up:</strong> {event.followUp}</p>
        </div>
      ))
    ) : (
      <p>No significant events recorded.</p>
    )}
  </div>
);

const MedicalContactsSection = ({ patient }) => (
  <div>
    <h2 className="text-xl font-semibold mb-2">Medical Contacts</h2>
    {patient.medicalContacts && patient.medicalContacts.length > 0 ? (
      patient.medicalContacts.map((contact, index) => (
        <div key={index} className="mb-4 p-4 bg-gray-100 rounded">
          <p><strong>Name:</strong> {contact.name}</p>
          <p><strong>Specialty:</strong> {contact.specialty}</p>
          <p><strong>Contact:</strong> {contact.contact}</p>
          <p><strong>Last Appointment:</strong> {formatTimestamp(contact.lastAppointment)}</p>
          <p><strong>Next Appointment:</strong> {formatTimestamp(contact.nextAppointment)}</p>
        </div>
      ))
    ) : (
      <p>No medical contacts recorded.</p>
    )}
  </div>
);

const FacesheetSection = ({ patient }) => (
  <div>
    <h2 className="text-xl font-semibold mb-2">Facesheet</h2>
    {patient.facesheet ? (
      <div className="p-4 bg-gray-100 rounded">
        <h3 className="text-lg font-semibold mt-4 mb-2">Law Enforcement</h3>
        <p>{patient.facesheet.lawEnforcement}</p>
        <h3 className="text-lg font-semibold mt-4 mb-2">Paramedics</h3>
        <p>{patient.facesheet.paramedics}</p>
        <h3 className="text-lg font-semibold mt-4 mb-2">Support Coordinators</h3>
        <p>{patient.facesheet.supportCoordinators}</p>
        <h3 className="text-lg font-semibold mt-4 mb-2">Hospital</h3>
        <p>{patient.facesheet.hospital}</p>
        <h3 className="text-lg font-semibold mt-4 mb-2">Family Members</h3>
        <ul className="list-disc pl-5">
          {patient.facesheet.familyMembers.map((member, index) => (
            <li key={index}>{member.name} - {member.relationship} - {member.contact}</li>
          ))}
        </ul>
        <h3 className="text-lg font-semibold mt-4 mb-2">APD</h3>
        <p>{patient.facesheet.apd}</p>
      </div>
    ) : (
      <p>No facesheet information recorded.</p>
    )}
  </div>
);

const IncomeAndExpensesSection = ({ patient }) => (
  <div>
    <h2 className="text-xl font-semibold mb-2">Income and Expenses</h2>
    {patient.incomeAndExpenses ? (
      <div className="p-4 bg-gray-100 rounded">
        <h3 className="text-lg font-semibold mt-4 mb-2">Income Sources</h3>
        <ul className="list-disc pl-5">
          {patient.incomeAndExpenses.incomeSources.map((source, index) => (
            <li key={index}>{source.name}: ${source.amount}</li>
          ))}
        </ul>
        <p className="mt-2"><strong>Total Monthly Income:</strong> ${patient.incomeAndExpenses.totalMonthlyIncome}</p>
        
        <h3 className="text-lg font-semibold mt-4 mb-2">Fixed Expenses</h3>
        <ul className="list-disc pl-5">
          {patient.incomeAndExpenses.fixedExpenses.map((expense, index) => (
            <li key={index}>{expense.name}: ${expense.amount}</li>
          ))}
        </ul>
        
        <h3 className="text-lg font-semibold mt-4 mb-2">Variable Expenses</h3>
        <ul className="list-disc pl-5">
          {patient.incomeAndExpenses.variableExpenses.map((expense, index) => (
            <li key={index}>{expense.name}: ${expense.amount}</li>
          ))}
        </ul>
        
        <p className="mt-2"><strong>Total Monthly Expenses:</strong> ${patient.incomeAndExpenses.totalMonthlyExpenses}</p>
        <p><strong>Monthly Savings:</strong> ${patient.incomeAndExpenses.monthlySavings}</p>
      </div>
    ) : (
      <p>No income and expenses information recorded.</p>
    )}
  </div>
);

const LogsSection = ({ patient }) => (
  <div>
    <h2 className="text-xl font-semibold mb-2">Logs</h2>
    {patient.logs ? (
      <div>
        <h3 className="text-lg font-semibold mt-4 mb-2">Rehab Logs</h3>
        {patient.logs.rehabLogs.length > 0 ? (
          patient.logs.rehabLogs.map((log, index) => (
            <div key={index} className="mb-2 p-2 bg-gray-100 rounded">
              <p><strong>Date:</strong> {formatTimestamp(log.date)}</p>
              <p><strong>Activity:</strong> {log.activity}</p>
              <p><strong>Progress:</strong> {log.progress}</p>
            </div>
          ))
        ) : (
          <p>No rehab logs recorded.</p>
        )}

        <h3 className="text-lg font-semibold mt-4 mb-2">Respite Logs</h3>
        {patient.logs.respiteLogs.length > 0 ? (
          patient.logs.respiteLogs.map((log, index) => (
            <div key={index} className="mb-2 p-2 bg-gray-100 rounded">
              <p><strong>Date:</strong> {formatTimestamp(log.date)}</p>
              <p><strong>Duration:</strong> {log.duration}</p>
              <p><strong>Reason:</strong> {log.reason}</p>
            </div>
          ))
        ) : (
          <p>No respite logs recorded.</p>
        )}

        <h3 className="text-lg font-semibold mt-4 mb-2">Life Skills Logs</h3>
        {patient.logs.lifeSkillsLogs.length > 0 ? (
          patient.logs.lifeSkillsLogs.map((log, index) => (
            <div key={index} className="mb-2 p-2 bg-gray-100 rounded">
              <p><strong>Date:</strong> {formatTimestamp(log.date)}</p>
              <p><strong>Skill Practiced:</strong> {log.skillPracticed}</p>
              <p><strong>Progress:</strong> {log.progress}</p>
            </div>
          ))
        ) : (
          <p>No life skills logs recorded.</p>
        )}

        <h3 className="text-lg font-semibold mt-4 mb-2">Personal Support Logs</h3>
        {patient.logs.personalSupportLogs.length > 0 ? (
          patient.logs.personalSupportLogs.map((log, index) => (
            <div key={index} className="mb-2 p-2 bg-gray-100 rounded">
              <p><strong>Date:</strong> {formatTimestamp(log.date)}</p>
              <p><strong>Type of Support:</strong> {log.supportType}</p>
              <p><strong>Staff:</strong> {log.staff}</p>
            </div>
          ))
        ) : (
          <p>No personal support logs recorded.</p>
        )}

        <h3 className="text-lg font-semibold mt-4 mb-2">Seizure Logs</h3>
        {patient.logs.seizureLogs.length > 0 ? (
          patient.logs.seizureLogs.map((log, index) => (
            <div key={index} className="mb-2 p-2 bg-gray-100 rounded">
              <p><strong>Date:</strong> {formatTimestamp(log.date)}</p>
              <p><strong>Duration:</strong> {log.duration}</p>
              <p><strong>Type:</strong> {log.type}</p>
              <p><strong>Triggers:</strong> {log.triggers}</p>
            </div>
          ))
        ) : (
          <p>No seizure logs recorded.</p>
        )}
      </div>
    ) : (
      <p>No logs recorded.</p>
    )}
  </div>
);

export default PatientEMRDetails;